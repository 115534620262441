import { v4 as uuidv4 } from 'uuid';
import moment from "moment-timezone";
import _ from "lodash";
import { Address, AdultParticipant, ChildParticipant, Gender, ParentGuardian, ParticipantBase, ProgramPromotionCode, ProgramPromotionCodeDiscountType, ProgramRegistration } from "../models/programregistration.models";
import { Program, ProgramActivity, ProgramActivityPriceType, ProgramActivitySession, ProgramPriceType } from '../models/programs.models';
import { currency } from '../shared/helpers';

export const createEmptyProgramRegistration = (defaults?: Partial<ProgramRegistration>) : ProgramRegistration => {
	let programRegistration: ProgramRegistration = {
		Id: 0,
		TransactionId: 0,
		UserId: 0,
		DateSubmitted: null,
		DateFrom: defaults?.DateFrom ?? null,
		DateTo: defaults?.DateTo ?? null,
		ProgramId: 0,
		ProgramGroupId: 0,
		Data: {
			Id: _.random(1000000, 9999999),
			ProgramId: 0,
			ProgramGroupId: 0,
			DateFrom: defaults?.Data?.DateFrom ?? null,
			DateTo: defaults?.Data?.DateTo ?? null,
			Requests: "",
			PromotionCodeId: 0,
			ParentGuardians: [],
			Participants: [],
			ProgramSessions: [],
			Custom: [],
			Metadata: { 
				program: "",
				programGroup: "",
				participants: [],
				activities: [],
				cost: 0,
				savePaymentMethod: false
			},
			PromotionCode: undefined
		},
		Total: 0,
		Metadata: {
			Program: "",
			ProgramGroup: "",
			Participants: []
		},
		Deleted: false
	}

	return programRegistration;
}

export const createEmtpyAddress = () : Address => {
	let address: Address = {
		Id: 0,
		Street1: "",
		Street2: "",
		// Street3?: string;
		City: "",
		Province: "Ontario",
		PostalCode: "",
		Country: "Canada"
	}

	return address;
}

export const createEmptyAdult = () : AdultParticipant => {
	let adult: AdultParticipant = {
		Type: "adult",
		Id: uuidv4(),
		Salutation: "",
		FirstName: "",
		LastName: "",
		Gender: 0,
		Email: "",
		HomePhone: "",
		MobilePhone: "",
		SameAddress: true,
		Address: createEmtpyAddress()
	};

	return adult;
}

export const createEmptyChild = () : ChildParticipant => {
	let child: ChildParticipant = {
		Type: "child",
		Id: uuidv4(),
		FirstName: "",
		LastName: "",
		Gender: 0,
		Birthdate: null,
		SameAddress: true,
		Address: createEmtpyAddress()
	};

	return child;
}

export const createEmptyParentGuardian = () : ParentGuardian => {
	let parentGuardian: ParentGuardian = {
		Type: "adult",
		Id: uuidv4(),
		Salutation: "",
		FirstName: "",
		LastName: "",
		Gender: 0,
		Email: "",
		HomePhone: "",
		MobilePhone: "",
		Location: "",
		EmergencyContact: "",
		Address: createEmtpyAddress()
	};

	return parentGuardian;
}

export const getParticipant = (registation: ProgramRegistration, participantId: string) => {
	let participant = registation.Data.Participants.find(p => p.Id === participantId);
	return participant;
}

export const getParticipantsForSession = (registation: ProgramRegistration, programSessionId: number) => {
	let programSession = registation.Data.ProgramSessions.find(s => s.ProgramSessionId === programSessionId);
	let participantIds = programSession?.Participants.map(p => p.ParticipantId);
	let participants = registation.Data.Participants.filter(p => participantIds?.includes(p.Id));
	return participants;
}

export const getParticipantName = (registation: ProgramRegistration, participantId: string) => {
	let participant = registation.Data.Participants.find(p => p.Id === participantId);
	return participant ? `${participant.FirstName} ${participant.LastName}` : '';
}

export const getParticipantsNames = (registation: ProgramRegistration|undefined) => {
	if (registation === undefined || registation.Data === undefined || registation.Data.Participants === undefined)
		return [];

	let names: string[] = [];
	
	for (let participant of registation.Data.Participants) {
		names.push(`${participant.FirstName} ${participant.LastName}`);
	}

	return names;
}

export const getGender = (participant: ParticipantBase) => { 
	if (participant === undefined)
		return "N/A";
	switch (participant.Gender) {
		case Gender.Male:
			return "Male";
		case Gender.Female:
			return "Female";
		case Gender.NotSpecified:
			return ""
		default:
			return "N/A";
	}
}

export const getGenderDescription = (gender: Gender) => {
	switch (gender) {
		case Gender.Male:
			return "Male";
		case Gender.Female:
			return "Female";
		case Gender.NotSpecified:
			return "N/A";
		default:
			return "";
	}
}

export const getAge = (child: ChildParticipant, relativeToDate: moment.MomentInput) => { 
	if (child === undefined)
		return "";
	if (child.Birthdate === null || child.Birthdate === undefined)
		return null;
	var age: string|number = moment(relativeToDate).diff(moment(child.Birthdate), 'years');
	if (age < 2)
		return `${moment().diff(moment(child.Birthdate), 'months')} months`;
	return age < 19 ? `${age}` : null;
}

export const formatAddress = (address: Address|undefined, singleLine = false) => {
	if (!address)
		return <></>;

	return <>
		{address.Street1}
		{address.Street2 !== "" && 
		<>, {address.Street2}</>
		}
		{singleLine ? <>, </> : <br />}
		{address.City}, {address.Province}
		{/* {singleLine ? <>, </> : <br />} */}
		<>, </>
		{address.PostalCode}
	</>
}

export const formatDateRange = (dateFrom: moment.MomentInput, dateTo: moment.MomentInput) => {
	if (moment(dateFrom).isSame(moment(dateTo), "date"))
		return `${moment(dateFrom).format("MMMM D, YYYY")}, ${moment(dateFrom).format("h:mma")} - ${moment(dateTo).format("h:mma")}`;

	if (moment(dateFrom).isSame(moment(dateTo), "month"))
		return `${moment(dateFrom).format("MMMM D")}-${moment(dateTo).format("D, YYYY")}`;

	return `${moment(dateFrom).format("MMMM D")} - ${moment(dateTo).format("MMMM D, YYYY")}`;
}

export const getCustomFieldValue = (registration: ProgramRegistration, customFieldId: number) => {
	let customFieldValue = registration?.Data.Custom.find(c => c.CustomFieldId === customFieldId);
	return customFieldValue?.Value ?? "";
}

export const getProgramRegistrationCost = (registration: ProgramRegistration, programs: Program[], programActivities: ProgramActivity[], programActivitySessions: ProgramActivitySession[], includeDiscount: boolean = false) => {
	if (!registration)
		return 0;
	var programTotal = getRegistrationProgramTotal(registration, programs);
	var activityTotal = getProgramActivityTotal(registration, programActivities, programActivitySessions);
	var subtotal = programTotal + activityTotal;

	if (includeDiscount) {
		var discountTotal = getRegistrationDiscountTotal(registration, subtotal);
		subtotal = subtotal - discountTotal;
	}

	var total = _.round(subtotal, 2);
	return total;
}

export const getProgramRegistrationTotal = (registration: ProgramRegistration, programs: Program[], programActivities: ProgramActivity[], programActivitySessions: ProgramActivitySession[], includeDiscount: boolean = false) => {
	if (!registration)
		return 0;
	var programTotal = getRegistrationProgramTotal(registration, programs);
	var activityTotal = getProgramActivityTotal(registration, programActivities, programActivitySessions);
	var subtotal = programTotal + activityTotal;

	if (includeDiscount) {
		var discountTotal = getRegistrationDiscountTotal(registration, subtotal);
		subtotal = subtotal - discountTotal;
	}

	var total = _.round(subtotal * 1.13, 2);
	return total;
}

const getRegistrationProgramTotal = (registrtaion: ProgramRegistration, programs: Program[]) => {
	var program = programs.find(p => p.Id === registrtaion.Data.ProgramId);
	
	var total = _.sumBy(registrtaion.Data.ProgramSessions, (session) => {
		if (program?.PriceType === ProgramPriceType.PerGroup)
			return program.Price ?? 0;
		else if (program?.PriceType === ProgramPriceType.PerPerson)
			return session.Participants.length * (program?.Price ?? 0);
		else
			return 0;
	});

	return total;
}

const getProgramActivityTotal = (registration: ProgramRegistration, programActivities: ProgramActivity[], programActivitySessions: ProgramActivitySession[]) => {
	var programActivityTotal = 0;

	let participantActivityMap = new Map<string,number[]>();

	for (let programSession of registration.Data.ProgramSessions) {
		for (let participant of programSession.Participants) {
			for (let activitySessionId of participant.ActivitySessionIds) {
				let activitySession = programActivitySessions?.find(s => s.Id === activitySessionId);
				if (activitySession === undefined)
					continue;

				let activity = programActivities?.find(a => a.Id === activitySession!.ActivityId);
				if (activity === undefined)
					continue;

				let key = `${participant.ParticipantId}-${moment(activitySession.StartDate).format("h:mm a") ?? ""}-${moment(activitySession.EndDate).format("h:mm a") ?? ""}`;

				if (!participantActivityMap.has(key)) {
					participantActivityMap.set(key, []);
				}

				if (activity.PriceType === ProgramActivityPriceType.PerPerson) {
					var pa = participantActivityMap.get(key);
					if (pa && pa.includes(activity.Id))
						continue;

					pa?.push(activity.Id);
					participantActivityMap.set(key, pa ?? []);
					programActivityTotal += Number(activity.Price);
				} else if (activity.PriceType === ProgramActivityPriceType.PerSession) {
					programActivityTotal += Number(activity.Price);
				}

			}
		}
	}

	return programActivityTotal;
}

const getRegistrationDiscountTotal = (registration: ProgramRegistration, total: number) => {
	if (registration.Data.PromotionCode != null) {	
		var discountAmount = registration.Data.PromotionCode.DiscountAmount;
		if (registration.Data.PromotionCode.DiscountType == ProgramPromotionCodeDiscountType.Percent)
			discountAmount = total * discountAmount / 100;
		return discountAmount;
	}

	return 0;
}

export const formatDiscountAmount = (promotionCode: ProgramPromotionCode) => {
	switch (promotionCode.DiscountType) {
		case ProgramPromotionCodeDiscountType.Amount:
			return currency.format(promotionCode.DiscountAmount);
		case ProgramPromotionCodeDiscountType.Percent:
			return `${Math.round(promotionCode.DiscountAmount)}%`;
		default:
			return promotionCode.DiscountAmount;
	}
}