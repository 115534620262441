import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAccess, useAccountId } from "../Auth/auth-functions";
import { api } from "./api";
import { Transaction, CreateTransactionDto, CreateRefundDto, StripeRefund } from "../models/transaction.models";

export const useTransactionByIdQuery = (transactionId: number) => { 
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['transaction-by-id', transactionId, accountId], async () => {
		let res = await api.get<Transaction>(`/admin/transactions/id/${transactionId}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: transactionId > 0 && hasAccess });
}

export const useCreateRefundMutation = () => {
	const queryClient = useQueryClient();
	// const hasAccess = useAccess("role.user.readwrite");
	const accountId = useAccountId();

	return useMutation((model: CreateRefundDto) => {		
		return api.post<StripeRefund>(`/admin/transactions`, model);
	}, {
		onSuccess: (response, refundModel) => {
			// queryClient.invalidateQueries(['search']);
			queryClient.invalidateQueries(['transaction-by-id', refundModel.TransactionId, accountId]);
			queryClient.invalidateQueries(['program-registrations-by-id', refundModel.ProgramRegistrationId, accountId]);
			// queryClient.invalidateQueries(['program-session-enrollments-by-registration-id', programRegistration.Id, accountId]);
		}
	})
}

// export const useCreateTransactionMutation = () => {
// 	const queryClient = useQueryClient();
// 	// const hasAccess = useAccess("role.user.readwrite");
// 	const accountId = useAccountId();

// 	return useMutation((model: CreateTransactionDto) => {		
// 		return api.post<Transaction>(`/admin/transactions`, model);
// 	}, {
// 		onSuccess: (response, transactionModel) => {
// 			// queryClient.invalidateQueries(['search']);
// 			queryClient.invalidateQueries(['transaction-by-id', response.data.Id, accountId]);
// 			queryClient.invalidateQueries(['program-registrations-by-id', transactionModel.ProgramRegistrationId, accountId]);
// 			// queryClient.invalidateQueries(['program-session-enrollments-by-registration-id', programRegistration.Id, accountId]);
// 		}
// 	})
// }