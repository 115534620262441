import { Badge, Box, Button, Card, Center, Divider, Flex, Grid, Group, Loader, MediaQuery, SimpleGrid, Skeleton, Stack, Text, ThemeIcon, Title } from "@mantine/core"
import { useNewRegistrationsTodayQuery, useNewRegistrationsYesterdayQuery } from "../../api/programregistration.api"
import { ProgramRegistration } from "../../models/programregistration.models"
import _ from "lodash";
import { useProgramGroupsQuery } from "../../api/program.api";
import { ProgramGroup } from "../../models/programs.models";
import { IconCircleArrowRightFilled } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { ProgramRegistrationList } from "./ProgramRegistrationList";
import { linkTitle } from "../../shared/helpers";

interface NewRegistrationsWidgetContentProps {
	registrations: ProgramRegistration[]|undefined;
	isLoading: boolean;
}
const NewRegistrationsWidgetContent = ({ registrations, isLoading }: NewRegistrationsWidgetContentProps) => {
	const { data: programGroups } = useProgramGroupsQuery();

	const getRegistrationsForProgramGroup = (programGroup: ProgramGroup) => {
		return registrations?.filter(r => r.ProgramGroupId === programGroup.Id) ?? [];
	}

	return <>
		{isLoading && <Skeleton height={25} mb={0} />}

		{!isLoading && registrations && <>
			<Group position="left" align="flex-start">
				<MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
				<Center h="140px" w={160}>
					<Stack align="center" spacing={0}>
						<Text weight="normal" size={80} sx={{ lineHeight: '80px' }} variant="gradient">{registrations.length}</Text>
						<Text weight="bold">New registrations</Text>
					</Stack>
				</Center>
				</MediaQuery>
				<MediaQuery largerThan="xs" styles={{ display: 'none' }}>
					<Box sx={{ width: "100%" }}>
						<Center>
							<Stack align="center" spacing={0}>
								<Text weight="normal" size={80} sx={{ lineHeight: '70px' }} variant="gradient">{registrations.length}</Text>
								<Text weight="bold">New registrations</Text>
							</Stack>
						</Center>
						<Divider w="100%" mt="sm" />
					</Box>
				</MediaQuery>
				<MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
					<Center h={140} w={20}>
						<Divider orientation="vertical"/>
					</Center>
				</MediaQuery>
				<Stack spacing={0} sx={{flexGrow: 1}} w="50%">
					{programGroups?.map((programGroup, index) => {
						return <div key={index}>
							<Button component={Link} to={`/registrations/list/${linkTitle(programGroup.Title)}`} fullWidth variant="subtle" p={10} rightIcon={<Badge sx={{ alignSelf: 'center' }} ml="sm" miw="45px" variant="gradient">{getRegistrationsForProgramGroup(programGroup).length}</Badge>} style={{ justifyContent: 'space-between' }}>
								<Text truncate sx={{ width: '500px'}}>{programGroup.Title}</Text>
							</Button>
							{/* <Link to="/registrations" style={{ textDecoration: 'none', color: 'unset' }}>
							<Flex direction="row" w="100%" justify="space-between" p={4} sx={{ borderRadius: 5, ":hover": { backgroundColor: "#eee", cursor: 'pointer' } }}>

								<Text truncate size="sm">{programGroup.Title}</Text>
								<Badge sx={{ alignSelf: 'center' }} ml="sm" miw="45px" variant="gradient">{getRegistrationsForProgramGroup(programGroup).length}</Badge>
							</Flex>
							</Link> */}
							
							{index < programGroups.length - 1 && <Divider mt={1} mb={1} w="100%" />}
						</div>
					})}
					<Divider mt={1} mb={5} w="100%" />
					{/* <Group align="flex-end" w="100%" position="right">
						<Link to="/registrations" style={{ textDecoration: 'none' }}>
							<Text variant="gradient" size="sm" weight="bold" sx={{ float: 'left', marginTop: '2px' }}>See all registrations</Text>
							<ThemeIcon variant="gradient" radius="xl" ml="xs"><IconCircleArrowRightFilled size={16} /></ThemeIcon>
						</Link>
					</Group> */}
					<Button component={Link} to="/registrations" variant="subtle" compact w={172} sx={{ alignSelf: 'flex-end' }} p={0} rightIcon={<IconCircleArrowRightFilled size={16} color="" />}><Text>See all registrations</Text></Button>
				</Stack>
			</Group>
		</>}
	</>
}

export const NewRegistrationsTodayDashboard = () => {
	const { data: registrations, isLoading } = useNewRegistrationsTodayQuery();

	return <>
		<Card shadow="lg" withBorder style={{ height: '100%' }}>
			<Group position="apart">
				<Group spacing="xs">
					{/* <IconAdjustmentsHorizontal /> */}
					<Title order={3} mb={0}>Today</Title>
				</Group>
				{/* <Button variant="light" mt={-15}><IconPencil /> Edit</Button> */}
				{isLoading && <Loader size="sm" />}
			</Group>
			<NewRegistrationsWidgetContent registrations={registrations} isLoading={isLoading} />
			
		</Card>
	</>
}

export const NewRegistrationsYesterdayDashboard = () => {
	const { data: registrations, isLoading } = useNewRegistrationsYesterdayQuery();

	return <>
		<Card shadow="lg" withBorder style={{ height: '100%' }}>
			<Group position="apart">
				<Group spacing="xs">
					{/* <IconAdjustmentsHorizontal /> */}
					<Title order={3}>Yesterday</Title>
				</Group>
				{/* <Button variant="light" mt={-15}><IconPencil /> Edit</Button> */}
				{isLoading && <Loader size="sm" />}
			</Group>
			
			<NewRegistrationsWidgetContent registrations={registrations} isLoading={isLoading} />
		</Card>
	</>
}

export const UpcomingSessionsDashboard = () => {
	// const { data: registrations, isLoading } = useNewRegistrationsYesterdayQuery();

	return <>
		<Card shadow="lg" withBorder style={{ height: '100%' }}>
			<Group position="apart">
				<Group spacing="xs">
					<Title order={3}>Upcoming Sessions</Title>
				</Group>
				{/* {isLoading && <Loader size="sm" />} */}
			</Group>
			
			{/* <WidgetContent registrations={registrations} isLoading={isLoading} /> */}
		</Card>
	</>
}

export const RegistrationsListTodayDashboard = () => {
	const { data: registrations, isLoading } = useNewRegistrationsTodayQuery();

	return <>
		<Card shadow="lg" withBorder style={{ height: '100%' }}>
			<Group position="apart">
				<Group spacing="xs">
					<Title order={3}>Today's New Registrations</Title>
				</Group>
				{isLoading && <Loader size="sm" />}
			</Group>
			
			{/* <WidgetContent registrations={registrations} isLoading={isLoading} /> */}
			<ProgramRegistrationList registrations={registrations ?? []} isLoading={isLoading} showFilter={false} />
		</Card>
	</>
}