import { Button, Divider, Drawer, Group, Input, TextInput, Textarea, Title } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks";
import { IconCheck, IconPencil, IconPlus, IconX } from "@tabler/icons-react"
import { useProgramRegistrationState } from "../../state/programregistration.state";
import { useProgramRegistrationCustomFieldsQuery } from "../../api/programregistration.api";
import { getCustomFieldValue } from "../../functions/programregistration.functions";
import { useState } from "react";
import { ProgramRegistrationCustomFieldType, ProgramRegistrationCustomFieldValue } from "../../models/programregistration.models";
import { useEffect } from "react";
import { cloneDeep } from "lodash";

const CustomFieldsEditor = () => {
	const [opened, { open, close }] = useDisclosure(false);
	const { programRegistration, updateProgramRegistration, isLoading: isLoadingRegistration, isDeleted, status } = useProgramRegistrationState();
	const { data: customFieldGroups } = useProgramRegistrationCustomFieldsQuery(programRegistration.ProgramId ?? 0);

	const [customFields, setCustomFields] = useState<ProgramRegistrationCustomFieldValue[]>([]);
	const [isDirty, setIsDirty] = useState(false);

	useEffect(() => {
		setCustomFields(cloneDeep(programRegistration.Data.Custom));
		setIsDirty(false);
	}, [programRegistration, opened]);
	
	const getCustomFieldValue = (customFieldId: number) => {
		let customField = customFields?.find(f => f.CustomFieldId === customFieldId);

		return (customField?.Value ?? "").toString();
	}
	const updateCustomField = (customFieldId: number, value: string) => {
		let customField = customFields?.find(f => f.CustomFieldId === customFieldId);
		if (customField) {
			// console.log(value)
			customField.Value = value;
			let c = cloneDeep(customFields);
			c[c.findIndex(f => f.CustomFieldId === customFieldId)] = customField;
			setCustomFields(c);
			setIsDirty(true);
			// console.log(customFields)
		}
	}

	const handleSave = () => {
		programRegistration.Data.Custom = customFields;
		updateProgramRegistration(programRegistration);
		setIsDirty(false);
		close();
	}

	const handleCancel = () => {
		// setCustomFields(cloneDeep(programRegistration.Data.Custom));
		// setIsDirty(false);
		close();
	}

	return <>
		{/* <Button variant="light" size="xs" mt={-15} mb="md"><IconPencil /> Edit</Button> */}
		<Button onClick={open} disabled={isDeleted} variant="light" size="xs" mt={-15} mb="xs" leftIcon={<IconPencil />}>
			Edit
		</Button>
	
		<Drawer opened={opened} onClose={close} title={<div><Title order={3}>Custom Field Details</Title></div>} position="right" withOverlay={true} size="md" withCloseButton overlayProps={{ opacity: 0.1, blur: 0 }}>
			{customFieldGroups?.map((customFieldGroup, index) => {
				return <div key={index}>
					<Divider label={customFieldGroup.Title} labelPosition="center" />
					{customFieldGroup.CustomFields.map((customField, index2) => {
						return <div key={index2}>
							{customField.Type === ProgramRegistrationCustomFieldType.TextLarge &&
							<TextInput label={customField.Title} value={getCustomFieldValue(customField.Id)} onChange={(e) => updateCustomField(customField.Id, e.target.value)} />
							}
							{customField.Type === ProgramRegistrationCustomFieldType.CommentsLarge &&
							<Textarea label={customField.Title} minRows={4} autosize value={getCustomFieldValue(customField.Id)} onChange={(e) => updateCustomField(customField.Id, e.target.value)} />
							}
						</div>
					})}
				</div>
			})}

			<Group mt="md">
				<Button type="button" onClick={handleSave} leftIcon={<IconCheck />} hidden={!isDirty}>
					Save Changes
				</Button>
				<Button type="button" variant="subtle" onClick={close} leftIcon={<IconX />}>
					{isDirty ? "Discard Changes" : "Close"}
				</Button>
				{/* <Button type="submit" variant="light" hidden={!form.isDirty() && !form.isTouched()}>
					Save Changes &amp; Add Contact Details
				</Button> */}
			</Group>
		</Drawer>
	</>
}

export default CustomFieldsEditor