import { Badge, Button, Drawer, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPencil, IconPlus, IconX } from "@tabler/icons-react";
import moment from "moment";
import { formatAddress, getAge, getGender } from "../../functions/programregistration.functions";
import { AdultParticipant, ChildParticipant, ParticipantBase } from "../../models/programregistration.models";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import ParticipantEditor from "./ParticipantEditor";
import { useProgramGroupsQuery } from "../../api/program.api";
import { ProgramParticipantType } from "../../models/programs.models";

interface Props {
	
}

const ParticipantsListEditor = ({ }: Props) => {
	const [opened, { open, close }] = useDisclosure(false);
	const { programRegistration, getParticipants, isDeleted } = useProgramRegistrationState();
	const { data: programGroups } = useProgramGroupsQuery();

	const programGroup = programGroups?.find(g => g.Id === (programRegistration?.Data.ProgramGroupId ?? 0));
	const showAddAdultParticipant = programGroup && (programGroup.ParticipantType === ProgramParticipantType.Adults || programGroup.ParticipantType === ProgramParticipantType.AdultsAndChildren);
	const showAddChildParticipant = programGroup && programGroup.ParticipantType !== ProgramParticipantType.Adults;

	return <>
		<Button onClick={open} disabled={isDeleted} variant="light" size="xs" mt={-15} mb="md" leftIcon={getParticipants().length === 0 ? <IconPlus /> : <IconPencil />}>
			{getParticipants().length === 0 ? "Add" : "Edit"}
		</Button>

		<Drawer opened={opened} onClose={close} title={<div><Title order={3}>Participants</Title></div>} position="right" size="md" withCloseButton>
			{getParticipants().map((participant, index) => {
				return <ParticipantsEditorItem key={index} participant={participant} />
			})}

			{getParticipants().length === 0 && <>
				<Text color="dimmed">There are no participants</Text>
			</>}

			{/* <Button onClick={handleAddParticipant} variant="subtle" size="sm" mt="md" mb="md" leftIcon={<IconPlus />}>Add Participant</Button> */}
			{showAddAdultParticipant && <ParticipantEditor registrationId={programRegistration.Id} participantType="adult" participantId="" add />}
			{showAddChildParticipant && <ParticipantEditor registrationId={programRegistration.Id} participantType="child" participantId="" add />}
		</Drawer>
	</>
}

export default ParticipantsListEditor

interface ParticipantsEditorItemProps {
	participant: ParticipantBase;
}

const ParticipantsEditorItem = ({ participant }: ParticipantsEditorItemProps) => {
	const { programRegistration, deleteParticipant } = useProgramRegistrationState();
	const handleDeleteParticipant = (participantId: string) => {
		deleteParticipant(participantId); 
	}

	const canDelete = programRegistration.Data.Participants.length > 1;

	let childParticipant = participant.Type == "child" ? participant as ChildParticipant : undefined;
	let adultParticipant = participant.Type == "adult" ? participant as AdultParticipant : undefined;
	return <Paper p="sm" mb="md" withBorder shadow="sm">
		<Group position="apart" align="flex-start" noWrap>
			<Stack spacing={0}>
				<Text size="lg" weight="bold">{adultParticipant?.Salutation} {participant.FirstName} {participant.LastName} </Text>
				
				{adultParticipant && <Text size="sm">({getGender(participant)}, Adult)</Text>}
				{childParticipant && <Text size="sm">
					({getGender(participant)}, {moment(childParticipant.Birthdate).format("LL")}, Age {getAge(childParticipant, programRegistration.Data.DateFrom)})
				</Text>}
{/* 
				{childParticipant &&
				<Text size="sm">{moment(childParticipant.Birthdate).format("LL")}</Text>
				} */}

				<Text size="xs" color="dimmed">{formatAddress(participant.Address, false)}</Text>

				{(childParticipant && (childParticipant.HealthConcerns || childParticipant.Allergies || childParticipant.SpecialNeeds || childParticipant.AgeOverrideReason)) && 
				<Group mt="sm">
					{childParticipant && childParticipant.HealthConcerns && <Badge>Health</Badge>}
					{childParticipant && childParticipant.Allergies && <Badge>Allergies</Badge>}
					{childParticipant && childParticipant.SpecialNeeds && <Badge>Special Needs</Badge>}
					{childParticipant && childParticipant.AgeOverrideReason && <Badge>Age Override</Badge>}
				</Group>
				}
			</Stack>
			<Stack spacing="xs">
				<ParticipantEditor registrationId={programRegistration.Id} participantType={participant.Type} participantId={participant.Id} />
				<Button onClick={() => handleDeleteParticipant(participant.Id)} disabled={!canDelete} variant="light" size="xs" mb="md" leftIcon={<IconX />} sx={{ alignSelf: "flex-start" }}>Delete</Button>
			</Stack>
		</Group>
			
	</Paper>
}