import { useQuery } from "react-query";
import { useAccess, useAccountId } from "../Auth/auth-functions";
import { api } from "./api";

export interface UserPermissions { 
	DeletePermission: boolean;
	RefundPermission: boolean;
};

export const useUserPermissionQuery = () => { 
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['user-permissions', accountId], async () => {
		let res = await api.get<UserPermissions>(`/admin/user/permissions`);
		return res.data;
	}, { staleTime: 10 * 60 * 1000, cacheTime: 60 * 60 * 1000, enabled: hasAccess });
}