import { PaymentMethod } from "@stripe/stripe-js";
import { BillingContact, TransactionPaymentMethod } from "./transaction.models";


export interface ProgramRegistration {
	Id: number;
	TransactionId: number;
	UserId: number;
	DateSubmitted: string|null;
	DateFrom: string|null;
	DateTo: string|null;
	ProgramId: number;
	ProgramGroupId: number;
	Data: ProgramRegistrationDto;
	Total: number;
	Metadata?: RegistrationMetadata;
	Deleted: boolean;
}

export interface ProgramRegistrationDto {
	Id: number;
	ProgramId: number;
	ProgramGroupId: number;
	DateFrom: string|null;
	DateTo: string|null;
	Requests: string;
	PromotionCodeId: number;
	ParentGuardians: ParentGuardian[];
	Participants: ParticipantBase[];
	ProgramSessions: RegistrationProgramSession[];
	Custom: ProgramRegistrationCustomFieldValue[];
	Metadata: RegistrationDtoMetadata;
	PromotionCode: ProgramPromotionCode|undefined;
}

export interface RegistrationMetadata {
	Program: string;
	ProgramGroup: string;
	Participants: string[];
	// activities: { name: string, activities: string[] }[];
	// cost: number;
	// savePaymentMethod: boolean;
}

export interface RegistrationDtoMetadata {
	program: string;
	programGroup: string;
	participants: string[];
	activities: { name: string, activities: string[] }[];
	cost: number;
	savePaymentMethod: boolean;
	// paymentMethod?: number;
	transaction?: {
		billingContact?: BillingContact;
		paymentMethod?: TransactionPaymentMethod;
		paymentCharged?: boolean;
		stripePaymentMethod?: PaymentMethod;
		offlinePaymentMethod?: string;
	}
}

export interface ProgramPromotionCode {
	Id: number;
	ProgramId: number;
	Code: string;
	DiscountType: ProgramPromotionCodeDiscountType;
	DiscountAmount: number;
}

export enum ProgramPromotionCodeDiscountType {
	Amount = 1,
	Percent = 2
}

export interface RegistrationProgramSession {
	ProgramSessionId: number;
	Participants: { 
		ParticipantId: string;
		WaitList?: boolean;
		ActivitySessionIds: number[];
	}[];
}

export interface ProgramRegistrationCustomFieldGroup {
	Id: number;
	Title: string;
	Subtitle: string;
	CustomFields: ProgramRegistrationCustomField[];
}

export interface ProgramRegistrationCustomField {
	Id: number;
	Type: ProgramRegistrationCustomFieldType;
	Required: boolean;
	Title: string;
	Items: string[];

	Value?: string;
}

export interface ProgramRegistrationCustomFieldValue {
	CustomFieldId: number;
	CustomFieldGroupId: number;
	Value: string|string[]|boolean;
}

export enum ProgramRegistrationCustomFieldType {
	TextSmall				= 1,
	TextMedium				= 2,
	TextLarge				= 3,
	CommentsSmall			= 11,
	CommentsMedium			= 12,
	CommentsLarge			= 13,
	CheckBoxSmall			= 21,
	CheckBoxMedium			= 22,
	CheckBoxLarge			= 23,
	CheckBoxListSmall		= 25,
	CheckBoxListMedium		= 26,
	CheckBoxListLarge		= 27,
	RadioButtonListSmall 	= 31,
	RadioButtonListMedium	= 32,
	RadioButtonListLarge 	= 33,
	DropdownListSmall		= 41,
	DropdownListMedium		= 42,
	DropdownListLarge		= 43
}

export interface ParticipantBase {
	Type: "adult"|"child";
	Id: string;
	FirstName?: string;
	LastName?: string;
	Gender?: number;

	Address?: Address;
	SameAddress?: boolean;

	Allergies?: string;
	Dietary?: string;
}

export enum Gender {
	NotSpecified = 0,
	Male = 1,
	Female = 2
}

export const Genders: Gender[] = [
	Gender.Male, Gender.Female, Gender.NotSpecified
];

export const Salutations = ["", "Mr", "Mrs", "Ms", "Dr", "Rev"];

export interface ParentGuardian extends ParticipantBase {
	Salutation?: string;
	Email?: string;
	HomePhone?: string;
	MobilePhone?: string;

	Location?: string;
	EmergencyContact?: string;
}

export interface AdultParticipant extends ParticipantBase {
	Salutation?: string;
	Email?: string;
	HomePhone?: string;
	MobilePhone?: string;

	Location?: string;
	EmergencyContact?: string;
}

export interface ChildParticipant extends ParticipantBase {
	Birthdate: string|null;
	HealthConcerns?: string;
	Allergies?: string;
	SpecialNeeds?: string;
	AgeOverrideReason?: string;
	ChangeDiaper?: boolean;
}

export interface Address {
	Id?: number;
	Street1?: string;
	Street2?: string;
	City?: string;
	Province?: string;
	Region?: string;
	PostalCode?: string;
	Country?: string;
}