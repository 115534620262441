import { useHistory, useParams } from "react-router-dom";
import { Button, Divider, Group, Loader, Stack, Text, Title } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import moment from "moment";

import { useProgramSessionByIdQuery, useProgramSessionEnrollmentsByIdQuery } from "../../api/programsession.api";
import { useProgramsQuery } from "../../api/program.api";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import { createEmptyProgramRegistration } from "../../functions/programregistration.functions";
import { getProgram } from "../../functions/program.functions";
import ProgramSessionEnrollmentsList from "../../Components/ProgramSessions/ProgramSessionEnrollmentsList";
import { RoleTemplate } from "../../Components/RoleTemplate";

interface ViewCalendarSessionPageParams {
	programSessionId: string;
}

const ViewCalendarSessionPage = () => {
	const history = useHistory();
	const { programSessionId } = useParams<ViewCalendarSessionPageParams>();
	const { data: programs, isLoading: isLoadingPrograms } = useProgramsQuery();
	const { data: programSession, isLoading: isLoadingSession } = useProgramSessionByIdQuery(Number(programSessionId ?? 0));
	const { data: programSessionEnrollments, isLoading: isLoadingSessionEnrollments } = useProgramSessionEnrollmentsByIdQuery(programSession?.Id ?? 0);
	const { setProgramRegistration } = useProgramRegistrationState();

	const program = getProgram(programs, programSession?.ProgramId ?? 0);

	const handleNewRegistration = () => {
		if (programSession === undefined)
			return;

		let program = programs?.find(p => p.Id === programSession.ProgramId);
		if (program === undefined)
			return;
			
		let newRegistration = createEmptyProgramRegistration();
		newRegistration.ProgramId = program.Id;
		newRegistration.Data.ProgramId = program.Id;
		newRegistration.ProgramGroupId = program.ProgramGroupId;
		newRegistration.Data.ProgramGroupId = program.ProgramGroupId;
		newRegistration.DateFrom = programSession.StartDate;
		newRegistration.DateTo = programSession.EndDate;
		newRegistration.Data.DateFrom = programSession.StartDate;
		newRegistration.Data.DateTo = programSession.EndDate;

		setProgramRegistration(newRegistration);
		history.push({ pathname: `/registrations/new` });
	}

	return <>
		<Group position="apart">
			<Stack spacing={0}>
			{/* <Title order={5} color="gray">PROGRAM SESSION</Title> */}
			{!isLoadingPrograms && program && programSession &&
			<Title order={2} mb="sm">{program?.Title} for {moment(programSession?.StartDate).format("LLL")}</Title>
			}
			{(isLoadingPrograms) && <Loader size="md" mb="xs" pt={3} />}
			{/* <Text>{programSession?.StartDate}</Text> */}
			</Stack>
			{!isLoadingSession && programSession &&
			<Group spacing={0}>
				<Text>Enrollment {programSession?.Enrollment}/{programSession?.Limit}</Text>
				<RoleTemplate role="role.user.readwrite">
					<Divider orientation="vertical" ml="md" mr={1} />
					<Button onClick={handleNewRegistration} variant="subtle" size="sm" leftIcon={<IconPlus />}>New Registration</Button>
				</RoleTemplate>
			</Group>
			}
			{/* <Button component={Link} to="/registrations" size="sm" sx={{ alignSelf: 'flex-start'}} mb="sm">See All Registrations...</Button> */}
		</Group>
		<Divider mb="md" />
		{/* {programSessionEnrollments && */}
		<ProgramSessionEnrollmentsList isLoading={isLoadingPrograms || isLoadingSession || isLoadingSessionEnrollments} enrollments={programSessionEnrollments ?? []} />
		{/* } */}
	</>
}

export default ViewCalendarSessionPage