import { Group, Title, Grid, Card, Text } from "@mantine/core"
import { RoleTemplate } from "../Components/RoleTemplate"
import { NewRegistrationsTodayDashboard, NewRegistrationsYesterdayDashboard, RegistrationsListTodayDashboard, UpcomingSessionsDashboard } from "../Components/ProgramRegistrations/DashboardElements"

const Home = () => {
	return <>
		<RoleTemplate role="role.user.readonly" noAccess={<>You don't have access to this.</>}>
			<Grid mb="xl" mt="sm">
				<Grid.Col xs={12} sm={6}>
					<NewRegistrationsTodayDashboard />
				</Grid.Col>

				<Grid.Col xs={12} sm={6}>
					<NewRegistrationsYesterdayDashboard />
				</Grid.Col>

				{/* <Grid.Col span={12}>
					<UpcomingSessionsDashboard />
				</Grid.Col> */}

				<Grid.Col span={12}>
					<RegistrationsListTodayDashboard />
				</Grid.Col>
			</Grid>


			
		</RoleTemplate>
	</>
}

export default Home