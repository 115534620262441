import axios from 'axios';
import { msalInstance } from '../auth';
import { loginRequest } from '../authConfig';
// import { getSettings, setSettings } from './settings';

export const api = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	withCredentials: true
});


// Add a request interceptor
api.interceptors.request.use(
	async (config) => {
		const account = msalInstance.getActiveAccount();
		if (account) {
			//throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
			const response = await msalInstance.acquireTokenSilent({
				...loginRequest,
				account: account
			});
	
			if (response.idToken) {
				config.headers["Authorization"] = "Bearer " + response.idToken;
			}
		}
	
		config.headers["Content-Type"] = "application/json";
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

api.interceptors.response.use(response => response, error => {
	// if ((error as string).startsWith('InteractionRequiredAuthError')) {
	// 	msalInstance.loginRedirect({ ...loginRequest });
	// 	return;
	// }
	// console.log(JSON.stringify(error));
	if (error.errorCode == "login_required") {
		// msalInstance.logoutRedirect();
		msalInstance.loginRedirect();
		return Promise.reject(error)
	}

	const account = msalInstance.getActiveAccount();
	// console.log(error.response)
	// if (error.response.statusCode == 401 || 
	// 	(error.response.statusCode == 400 && error.response.suberror == "bad_token")) {
	// 	// || error.response.data.statusCode == 403)
	// 	console.log('foo')
	// 	msalInstance.loginRedirect({ ...loginRequest });//acquireTokenRedirect({ ...loginRequest });
	// }

	if (error.response.data && error.response.data.statusCode == 403) {
		// || error.response.data.statusCode == 401)
		msalInstance.acquireTokenRedirect({ ...loginRequest });
	}
	
	return Promise.reject(error)
})

// api.interceptors.request.use(async config => {
// 	let settings = getSettings();
// 	if (settings) {
// 		api.defaults.baseURL = settings.apiUrl;
// 		config.baseURL = settings.apiUrl;
// 		return config;
// 	}

// 	let settingsResponse = await axios.get("/settings.json");
// 	setSettings(settingsResponse.data);
// 	settings = getSettings();
// 	api.defaults.baseURL = settings?.apiUrl;
// 	config.baseURL = settings?.apiUrl;
// 	return config;
// }, error => {
// 	// Do something with request error
// 	return Promise.reject(error);
// });

// api.interceptors.response.use(response => response, error => {
// 	console.log(error.response)
// 	return Promise.reject(error)
// })

export interface ApiError {
	statusCode: number;
	error: string;
	message: string;
}