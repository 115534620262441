import { useEffect } from "react";
import { ActionIcon, Button, Divider, Drawer, Grid, Group, Menu, Select, TextInput, Textarea, Title, Tooltip } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconDotsCircleHorizontal, IconCheck, IconPencil, IconPlus, IconX } from "@tabler/icons-react";
import _ from "lodash";
import * as Yup from "yup";
import { createEmptyParentGuardian, formatAddress, getGenderDescription } from "../../functions/programregistration.functions";
import { Address, Genders, Salutations } from "../../models/programregistration.models";
import { useFamilyRegistrationState } from "../../state/familyregistration.state";
import { AddressSchema } from "../ProgramRegistrations/AddressEditor";
import { ProgramParticipantDocument } from "../../api/search.api";
import PersonSearchModal from "../ProgramRegistrations/PersonSearchModal";
import { getCountries, getPostalCodeLabel, getProvinceLabel, getProvincesStates } from "../../shared/address";

interface FormFields {
	FirstName: string;
	LastName: string,
	// MiddleName: string,
	Salutation: string|null,
	Gender: string,
	// Birthdate: Date|null,
	// HealthConcerns?: string;
	// Allergies?: string;
	// SpecialNeeds?: string;
	// AgeOverrideReason?: string;
	Email?: string;
	HomePhone?: string;
	MobilePhone?: string;
	EmergencyContact?: string;
	Location?: string;
	// Deceased: Date|null
	Address: {
		Street1: string;
		Street2: string;
		// Street3: string;
		City: string;
		Region?: string;
		Province: string;
		PostalCode: string;
		Country: string;
	}
}

export const ParentGuardianSchema = Yup.object().shape({
	FirstName: Yup.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required'),
	LastName: Yup.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required'),
	Salutation: Yup.string()
		.max(30, 'Maximum length is 30 characters'),
	Gender: Yup.number()
		.oneOf([0,1,2], 'Required')
		.required('Required'),
	Email: Yup.string().email('Invalid email'),//.required('Required'),
	HomePhone: Yup.string(),//.when('MobilePhone', { is: "", then: (schema) => schema.min(10, 'Not a valid phone number').required('Required') }),
	MobilePhone: Yup.string(),//.when('HomePhone', { is: "", then: (schema) => schema.min(10, 'Not a valid phone number').required('Required') }),
	EmergencyContact: Yup.string().required('Required'),
	Location: Yup.string().required('Required'),
	// SameAddress: Yup.boolean(),
	Address: AddressSchema
	// Location: Yup.string().required('Required'),
	// EmergencyContact: Yup.string().required('Required')
}, [['HomePhone', 'MobilePhone']]);


interface Props {
	// registrationId: number,
	parentGuardianId: string,
	// participantType: "adult"|"child",
	add?: boolean
}

const ParentGuardianEditor = ({ parentGuardianId, add = false }: Props) => {
	const [opened, { open, close }] = useDisclosure(false);
	const { familyRegistration, updateFamilyRegistration, getAddresses } = useFamilyRegistrationState();

	const genders = Genders.map((g) => { return { value: g.toString(), label: getGenderDescription(g) } });
	const salutations = Salutations.map((s) => { return { value: s, label: s} });

	let parentGuardian = familyRegistration?.ParentGuardians.find(p => p.Id === parentGuardianId);

	if (add === true) {
		parentGuardian = createEmptyParentGuardian();
	}
	const form = useForm<FormFields>({
		validate: yupResolver(ParentGuardianSchema)
		// validate: {
		// 	FirstName: (value) => (value.length === 0 ? 'Required' : null)
		// }
	});

	useEffect(() => {
		form.setValues({
			FirstName: parentGuardian?.FirstName,
			LastName: parentGuardian?.LastName,
			// MiddleName: person?.MiddleName,
			Salutation: parentGuardian?.Salutation,
			Gender: parentGuardian?.Gender?.toString(),
			
			Email: parentGuardian?.Email,
			HomePhone: parentGuardian?.HomePhone,
			MobilePhone: parentGuardian?.MobilePhone,

			EmergencyContact: parentGuardian?.EmergencyContact,
			Location: parentGuardian?.Location,

			Address: {
				Street1: parentGuardian?.Address?.Street1 ?? "",
				Street2: parentGuardian?.Address?.Street2 ?? "",
				// Street3: address?.Street3,
				City: parentGuardian?.Address?.City ?? "",
				Province: parentGuardian?.Address?.Province ?? "",
				PostalCode: parentGuardian?.Address?.PostalCode ?? "",
				Region: parentGuardian?.Address?.Region,
				Country: parentGuardian?.Address?.Country ?? ""
			}
			// Deceased: person?.Deceased ? moment(person.Deceased).toDate() : null
		});
		form.resetDirty();
		form.resetTouched();
		
		// setShowDeceased(person?.Deceased !== null);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [familyRegistration, opened]);

	const handleSubmit = (values: FormFields) => {
		if (familyRegistration !== undefined && parentGuardian !== undefined) {
			_.assign(parentGuardian, values);
			parentGuardian.Gender = parseInt(values.Gender);
			
			if (add) {
				familyRegistration.ParentGuardians.push(parentGuardian)
			}

			for (let i = 0; i < familyRegistration.ProgramRegistrations.length; i++) {
				familyRegistration.ProgramRegistrations[i].Data.ParentGuardians = _.cloneDeep(familyRegistration.ParentGuardians);
			}

			updateFamilyRegistration(familyRegistration);
			close();
		}
	}

	const handleAddParentGuardian = () => {
		open();
	}

	const handleSearchResult = (person: ProgramParticipantDocument) => {
		form.setValues({ 
			FirstName: person.FirstName,
			LastName: person.LastName,
			Gender: person.Gender?.toString() ?? "0",
			
			Address: {
				Street1: person.Address?.Street1 ?? "",
				Street2: person.Address?.Street2 ?? "",
				City: person.Address?.City ?? "",
				Province: person.Address?.Province ?? "",
				PostalCode: person.Address?.PostalCode ?? "",
				Region: person.Address?.Region,
				Country: person.Address?.Country ?? ""
			}
		})

		if (person.Type == "adult") {
			form.setValues({ 
				Salutation: person.Salutation,
				Email: person.Email,
				HomePhone: person.HomePhone,
				MobilePhone: person.MobilePhone,
				Location: person.Location,
				EmergencyContact: person.EmergencyContact
			});
		}
	}

	const autofillAddress = (address: Address) => {
		let values = form.values;
		values.Address = {
			Street1: address?.Street1 ?? "",
			Street2: address?.Street2 ?? "",
			City: address?.City ?? "",
			Province: address?.Province ?? "",
			PostalCode: address?.PostalCode ?? "",
			Region: address?.Region,
			Country: address?.Country ?? ""
		};
		form.setValues(values);
	}

	return <>
		{!add &&
		<Button onClick={open} variant="light" size="xs" fullWidth leftIcon={<IconPencil />} sx={{ alignSelf: "flex-start" }}>Edit</Button>
		}
		{add &&
		<Button onClick={handleAddParentGuardian} variant="subtle" size="sm" mt="md" fullWidth leftIcon={<IconPlus />}>Add Parent/Guardian</Button>	
		}
		
		<Drawer opened={opened} onClose={close} title={<div><Title order={3}>{add ? "Add" : "Edit"} Parent/Guardian</Title></div>} position="right" withOverlay={true} size="md" withCloseButton overlayProps={{ opacity: 0.1, blur: 0 }}>

			{/* {isLoadingRegistration && <Loader />} */}
			<PersonSearchModal type="adult" onSelected={handleSearchResult} />
						
			<form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
			<Grid>
				<Grid.Col sm={6}>
					<TextInput label="First Name" withAsterisk {...form.getInputProps('FirstName')} />
				</Grid.Col>
				<Grid.Col sm={6}>
					<TextInput label="Last Name" withAsterisk {...form.getInputProps('LastName')} />
				</Grid.Col>
				
				{/* <Grid.Col sm={6}>
					<TextInput placeholder="Optional" label="Middle Name" {...form.getInputProps('MiddleName')} />
				</Grid.Col> */}
				<Grid.Col sm={6}>
					<Select label="Gender" data={genders} withAsterisk {...form.getInputProps('Gender')} />
				</Grid.Col>
			
				<Grid.Col sm={6}>
					<Select label="Salutation" data={salutations} {...form.getInputProps('Salutation')} />
				</Grid.Col>
			</Grid>
			<Divider mt="lg" mb="xs" />

			<Title order={4}>Contact</Title>
			<Grid>
				<Grid.Col sm={12}>
					<TextInput label="Email" {...form.getInputProps('Email')} />
				</Grid.Col>
				<Grid.Col sm={12} md={6}>
					<TextInput label="Home Phone" {...form.getInputProps('HomePhone')} />
				</Grid.Col>
				<Grid.Col sm={12} md={6}>
					<TextInput label="Cell Phone" {...form.getInputProps('MobilePhone')} />
				</Grid.Col>
				<Grid.Col sm={12} md={6}>
					<Textarea label="Location" withAsterisk {...form.getInputProps('Location')} />
				</Grid.Col>				
				<Grid.Col sm={12} md={6}>
					<Textarea label="Emergency Contact" withAsterisk {...form.getInputProps('EmergencyContact')} />
				</Grid.Col>
			</Grid>
			<Divider mt="lg" mb="xs" />

			{/* <AddressEdit participant={participant} /> */}
			<Group position="apart">
				<Title order={4}>Address</Title>
				<Menu shadow="md">
					<Menu.Target>
						<Tooltip label="Autofill Address" position="top">
							<ActionIcon radius="xl">
								<IconDotsCircleHorizontal />
							</ActionIcon>
						</Tooltip>
					</Menu.Target>
					<Menu.Dropdown>
						{getAddresses().map((address, index) => {
							return <Menu.Item key={index} onClick={() =>autofillAddress(address)}>{formatAddress(address, true)}</Menu.Item>
						})}
					</Menu.Dropdown>
				</Menu>
			</Group>
			<Grid>
				{/* <Grid.Col sm={12}>
					<TextInput label="Description" {...form.getInputProps('Description')} withAsterisk />
				</Grid.Col> */}
				<Grid.Col sm={6}>
					<TextInput label="Street Line 1" {...form.getInputProps('Address.Street1')} withAsterisk />
				</Grid.Col>
				<Grid.Col sm={6}>
					<TextInput label="Street Line 2" {...form.getInputProps('Address.Street2')} />
				</Grid.Col>
				{/* <Grid.Col sm={6}>
					<TextInput label="Street Line 3" {...form.getInputProps('Street3')} />
				</Grid.Col>	 */}
			</Grid>
			<Grid>
				<Grid.Col sm={6}>
					<TextInput label="City" {...form.getInputProps('Address.City')} withAsterisk />
				</Grid.Col>
				<Grid.Col sm={6}>
					{(form.values?.Address?.Country === "Canada" || form.values?.Address?.Country === "United States") &&
					<Select label={getProvinceLabel(form.values?.Address?.Country)} data={getProvincesStates(form.values?.Address?.Country).map(p => { return { value: p, label: p } })} {...form.getInputProps('Address.Province')} />
					}
					{(form.values?.Address?.Country !== "Canada" && form.values?.Address?.Country !== "United States") &&
					<TextInput label="Region" {...form.getInputProps('Address.Region')} />
					}
				</Grid.Col>
				<Grid.Col sm={6}>
					<TextInput label={getPostalCodeLabel(form.values?.Address?.Country)} {...form.getInputProps('Address.PostalCode')} withAsterisk />
				</Grid.Col>
				<Grid.Col sm={6}>
					<Select label="Country" data={getCountries().map(c => { return { value: c, label: c } })} {...form.getInputProps('Address.Country')} />
				</Grid.Col>
			</Grid>

			<Divider mt="lg" mb="xs" />

			<Group mt="md">
				<Button type="submit" leftIcon={<IconCheck />} hidden={!form.isDirty() && !form.isTouched()}>
					Save Changes
				</Button>
				<Button type="button" variant="subtle" onClick={() => close()} leftIcon={<IconX />}>
					{form.isDirty() || form.isTouched() ? "Discard Changes" : "Close"}
				</Button>
				{/* <Button type="submit" variant="light" hidden={!form.isDirty() && !form.isTouched()}>
					Save Changes &amp; Add Contact Details
				</Button> */}
			</Group>

			</form>
			{/* <Button variant="subtle" disabled={isLoadingRegistration} size="sm" mt="md" mb="md" leftIcon={<IconPlus />}>Add Participant</Button> */}

		</Drawer>
	</>
}

export default ParentGuardianEditor