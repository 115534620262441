import { useMsal } from '@azure/msal-react';
import { loginRequest } from "../authConfig";

import {
	TextInput,
	PasswordInput,
	Checkbox,
	Anchor,
	Paper,
	Title,
	Text,
	Container,
	Group,
	Button,
  } from '@mantine/core';
  
  export function Login() {
	const { instance } = useMsal();

	const title = import.meta.env.VITE_TITLE;
	const org = import.meta.env.VITE_ORG;

	const handleLogin = (loginType: string) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }

	return (
	  <Container size={420} my={40}>
		{/* <Title
		  align="center"
		  sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
		>
		  Welcome to MBC Online Forms!
		</Title>
		<Text color="dimmed" size="sm" align="center" mt={5}>
		  Do not have an account yet?{' '}
		  <Anchor size="sm" component="button">
			Create account
		  </Anchor>
		</Text> */}
  
		<Paper withBorder shadow="md" p={30} mt={30} radius="md">
			<Title order={3} align="center"	sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}>
				Welcome to
			</Title>
			<Title align="center" mb="lg" sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}>
				{title}
			</Title>
		  {/* <TextInput label="Email" placeholder="you@mantine.dev" required />
		  <PasswordInput label="Password" placeholder="Your password" required mt="md" />
		  <Group position="apart" mt="lg">
			<Checkbox label="Remember me" />
			<Anchor component="button" size="sm">
			  Forgot password?
			</Anchor>
		  </Group> */}
		  <Text>To access this site, you must sign in with your {org} Microsoft account.</Text>
		  <Button fullWidth mt="xl" onClick={() => handleLogin("redirect")}>
			Sign in
		  </Button>
		</Paper>
	  </Container>
	);
  }