import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { Group, Stack, Title, Divider, TextInput, Button, Loader, ActionIcon, Center, Text } from "@mantine/core"
import { useDebouncedValue, useFocusTrap } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconAlertTriangle, IconCheck, IconSearch, IconX } from "@tabler/icons-react"
import { useQueryClient } from "react-query";
import _ from "lodash";
import ProgramRegistrationSearchResultsTable from "../../Components/Search/SearchResultsTable";
import { RoleTemplate } from "../../Components/RoleTemplate";
import { ProgramRegistrationDocument, useSearchQuery, searchIndexProgramRegistrationsQuery } from "../../api/search.api";

interface Params {
	urlQuery: string|undefined;
}
const SearchPage = () => {
	const history = useHistory();
	const queryClient = useQueryClient();
	const { urlQuery } = useParams<Params>();
	const [query, setQuery] = useState(urlQuery ?? "");
	const [debounced] = useDebouncedValue(query, 1000);
	const { data: searchResults, isLoading } = useSearchQuery(debounced);
	const focusTrapRef = useFocusTrap();

	const [groupedSearchResults, setGroupedSearchResults] = useState<ProgramRegistrationDocument[][]|undefined>([]);
	
	useEffect(() => {
		if (searchResults !== undefined) {
			let grouped = [];

			let programGroups = _.uniq(searchResults?.map(r => r.ProgramGroup));
			for (let programGroup of programGroups) {
				grouped.push(searchResults?.filter(r => r.ProgramGroup === programGroup))			
			}

			setGroupedSearchResults(grouped);
		}

	}, [searchResults]);

	useEffect(() => {
		if (debounced !== "")
			history.replace({ pathname: `/search/${encodeURI(query)}` })
		else
			history.replace({ pathname: `/search` })
			// window.history.replaceState(null, "", `/search/${encodeURI(query)}`);
		queryClient.invalidateQueries('search');
	}, [debounced]);

	useEffect(() => {
		return history.listen(() => {
			let parts = history.location.pathname.split('/');
			if (parts.length > 2) {
				let q = parts[parts.length - 1];
				setQuery(q);
			} else {
				setQuery("");
				setGroupedSearchResults([]);
			}
		})
	}, [history]);

	const reIndexSearch = async () => {
		
		showNotification({
			id: 'search-reindex',
			loading: true,
			title: 'Rebuilding Search Index ',
			message: 'Please wait while the search index is rebuilt',
			autoClose: false,
			withCloseButton: false
		});

		await searchIndexProgramRegistrationsQuery();

		updateNotification({
			id: 'search-reindex',
			color: 'green',
			icon: <IconCheck size={16} />,
			title: 'Search Re-Index Completed',
			message: 'The search index has been rebuilt successfully',
			autoClose: 3000
		});
	}

	const clearQuery = () => { 
		setQuery("");
		setGroupedSearchResults([]);
		history.replace({ pathname: `/search` });
	}

	return <>
		<Group position="apart">
			<Stack spacing={0}>
			{/* <Title order={5} color="gray">PROGRAM SESSION</Title> */}
			<Title order={2} mb="sm">Search{searchResults && query !== "" && ` Results for "${debounced}"`}</Title>
			{/* <Text>{programSession?.StartDate}</Text> */}
			</Stack>
			<Group mt={-10}>
				
				<TextInput ref={focusTrapRef} value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Search" radius="xl" icon={<IconSearch />} rightSection={isLoading ? <Loader size="sm" /> : query !== "" ? <ActionIcon radius="xl" variant="subtle" onClick={clearQuery}><IconX /></ActionIcon> : <></>} />
			</Group>
			{/* <Title order={2}>Enrollment {programSession?.Enrollment}/{programSession?.Limit}</Title> */}
			{/* <Button component={Link} to="/registrations" size="sm" sx={{ alignSelf: 'flex-start'}} mb="sm">See All Registrations...</Button> */}
		</Group>
		<Divider mb="lg" />

		{isLoading === false && query !== "" && groupedSearchResults && groupedSearchResults.map((results, index) => {
			return <ProgramRegistrationSearchResultsTable key={index} documents={results} />
		})}
		
		{isLoading === false && debounced !== "" && query !== "" && groupedSearchResults?.length === 0 && <>
			<Center p="xl">
				<Stack align="center">
					<IconAlertTriangle size={100} color="lightgray" />
					<Title order={2} color="dimmed">No results found</Title>
					<Text color="dimmed">There are no search results matching your query.</Text>
				</Stack>
			</Center>
		</>}

		{(!searchResults || query === "") &&
		<Center w="100%" p="xl">
			<Stack align="center">
				{isLoading && <>
					<Loader size="90"/>
					<Text color="dimmed">Searching for "{query}"...</Text>
				</>}
				{!isLoading && <>
					<IconSearch size="100" color="lightgray" />
					<Text color="dimmed">Please search for something and the results will appear here.</Text>
					<RoleTemplate role="role.admin">
						<Button onClick={reIndexSearch} size="xs" variant="light" sx={{ alignSelf: 'center' }}>Re-Index Typesense</Button>
					</RoleTemplate>
				</>}
				
			</Stack>
		</Center>
		}
	</>
}

export default SearchPage