import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Badge, Divider, Group, Text, Title } from "@mantine/core";
import { MRT_ColumnDef, MRT_Row, MantineReactTable } from "mantine-react-table";
import moment from "moment-timezone";
import { ProgramRegistration } from "../../models/programregistration.models";
import { ProgramRegistrationDocument } from "../../api/search.api";
import { formatDateRange } from "../../functions/programregistration.functions";

interface Props {
	documents: ProgramRegistrationDocument[];
}

const ProgramRegistrationSearchResultsTable = ({ documents }: Props) => {
	const history = useHistory();

	const data = useMemo(() => documents.map(document => {
		return {
			id: document.id,
			Participants: document.Participants?.join(", ") ?? "",
			ProgramGroup: document.ProgramGroup,
			Program: document.Program,
			Dates: formatDateRange(document.DateFrom, document.DateTo),
			DateFrom: document.DateFrom,
			DateTo: document.DateTo,
			Submitted: document.DateSubmitted,
			Deleted: document.Deleted
			// Registration: document.Registration,
			// Participants: getParticipantName(document.Registration, document.ProgramSessionEnrollment.ParticipantId),
			// Gender: getGender(getParticipant(document.Registration, document.ProgramSessionEnrollment.ParticipantId)!),
			// Age: getAge(getParticipant(document.Registration, document.ProgramSessionEnrollment.ParticipantId)! as ChildParticipant),
			// EnrollmentDate: moment(document.ProgramSessionEnrollment.EnrollmentDate).toISOString(),//`${moment(enrollment.ProgramSessionEnrollment.EnrollmentDate).format("LLL")}`,
			// WaitList: `${document.ProgramSessionEnrollment.WaitList ? "Yes": "No"}`
		}
	}), [documents]);

	const columns = useMemo<MRT_ColumnDef[]>(() => [
		// {
		// 	accessorKey: 'Id',
		// 	header: 'ID',
		// 	size: 60,
		// 	filterFn: numberFilterFn,
		// },
		{
			accessorKey: 'Participants',
			header: 'Participants',
			// accessorFn: (row: ProgramSessionEnrollmentRegistration) => getParticipantName(row.Registration, row.ProgramSessionEnrollment.ParticipantId),
			// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
			// filterFn: stringFilterFn,
		},
		// {
		// 	accessorKey: 'ProgramGroup',
		// 	header: 'Type',
		// 	// accessorFn: (row: ProgramSessionEnrollmentRegistration) => getGender(getParticipant(row.Registration, row.ProgramSessionEnrollment.ParticipantId)!),
		// 	// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
		// 	// filterFn: stringFilterFn,
		// },
		{
			accessorKey: 'Program',
			header: 'Program',
			// accessorFn: (row: ProgramSessionEnrollmentRegistration) => getAge(getParticipant(row.Registration, row.ProgramSessionEnrollment.ParticipantId)! as ChildParticipant),
			// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
			// filterFn: stringFilterFn,
		},
		{
			accessorKey: 'DateFrom',
			header: 'Dates',
			// Cell: ({ cell }) => {
			// 	return moment(cell.getValue() as string).format("LLL")
			// }
			// accessorFn: (row: any) => `${moment(row.DateFrom).format("LLL")}`,
			Cell: ({ row }) => <div>{(row.original as any).Dates}</div>//cell.getValue() != "" ? cell.getValue() : "(unavailable)",
			// filterFn: dateFilterFn,
		},
		// {
		// 	accessorKey: 'WaitList',
		// 	header: 'Wait List',
		// 	// accessorFn: (row: ProgramSessionEnrollmentRegistration) => `${row.ProgramSessionEnrollment.WaitList ? "Yes": "No"}`,
		// 	// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
		// 	// filterFn: stringFilterFn,
		// }
		{
			accessorKey: 'Submitted',
			header: 'Submitted On',
			// accessorFn: (row: any) => moment(row.SubmissionDate).toDate(),
			Cell: ({ cell }) => {
				return moment(cell.getValue() as string).format("LLL")
			}
			// filterFn: dateFilterFn,
		},
		{
			accessorKey: 'Deleted',
			header: 'Status',
			Cell: ({ cell }) => {
				return cell.getValue() as boolean ? <Badge color="red" variant="filled">Deleted</Badge> : <Badge color="green" variant="light">Registered</Badge>
			}
		}
	], []);

	const handleRowClick = (row: MRT_Row<ProgramRegistrationDocument>) => {
		history.push({ pathname: `/registrations/view/${row.original.id}`});
	}
	
	return <>
		<Group position="apart">
			<Title order={3} mb="sm">{data[0]?.ProgramGroup ?? "Registrations" }</Title>
			<Text weight="bold">{data.length} results</Text>
		</Group>
		<Divider />

		<MantineReactTable 
				columns={columns} 
				data={data} 
				enableFullScreenToggle={false}
				enableDensityToggle={false}
				enableTopToolbar={false}
				enableHiding={false}
				// enableColumnFilters={false}
				enableColumnActions={false}
				initialState={{
					density: "xs",
				}}
				mantinePaperProps={{
					withBorder: false,
					shadow: ""
				}}
				mantineTableBodyRowProps={({ row }) => ({
					onClick: () => handleRowClick((row as any)),//row.getToggleSelectedHandler(),
					sx: { cursor: 'pointer' },
				})}
				mantineTableHeadCellProps={{
					sx: { fontVariantCaps: 'all-small-caps' }
				}}
			/>
	</>
}

export default ProgramRegistrationSearchResultsTable