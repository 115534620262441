import { useState } from "react";
import { Accordion, Button, Divider, Drawer, Grid, Group, Text, Title } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks";
import _ from "lodash";
import { IconCheck, IconCircle, IconCircleArrowRightFilled, IconCircleCheck, IconCircleDot, IconCircleFilled, IconCirclePlus, IconPencil, IconPlus, IconX } from "@tabler/icons-react"
import { useProgramRegistrationState } from "../../state/programregistration.state";
import { AdultParticipant, ParticipantBase } from "../../models/programregistration.models";
import { formatAddress } from "../../functions/programregistration.functions";
import { BillingContact } from "../../models/transaction.models";

interface PaymentBillingContactViewProps {
	onBillingContactChanged: (billingContact: BillingContact) => void;
}

const PaymentBillingContactView = ({ onBillingContactChanged }: PaymentBillingContactViewProps) => {
	const [opened, { open, close }] = useDisclosure(false);
	const { programRegistration, updateProgramRegistration, isLoading: isLoadingRegistration, getParticipants, getParentGuardians, isDeleted, status } = useProgramRegistrationState();
	const [selectedBillingContact, setSelectedBillingContact] = useState<string | null>(programRegistration.Data.Metadata.transaction?.billingContact?.Id ?? null);
	const [isChanged, setChanged] = useState(false);

	const getContacts = () => {
		let contacts: ParticipantBase[] = [];
		contacts.push(...getParticipants());
		contacts.push(...getParentGuardians());
		contacts = _.uniqBy(contacts, (c) => `${c.FirstName} ${c.LastName}`);
		return contacts.filter(p => p.Type === "adult");
	}

	const updateBillingContact = (value: string) => {
		if (value === null)
			return;
		setSelectedBillingContact(value);
		// setChanged(true);
		if (onBillingContactChanged) {
			let selection = getParticipants().find(p => p.Id === value);
			if (selection === undefined)
				selection = getParentGuardians().find(p => p.Id === value);
			onBillingContactChanged(selection as unknown as BillingContact);
		}
	}

	const handleSave = () => {
		let selection = getParticipants().find(p => p.Id === selectedBillingContact);
		if (selection === undefined)
				selection = getParentGuardians().find(p => p.Id === selectedBillingContact);
		if (programRegistration.Data.Metadata.transaction === undefined)
			programRegistration.Data.Metadata.transaction = { };
		programRegistration.Data.Metadata.transaction.billingContact = selection as unknown as BillingContact;
		updateProgramRegistration(programRegistration);
		close();
	}

	return <>
		{/* {selectedBillingContact === null && 
		<Button onClick={open} variant="light" size="sm" mt="md" leftIcon={<IconPlus />}>
			Add Billing Contact
		</Button>
		}
		{selectedBillingContact !== null && 
		<Button onClick={open} variant="light" size="sm" mt="md" leftIcon={<IconPencil />}>
			Edit Billing Contact
		</Button>
		} */}

		{/* <Drawer opened={opened} onClose={close} title={<div><Title order={3}>Billing Contact</Title></div>} position="right" withOverlay={true} size="md" withCloseButton overlayProps={{ opacity: 0.1, blur: 0 }}> */}
			<Text mb="md">Select a billing contact for this payment:</Text>
			
			<Accordion value={selectedBillingContact} onChange={updateBillingContact} variant="filled" chevron={false} radius="md">
			{getContacts().map((participant, index) => {
				let adultParticipant = participant.Type === "adult" ? participant as AdultParticipant : null;
				return <Accordion.Item key={index} value={participant.Id}>
					<Accordion.Control icon={selectedBillingContact === participant.Id ? <IconCircleArrowRightFilled /> : <IconCircle />}>{participant.FirstName} {participant.LastName}</Accordion.Control>
					<Accordion.Panel>
						<Grid mb="xs">
							{adultParticipant?.Email && <>
							<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Email:</Text></Grid.Col>
							<Grid.Col span={9} sm={9} py={0}><Text size="sm">{adultParticipant?.Email}</Text></Grid.Col>
							</>}
							{adultParticipant?.HomePhone && <>
							<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Home Phone:</Text></Grid.Col>
							<Grid.Col span={9} sm={9} py={0}><Text size="sm">{adultParticipant?.HomePhone}</Text></Grid.Col>
							</>}
							{adultParticipant?.MobilePhone && <>
							<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Cell Phone:</Text></Grid.Col>
							<Grid.Col span={9} sm={9} py={0}><Text size="sm">{adultParticipant?.MobilePhone}</Text></Grid.Col>
							</>}
						</Grid>
						<Grid>
							<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Address:</Text></Grid.Col>
							<Grid.Col span={9} sm={9} py={0}><Text size="sm">{formatAddress(participant.Address)}</Text></Grid.Col>
						</Grid>
					</Accordion.Panel>
				</Accordion.Item>
			})}
				{/* <Accordion.Item value="add">
					 <Accordion.Control icon={selectedBillingContact === "add" ? <IconCircleArrowRightFilled color="red" /> : <IconCircle />}>Other Billing Contact</Accordion.Control>
					 <Accordion.Panel>
						
					 </Accordion.Panel>
				</Accordion.Item> */}
			</Accordion>


			{/* <Divider mt="lg" mb="xs" /> */}

			{/* <Group mt="md" position="right">
				{isChanged &&
				<Button onClick={handleSave} type="submit" leftIcon={<IconCheck />}>
					Save Changes
				</Button>
				}
				<Button type="button" variant="subtle" onClick={() => close()} leftIcon={<IconX />}>
					{isChanged ? "Discard Changes" : "Close"}
				</Button>
			</Group> */}
		{/* </Drawer> */}
	</>
}

export default PaymentBillingContactView

interface BillingContactViewProps {
	billingContact: ParticipantBase;
}
const BillingContactView = ({ billingContact }: BillingContactViewProps) => {
	return <>
		{billingContact.FirstName}
	</>
}