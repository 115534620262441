export interface Program {
	Id: number;
	ProgramGroupId: number;
	Title: string;
	ShortDescription: string;
	Description: string;
	Price: number;
	PriceType: ProgramPriceType;
	AvailableDate?: Date;
	MinimumAgeMonths?: number;
	MinimumAgeYears?: number;
	MaximumAgeMonths?: number;
	MaximumAgeYears?: number;
	ImageURL: string;
	SortIndex: number;
	Hidden: boolean;
	Disabled: boolean;
	Deleted: boolean;
}

export enum ProgramPriceType {
	PerPerson = 0,
	PerGroup = 1
}


export interface ProgramGroup {
	Id: number;
	Title: string;
	ShortDescription: string;
	Description: string;
	ImageURL: string;
	Policies: string;
	SavePaymentMethod: boolean;
	ParticipantType: ProgramParticipantType;
	AllowWaitlist: boolean;
	SessionSelectionType: SessionSelectionType;
	ActivitySelectionType: ActivitySelectionType;
	Hidden: boolean;
}

export enum ProgramParticipantType {
	AdultsAndChildren = 0,
	Adults = 1,
	Children = 2,
	ChildrenPreSelected = 3
}

export enum SessionSelectionType {
	Fixed = 0, // Session selections cannot be customized
	Custom = 1 // Allows customizing session selections
}

export enum ActivitySelectionType {
	Single = 0, // The same activity must be selected for each session during the same time period
	Multi = 1 // Different activities can be selected for any session during the same time period
}

export interface ProgramSession {
	Id: number;
    ProgramId: number;
    StartDate: string;
    EndDate: string;
    Enrollment: number;
    Limit: number;
	AccessCodeRequired: boolean;
}

export interface ProgramSessionEnrollment {
	Id: number,
	ProgramSessionId: number,
    RegistrationId: number,
	ParticipantId: string,
    EnrollmentDate: string,
    WaitList: boolean
}

export interface ProgramSessionSelection {
	ProgramSession: ProgramSession;
	Quantity: number;
}

export interface ProgramActivity {
	Id: number;
	ProgramGroupId: number;
	Title: string;
	ShortDescription: string;
	Description: string;
	Price: number;
	PriceType: ProgramActivityPriceType;
	PriceDescription: string;
	Hidden: boolean;
	Disabled: boolean;
}

export enum ProgramActivityPriceType {
	None = 0,
	PerPerson = 1,
	PerSession = 2
}

export interface ProgramActivitySession {
	Id: number;
	ActivityId: number;
	ProgramIds: number[];
	StartDate: string;
	EndDate: string;
	Enrollment: number;
	Limit: number;
}

export interface ProgramActivitySessionEnrollment {
	Id: number,
	ProgramSessionId: number,
	ProgramActivitySessionId: number,
    RegistrationId: number,
	ParticipantId: string,
    EnrollmentDate: string,
    WaitList: boolean
}