import { hookstate, useHookstate, extend } from "@hookstate/core";
import { devtools } from "@hookstate/devtools";
import { localstored, StoreEngine } from "@hookstate/localstored";
import { createEmptyParentGuardian } from "../functions/programregistration.functions";
import { FamilyRegistration } from "../models/familyregistration.models";
import _ from "lodash";
import { Address, ParentGuardian, ProgramRegistration } from "../models/programregistration.models";

interface IFamilyRegistrationState {
	familyRegistration?: FamilyRegistration;
	initial?: FamilyRegistration;
	isChanged: boolean;
	isLoading: boolean;
	isSaving: boolean;
	successfulSaves: ProgramRegistration[];
	failedSaves: ProgramRegistration[];
	// isValid: boolean;
}

const createEmptyFamilyRegistration = () => {
	let familyRegistration: FamilyRegistration = {
		DateFrom: null,
		DateTo: null,
		ParentGuardians: [],
		ProgramRegistrations: [],
	};

	return familyRegistration;
}

const FamilyRegistrationState = hookstate(
	{ isChanged: false, isLoading: false } as IFamilyRegistrationState, 
	extend(
		localstored({
			key: 'admin-family-registration-v1',
			engine: sessionStorage as StoreEngine,
			initializer: () => new Promise<IFamilyRegistrationState>((resolve, reject) => { 
				let emptyRegistration = createEmptyFamilyRegistration();
				resolve({ 
					familyRegistration: emptyRegistration,
					initial: emptyRegistration,
					isChanged: false,
					isLoading: false,
					isSaving: false,
					successfulSaves: [],
					failedSaves: []
				})
			})
		}),			
		devtools({ key: 'admin-family-registration' })
	)
);


export const useFamilyRegistrationState = () => {
	let state = useHookstate(FamilyRegistrationState);

	return {
		familyRegistration: state.familyRegistration.get({ noproxy: true }) as FamilyRegistration,
		initial: state.initial.get({ noproxy: true }),
		setFamilyRegistration: (familyRegistration: FamilyRegistration|undefined, changed = true) => state.set({ 
			familyRegistration: familyRegistration, 
			initial: familyRegistration ? _.cloneDeep(familyRegistration) : undefined, 
			isChanged: changed, 
			isLoading: false,
			isSaving: false,
			successfulSaves: [],
			failedSaves: []
			// isValid: validatePerson(person)
		}),
		updateFamilyRegistration: (familyRegistration: FamilyRegistration|undefined, changed = true) => { 
			state.familyRegistration.set(familyRegistration);
			state.isChanged.set(changed);
			// state.isValid.set(validatePerson(person));
		},
		isValid: () => { 
			let familyRegistration = state.familyRegistration.get({ noproxy: true }) as FamilyRegistration;
			if (familyRegistration.ParentGuardians.length === 0 || familyRegistration.ProgramRegistrations.length == 0)
				return false;

			return true;
		},
		isChanged: state.isChanged.get({ noproxy: true }),
		isLoading: state.isLoading.get({ noproxy: true }),
		setIsLoading: (loading: boolean) => state.isLoading.set(loading),
		isSaving: state.isSaving.get({ noproxy: true }),
		setIsSaving: (saving: boolean) => state.isSaving.set(saving),
		isCompleted: state.successfulSaves.length > 0 || state.failedSaves.length > 0,

		successfulSaves: state.successfulSaves.get({ noproxy: true }),
		addSuccessfulSave: (programRegistration: ProgramRegistration) => {
			state.successfulSaves.set([...state.successfulSaves.get(), programRegistration]);
		},
		isSuccessfullySaved: (programRegistration: ProgramRegistration) => {
			let r = state.successfulSaves.find(p => p.value.Data.Id == programRegistration.Data.Id);
			return r != null;
		},

		failedSaves: state.failedSaves.get({ noproxy: true }),
		addFailedSave: (programRegistration: ProgramRegistration) => {
			state.failedSaves.set([...state.failedSaves.get(), programRegistration]);
		},		

		reset: () => state.set({ 
			familyRegistration: _.cloneDeep(state.initial.get({ noproxy: true })), 
			initial: state.initial.get({ noproxy: true }), 
			isChanged: false,
			isLoading: false,
			isSaving: false,
			successfulSaves: [],
			failedSaves: []
			// isValid: false
		}),

		getParentGuardians: () => state.familyRegistration.get({ noproxy: true })?.ParentGuardians as ParentGuardian[],
		addParentGuardian: () => {
			let programRegistration = state.familyRegistration.get({ noproxy: true }) as FamilyRegistration;

			programRegistration.ParentGuardians.push(createEmptyParentGuardian());

			state.familyRegistration.set(programRegistration);
			state.isChanged.set(true);
		},
		deleteParentGuardian: (parentGuardianId: string) => {
			let familyRegistration = state.familyRegistration.get({ noproxy: true }) as FamilyRegistration;

			familyRegistration.ParentGuardians.splice(familyRegistration.ParentGuardians.findIndex(p => p.Id === parentGuardianId), 1);

			state.familyRegistration.set(familyRegistration);
			state.isChanged.set(true);
		},

		// getProgramRegistrations: () => state.familyRegistration.get({ noproxy: true })?.ProgramRegistrations as ProgramRegistration[],

		getAddresses: () => {
			let addresses: Address[] = [];
			let familyRegistration = state.familyRegistration.get({ noproxy: true }) as FamilyRegistration;
				
			for (let parentGuardian of familyRegistration.ParentGuardians) {
				if (parentGuardian.Address !== undefined && addresses.filter(a => a.Street1 === parentGuardian.Address?.Street1).length === 0)
				addresses.push(_.cloneDeep(parentGuardian.Address));
			}
				
			return addresses;
		},
	}
}