import { Tooltip, ActionIcon, Drawer, Title, Text, Select, Box, Button, Divider, Group, Checkbox } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks";
import { IconCheck, IconPencil, IconX } from "@tabler/icons-react"
import moment from "moment-timezone";
import _ from "lodash";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import { useProgramActivitySessionsByProgramIdQuery } from "../../api/programactivitysession.api";
import { useProgramActivitiesQuery, useProgramActivitySessionsQuery, useProgramSessionsByProgramIdsQuery } from "../../api/program.api";
import { formatDateRange } from "../../functions/programregistration.functions";
import { useProgramSessionByIdQuery } from "../../api/programsession.api";
import { ProgramActivitySession } from "../../models/programs.models";
import { useEffect, useState } from "react";

interface SessionActivityEditorProps {
	participantId: string;
	programSessionId: number;
}

const SessionActivityEditor = ({ participantId, programSessionId }: SessionActivityEditorProps) => {
	const [opened, { open, close }] = useDisclosure(false);
	const { programRegistration, updateProgramRegistration, isLoading: isLoadingRegistration, isDeleted, status } = useProgramRegistrationState();
	const { data: programSession } = useProgramSessionByIdQuery(programSessionId);
	const { data: programSessions } = useProgramSessionsByProgramIdsQuery([programSession?.ProgramId ?? 0]);
	const { data: programActivities } = useProgramActivitiesQuery();
	const { data: programActivitySessions } = useProgramActivitySessionsByProgramIdQuery(programRegistration.Data.ProgramId, programRegistration.Data.DateFrom ?? new Date().toISOString(), programRegistration.Data.DateTo ?? new Date().toISOString());
	// const { data: programActivitySessionsUngrouped } = useProgramActivitySessionsQuery();

	const participantActivities = programRegistration.Data.ProgramSessions.find(p => p.ProgramSessionId == programSessionId)?.Participants.find(a => a.ParticipantId == participantId);
	// const getProgramActivitySession = (id: number) => programActivitySessions?.find(s => s.Id === id);
	const getProgramActivity = (id: number) => programActivities?.find(a => a.Id === id);

	const [isChanged, setChanged] = useState(false);
	const [selections, setSelections] = useState<number[]>([]);
	const [updateAllDays, setUpdateAllDays] = useState(true);

	useEffect(() => {
		setSelections(participantActivities?.ActivitySessionIds ?? []);
		setChanged(false);
	}, [opened]);

	const getProgramActivitySessions = () => {
		let results = programActivitySessions?.filter(p => { 
			return p.ProgramIds.includes(programRegistration.Data.ProgramId) &&
				moment(p.StartDate).isSameOrAfter(moment(programSession?.StartDate)) &&
				moment(p.EndDate).isSameOrBefore(moment(programSession?.EndDate));
		}) ?? [];

		results = results.sort((a, b) => { return moment(a.StartDate).isBefore(moment(b.StartDate)) ? -1 : 1 });

		let sessionsGroupedByTime: ProgramActivitySession[][] = [];
		let times = _.uniq(results.map(r => moment(r.StartDate).toISOString()));

		for (let time of times) {
			let classesForTime = results.filter(r => moment(r.StartDate).toISOString() === time);
			sessionsGroupedByTime.push(classesForTime);
		}
		
		return sessionsGroupedByTime;
	}

	const updateSelection = (index: number, value: string|null) => {
		let copy = _.cloneDeep(selections);
		copy[index] = Number(value);
		setSelections(copy);
		setChanged(true);
	}

	const canSave = () => {
		return true;
	}

	const handleSave = () => {
		if (participantActivities) {
			participantActivities.ActivitySessionIds = selections;

			if (updateAllDays) {
				let dateFrom = moment(programSession?.StartDate).startOf("week");
				let dateTo = moment(programSession?.EndDate).endOf("week");

				let rpsToUpdate = programRegistration.Data.ProgramSessions.filter(s => s.ProgramSessionId !== programSessionId);
				for (let rps of rpsToUpdate) {
					let participant = rps.Participants.find(p => p.ParticipantId === participantId);
					if (!participant)
						continue;
					for (let selection of selections) {
						let index = selections.findIndex(s => s === selection);
						let pas = programActivitySessions?.find(s => s.Id === selection);
						let pa = programActivities?.find(a => a.Id === pas?.ActivityId);
						let ps = programSessions?.find(s => s.Id === rps.ProgramSessionId);

						if (ps === undefined || moment(ps.StartDate).isBefore(dateFrom) || moment(ps.StartDate).isAfter(dateTo))
							continue;
							
						let pass = programActivitySessions?.filter(s => moment(s.StartDate).isSameOrAfter(moment(ps!.StartDate)) && moment(s.EndDate).isSameOrBefore(moment(ps!.EndDate)));
						
						let match = pass?.find(s => s.ActivityId === pa?.Id && 
							moment.duration(moment(s.StartDate).diff(moment(s.StartDate).startOf("day"))).asMinutes() === 
							moment.duration(moment(pas?.StartDate).diff(moment(pas?.StartDate).startOf("day"))).asMinutes() );

						if (participant.ActivitySessionIds.length >= index - 1 && match !== undefined)
							participant.ActivitySessionIds[index] = match?.Id ?? 0;
					}
				}
			}

			updateProgramRegistration(programRegistration);
		}
		close();
	}

	if (programActivitySessions !== undefined && programActivitySessions.length === 0)
		return <></>
		
	return <>
		<Tooltip label="Edit Skills Choices">
			<ActionIcon size="xs" onClick={open} disabled={isDeleted}>
				<IconPencil />
			</ActionIcon>
		</Tooltip>

		<Drawer opened={opened} onClose={close} title={<div><Title order={3}>Skills Choices</Title></div>} position="right" withOverlay={true} size="md" withCloseButton overlayProps={{ opacity: 0.1, blur: 0 }}>
			<Box h={600}>
				{getProgramActivitySessions().map((sessionsForTime, index) => {
					let items = sessionsForTime?.map(s => { return { label: `${getProgramActivity(s.ActivityId)?.Title ?? ""}`, value: s.Id.toString() }}).sort((a, b) => a.label < b.label ? -1 : 1) ?? [];
					let selected = selections[index] ?? 0;
					return <div key={index}>
						{/* {moment(sessionsForTime[0].StartDate).format("h:mm A")} */}
						<Select data={items} value={selected.toString()} onChange={(value) => updateSelection(index, value)} label={formatDateRange(sessionsForTime[0].StartDate, sessionsForTime[0].EndDate)} mb="md"></Select>
					</div>
				})}

				<Checkbox label="Update all days" checked={updateAllDays} onChange={(e) => setUpdateAllDays(e.target.checked)} />

				<Divider mt="lg" mb="xs" />

				<Group mt="md" position="right">
					{isChanged &&
					<Button onClick={handleSave} disabled={!canSave()} type="submit" leftIcon={<IconCheck />}>
						Save Changes
					</Button>
					}
					<Button type="button" variant="subtle" onClick={() => close()} leftIcon={<IconX />}>
						{isChanged ? "Discard Changes" : "Close"}
					</Button>
				</Group>

			</Box>

			{/* {participantActivities?.ActivitySessionIds.map((a, i) => {
				let sessions = programActivitySessions?.filter(s => s.ProgramIds.includes(programSession?.ProgramId ?? 0) && 
					moment(s.StartDate).isSameOrAfter(programSession?.StartDate) &&
					moment(s.EndDate).isSameOrBefore(programSession?.EndDate));

				// sessions = _.uniqBy(sessions, (s) => s.ActivityId);

				let selectedSession = getProgramActivitySession(a);
				let items = sessions?.map(s => { return { label: `${getProgramActivity(s.ActivityId)?.Title ?? ""} ${formatDateRange(s.StartDate, s.EndDate)}`, value: s.Id.toString() }}) ?? [];
				if (!selectedSession)
					return <></>;
				return <div key={i}>
					<Select data={items} value={selectedSession.Id.toString()} label={formatDateRange(selectedSession.StartDate, selectedSession.EndDate)} mb="md"></Select>
				
				</div>
			})} */}
		</Drawer>
	</>
}

export default SessionActivityEditor