import { useEffect, useState } from "react";
import { ActionIcon, Button, Center, Container, Drawer, Group, Loader, Paper, ScrollArea, Stack, Text, TextInput, Title, createStyles, rem } from "@mantine/core"
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCheck, IconSearch, IconX } from "@tabler/icons-react"
import { useQueryClient } from "react-query";
import moment from "moment-timezone";
import { ProgramParticipantDocument, searchIndexProgramParticipantsQuery, useParticipantSearchQuery } from "../../api/search.api";
import { formatAddress, getAge, getGender } from "../../functions/programregistration.functions";
import { ChildParticipant, ParticipantBase } from "../../models/programregistration.models";
import { RoleTemplate } from "../RoleTemplate";

interface PersonSearchModelProps {
	type: "adult"|"child";
	onSelected: (person: ProgramParticipantDocument) => void;
}

const useStyles = createStyles((theme) => ({
	searchResultButton: {
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.blue[0],
			borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.blue[3]
			// border: `${rem(1)} solid ${
			// 	theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[3]
			// } !important`
		}
	}

}));

const PersonSearchModal = ({ type, onSelected }: PersonSearchModelProps) => {
	const queryClient = useQueryClient();
	const { classes, cx } = useStyles();
	const [opened, { open, close }] = useDisclosure(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [debounced] = useDebouncedValue(searchQuery, 1000);
	const { data: allSearchResults, isLoading } = useParticipantSearchQuery(debounced);

	const searchResults = allSearchResults?.filter((r, i) => r.Type === type && 
		allSearchResults.findIndex(r2 => r2.FirstName?.trim() === r.FirstName?.trim() && r2.LastName?.trim() === r.LastName?.trim()) === i) ?? [];
	
	useEffect(() => {
		queryClient.invalidateQueries('search-participant');
	}, [debounced]);
	
	const reIndexSearch = async () => {
		
		showNotification({
			id: 'search-reindex',
			loading: true,
			title: 'Rebuilding Search Index ',
			message: 'Please wait while the search index is rebuilt',
			autoClose: false,
			withCloseButton: false
		});

		await searchIndexProgramParticipantsQuery();

		updateNotification({
			id: 'search-reindex',
			color: 'green',
			icon: <IconCheck size={16} />,
			title: 'Search Re-Index Completed',
			message: 'The search index has been rebuilt successfully',
			autoClose: 3000
		});
	}

	const clearQuery = () => { 
		setSearchQuery("");
	}

	const handleResultClicked = (result: ProgramParticipantDocument) => {
		onSelected(result);
		close();
	}
	
	return <>
		<Button onClick={open} variant="subtle" fullWidth leftIcon={<IconSearch />} mb="xs">Search for a person...</Button>

		<Drawer opened={opened} onClose={close} title={<Title order={3}>Search for a Person</Title>} position="right" withOverlay={true} size="md" withCloseButton overlayProps={{ opacity: 0.1, blur: 0 }}>
			<TextInput value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} data-autofocus placeholder="Search by Name, phone or email" icon={<IconSearch />} rightSection={isLoading ? <Loader size="sm" /> : searchQuery !== "" ? <ActionIcon variant="subtle" onClick={clearQuery}><IconX /></ActionIcon> : <></>} />

			{(!searchResults || searchQuery === "") &&
			<Center h={300}>
				<Stack align="center">
					{isLoading && <>
						<Loader size="90"/>
						<Text color="dimmed">Searching for "{searchQuery}"...</Text>
					</>}
					{!isLoading && <>
						<IconSearch size="100" color="lightgray" />
						<Text color="dimmed">Search results will appear here</Text>
						<RoleTemplate role="role.admin">
							<Button variant="light" onClick={reIndexSearch}>Re-index Person Search</Button>
						</RoleTemplate>
					</>}
				</Stack>
			</Center>
			}

			{isLoading === false && searchQuery !== "" && searchResults !== undefined && 
				<Container mt="md" p={0}>
					{searchResults.map((result, index) => {
						return <Paper key={index} className={cx(classes.searchResultButton)} onClick={() => handleResultClicked(result)} p="xs" withBorder shadow="md" mb="sm">
								<Group spacing="xs">
									<Text weight="bold">{result.Salutation} {result.FirstName} {result.LastName}</Text>
									{result.Type === "adult" && <Text size="sm">({getGender(result as ParticipantBase)}, Adult)</Text>}
									{result.Type === "child" && <Text size="sm">
										({getGender(result as ParticipantBase)}, {moment(result.Birthdate).format("LL")}, Age {getAge(result as ChildParticipant, new Date())})
									</Text>}
								</Group>
								<Text size="xs" color="dimmed">{formatAddress(result.Address, true)}</Text>
								{((result.HomePhone && result.HomePhone !== "") || (result.MobilePhone && result.MobilePhone !== "")) && 
								<Text size="xs" color="dimmed">Home: {result.HomePhone ?? "n/a"}, Mobile: {result.MobilePhone ?? "n/a"}</Text>
								}
								{result.Location && result.Location !== "" &&
								<Text size="xs" color="dimmed">Location: {result.Location}</Text>
								}
								{result.EmergencyContact && result.EmergencyContact !== "" && 
								<Text size="xs" color="dimmed">Emergerncy Contact: {result.EmergencyContact}</Text>
								}
							</Paper>
					})}
				</Container>
			}

			{/* <Group mt="md">
				<Button type="submit" leftIcon={<IconCheck />}>
					Use Selected Person
				</Button>
				<Button type="button" variant="subtle" onClick={() => close()} leftIcon={<IconX />}>
					Close
				</Button>
			</Group> */}
		</Drawer>
	</>
}

export default PersonSearchModal