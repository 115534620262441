import { useEffect, useMemo, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Badge, Center, Divider, Group, Loader, Menu, ScrollArea, Text, Title, UnstyledButton, useMantineColorScheme, useMantineTheme } from "@mantine/core"
import { IconChevronDown, IconCircle, IconCircleCheckFilled } from "@tabler/icons-react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from "moment"
import { useProgramGroupsQuery, useProgramSessionsByProgramIdsQuery, useProgramsQuery } from "../../api/program.api"
import { ProgramSession } from "../../models/programs.models"
import { getProgram } from "../../functions/program.functions"
import { useLocalStorage } from "@mantine/hooks"
import { DatesSetArg } from "@fullcalendar/core"

const ViewCalendarPage = () => {
	const location = useLocation();
	const history = useHistory();
	const theme = useMantineTheme();
	const colorScheme = useMantineColorScheme();
	const { data: programs, isLoading: isLoadingPrograms } = useProgramsQuery();
	const { data: programGroups } = useProgramGroupsQuery();
	const [selectedProgramGroup, setSelectedProgramGroup] = useLocalStorage({ key: 'calendar-view-program-group', defaultValue: 0 });
	const calendarRef = useRef<FullCalendar>(null);
	
	const programIds = programs?.filter(p => selectedProgramGroup > 0 ? p.ProgramGroupId === selectedProgramGroup : true).map(p => p.Id) ?? [];
	const { data: sessions, isLoading: isLoadingSessions } = useProgramSessionsByProgramIdsQuery(programIds);


	const isLoading = isLoadingPrograms || isLoadingSessions;

	const events = useMemo(() => sessions?.map((session) => {
		const program = getProgram(programs, session.ProgramId);
		return {
			title: program?.Title,
			start: moment(session.StartDate).toDate(),
			end: moment(session.EndDate).toDate(),
			color: colorScheme.colorScheme === "light" ? theme.colors.gray[2] : theme.colors.gray[8],// session.Limit - session.Enrollment < 5 ? '#a04343' : session.Limit - session.Enrollment < 10 ? 'orange' : '#486a67',
			textColor: colorScheme.colorScheme === "light" ? theme.colors.dark[9] : theme.colors.dark[0],// "#000",
			// borderColor: "red",
			sortIndex: program?.SortIndex,
			extendedProps: {
				session: session,
				color: session.Limit - session.Enrollment <= 0 ? 'red' : ((session.Limit - session.Enrollment) / session.Limit) < .2 ? 'orange' : 'green',
			}
		}
	}) ?? [], [sessions, colorScheme]);
	
	const startDate = events[0]?.start ?? new Date();
	const endDate = events[events.length - 1]?.end ?? new Date();

	const futureEvents = events?.filter(e => moment(e.start).isAfter(moment())) ?? [];
	const defaultSelectedStartDate = futureEvents.length > 0 ? futureEvents[0].start : startDate;
	const defaultSelectedEndDate = futureEvents.length > 0 ? futureEvents[futureEvents.length - 1].end : endDate;

	let from = defaultSelectedStartDate;//startDate;
	let to = defaultSelectedEndDate;//endDate;

	if (location.search) {
		let params = new URLSearchParams(location.search);
		if (params.get("from") !== null && params.get("to") !== null) {
			from = moment(params.get("from")).toDate();
			to = moment(params.get("to")).toDate();
		}
	}


	const [seletedDateRange, setSelectedDateRange] = useState<{ start: Date, end: Date}>({ start: from, end: to });// useLocalStorage({ key: 'calendar-view-dates', defaultValue: { start: startDate, end: moment(startDate).add(1, "week").toDate() } });

	useEffect(() => {
		if (events.length > 0) {
			setSelectedDateRange({ start: defaultSelectedStartDate, end: defaultSelectedEndDate });
			from = defaultSelectedStartDate;
			to = defaultSelectedEndDate;
			let queryString = `from=${moment(defaultSelectedStartDate).toISOString()}&to=${moment(defaultSelectedEndDate).toISOString()}`;

			const calendarApi = calendarRef?.current?.getApi();
			calendarApi?.gotoDate(from);

			setTimeout(() => {
				const calendarApi = calendarRef?.current?.getApi();
				calendarApi?.gotoDate(from);
			}, 100);
		}
	}, [isLoadingSessions]);

	const renderEventContent = (eventInfo: any) => {
		let programSession = eventInfo.event.extendedProps.session as ProgramSession;
		return <div style={{ padding: '5px' }}>
			{/* <div className="fc-event-time" style={{ alignSelf: 'start' }}>{eventInfo.timeText}</div> */}
			<div className="fc-event-title-container">
				{/* <div className="fc-event-title fc-sticky"> */}
				<Group position="apart">
					<small>{moment(programSession.StartDate).format("h:mm A")}</small>
					<Badge color={eventInfo.event.extendedProps.color} variant="filled" size="sm">{programSession.Enrollment}/{programSession.Limit}</Badge>
				</Group>
				<Text truncate="end" weight="bold">{eventInfo.event.title}</Text> 
					
				{/* </div> */}
			</div>
		</div>
	}

	const datesChanged = (arg: DatesSetArg) => {
// 		if (isLoadingSessions)
// 			return;
// 		let queryString = `from=${moment(arg.start).toISOString()}&to=${moment(arg.end).toISOString()}`;
// console.log(arg.start)
// 		if (location.search !== `?${queryString}` && events.length > 0) {
// 			console.log(location.search)
// 			console.log(`from=${moment(arg.start).toISOString()}`)
// 			history.replace({ search: queryString })
// 		}
		// if (moment(arg.start).isSame(moment(seletedDateRange.start), "date") === false || 
		// 	moment(arg.end).isSame(moment(seletedDateRange.end), "date") === false) {
			
		// 	setSelectedDateRange({ start: arg.start, end: arg.end });
		// }
	}

	const handleEventClick = (info: any) => {
		
		var selectedProgramSession = info.event.extendedProps.session as ProgramSession;

		history.push({ pathname: `/calendar/session/${selectedProgramSession.Id}`});
	}

	return <>
		<Group position="apart" mb="sm">
			<Title order={2}>Enrollment Calendar</Title>
			<Menu shadow="md" position="bottom-end">
				<Menu.Target>

					<UnstyledButton sx={(theme) => ({
							// display: 'block',
							// width: '100%',
							// padding: theme.spacing.md,
							borderRadius: '5px',
							color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

							'&:hover': {
							backgroundColor:
								theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.blue[0],
							},
						})}
					>
						<Title order={2}>
							{selectedProgramGroup === 0 ? "All Programs" : programGroups?.find(p => p.Id === selectedProgramGroup)?.Title} <IconChevronDown />
						</Title>
					</UnstyledButton>
				</Menu.Target>
				<Menu.Dropdown>
					<Menu.Item icon={selectedProgramGroup === 0 ? <IconCircleCheckFilled /> : <IconCircle />} onClick={() => setTimeout(() => setSelectedProgramGroup(0), 100)}><Text weight={selectedProgramGroup === 0 ? "bold":"normal"}>All Programs</Text></Menu.Item>
					{programGroups?.map((programGroup, index) => {
						return <Menu.Item key={index} icon={selectedProgramGroup === programGroup.Id ? <IconCircleCheckFilled /> : <IconCircle />} onClick={() => setTimeout(() => setSelectedProgramGroup(programGroup.Id), 100)}><Text weight={selectedProgramGroup === programGroup.Id ? "bold":"normal"}>{programGroup.Title}</Text></Menu.Item>
					})}
				</Menu.Dropdown>
			</Menu>
			{/* <Button component={Link} to="/registrations" size="sm" sx={{ alignSelf: 'flex-start'}} mb="sm">See All Registrations...</Button> */}
		</Group>
		<Divider mb="sm" />

		{isLoading && <Center h={150} mx="auto">
			<Loader />
		</Center>}
		
		{!isLoading &&
		<ScrollArea miw={1000} w="100%">
			{/* <Container miw={1000}> */}
			<FullCalendar
				ref={calendarRef}
				plugins={[ dayGridPlugin ]}
				initialView="dayGridWeek"
				events={events}
				height={"calc(100vh - 230px)"}
				validRange={{ start: startDate, end: endDate }}
				// initialDate={moment(seletedDateRange.start).startOf('week').toDate()}
				headerToolbar={{
					start: 'title',
					center: 'dayGridMonth,dayGridWeek',
					end: 'today,prev,next'
				}}
				firstDay={6}
				eventContent={renderEventContent}
				eventDisplay="block"
				displayEventTime={false}
				datesSet={datesChanged}
				eventClick={handleEventClick}
				eventOrder={[{ field: 'sortIndex' }]}
			/>
			{/* </Container> */}
			</ScrollArea>
		}
	</>
}

export default ViewCalendarPage