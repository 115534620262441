import { useEffect, useState } from "react";
import { Group, Title, Tooltip, ActionIcon, Grid, Drawer, useMantineTheme, TextInput, Divider, Button, createStyles, Select } from "@mantine/core";
import { useForm, yupResolver } from '@mantine/form';
import { IconEdit, IconHome, IconPencil } from "@tabler/icons-react";
import _ from "lodash";
import { Address, ParticipantBase } from "../../models/programregistration.models";

import * as Yup from "yup";
import { useProgramRegistrationState } from "../../state/programregistration.state";

export const AddressSchema = Yup.object().shape({
	// Description: Yup.string()
	// 	.max(50, 'Maximum length is 50 characters')
	// 	.required('Required'),
	Street1: Yup.string()
		.max(50, 'Maximum length is 50 characters')
		.required('Required'),
	Street2: Yup.string()
		.max(40, 'Maximum length is 40 characters'),
	// Street3: Yup.string()
	// 	.max(30, 'Maximum length is 30 characters'),
	City: Yup.string()
		.max(50, 'Maximum length is 50 characters')
		.min(1, 'Required')
		.required('Required'),
	Region: Yup.string()
		.notRequired()
		.nullable()
		.max(50, 'Maximum length is 50 characters'),
	Province: Yup.string()
		.max(50, 'Maximum length is 50 characters'),
		// .when("Country", { is: "Canada", then: Yup.string().required('Required'), otherwise: Yup.string() })
		// .when("Country", { is: "United States", then: Yup.string().required('Required'), otherwise: Yup.string() }),
	PostalCode: Yup.string()
		.max(20, 'Maximum length is 50 characters')
		.required('Required'),
		// .when("Country", { is: "Canada", then: Yup.string().required('Required'), otherwise: Yup.string() })
		// .when("Country", { is: "United States", then: Yup.string().required('Required'), otherwise: Yup.string() }),
	Country: Yup.string()
		.max(50, 'Maximum length is 50 characters')
		.required('Required')
});

export const isAddressValid = (address: Address) : boolean => {
	return AddressSchema.isValidSync(address);
}

interface FormFields {
	// Description: string;
	Street1: string;
	Street2: string;
	// Street3: string;
	City: string;
	// Region: string;
	Province: string;
	PostalCode: string;
	Country: string;
}

const useStyles = createStyles((theme) => ({
	drawer: {
		overflowY: 'scroll'
	}
}));

interface AddressEditProps {
	participant?: ParticipantBase;
	// address?: Address;
	// familyAddress: boolean;
	// addressIndex?: number;
	defaultOpened?: boolean;
}


const AddressEdit = ({ participant, defaultOpened = false }: AddressEditProps) => {
	const theme = useMantineTheme();
	const { programRegistration, updateProgramRegistration } = useProgramRegistrationState();
	// const { person, updatePerson } = usePersonState();
	const [editOpened, setEditOpened] = useState(defaultOpened);
	const form = useForm<FormFields>({
		validate: yupResolver(AddressSchema)
	});
	// const { mutate: savePerson, isLoading: saving } = usePersonSaveMutation();

	const { classes } = useStyles();

	useEffect(() => {
		form.setValues({
			// Description: address?.Description,
			Street1: participant?.Address?.Street1,
			Street2: participant?.Address?.Street2,
			// Street3: address?.Street3,
			City: participant?.Address?.City,
			Province: participant?.Address?.Province,
			PostalCode: participant?.Address?.PostalCode,
			// Region: address?.Region,
			Country: participant?.Address?.Country
		});
		form.resetDirty();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [participant?.Address, editOpened]);

	const handleSubmit = (values: FormFields) => {
		// var updatedPerson = _.cloneDeep(person!);
		
		// if (familyAddress) {
		// 	updatedPerson.Family.Address.Description = values.Description;
		// 	updatedPerson.Family.Address.Street1 = values.Street1;
		// } else if (!familyAddress && addressIndex !== undefined) {
		// 	updatedPerson.Addresses[addressIndex].Description = values.Description;
		// 	updatedPerson.Addresses[addressIndex].Street1 = values.Street1;
		// }

		// updatePerson(updatedPerson);
		let p = programRegistration?.Data.Participants.find(p => p.Id === participant!.Id);
		p!.Address!.Street1 = values.Street1;
		updateProgramRegistration(programRegistration);

		setEditOpened(false);

		// showNotification({
		// 	id: 'person-address-save',
		// 	loading: true,
		// 	title: 'Saving your changes',
		// 	message: 'Please wait while your changes are saved',
		// 	autoClose: false,
		// 	disallowClose: true
		// });

		// savePerson(updatedPerson, { 
		// 	onSuccess: () => {
		// 		// form.reset();
		// 		setEditOpened(false);
				
		// 		updateNotification({
		// 			id: 'person-address-save',
		// 			title: 'Success',
		// 			message: 'Address has been saved',
		// 			color: 'green',
		// 			icon: <IconCheck size={16} />,
		// 			autoClose: 4000
		// 		});
		// 	}
		// })
	}

	return <>
		{/* <Tooltip label="Edit Address" withArrow>
			<ActionIcon onClick={() => setEditOpened(true)} variant="subtle" radius="sm" color="blue" className="edit-icon"><IconEdit /></ActionIcon>
		</Tooltip> */}
		<Button onClick={() => setEditOpened(true)} variant="light" size="xs" mb="md" sx={{ alignSelf: "flex-start" }}><IconPencil /> Edit Address</Button>
		<Drawer opened={editOpened} onClose={() => { form.reset(); setEditOpened(false); } } title={<Group spacing="xs"><IconHome /><Title order={3}>Edit Address</Title></Group>} position="right" padding="xl" size="md" className={classes.drawer}>
			
			<form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
			<Grid>
				{/* <Grid.Col sm={12}>
					<TextInput label="Description" {...form.getInputProps('Description')} withAsterisk />
				</Grid.Col> */}
				<Grid.Col sm={6}>
					<TextInput label="Street Line 1" {...form.getInputProps('Street1')} withAsterisk />
				</Grid.Col>
				<Grid.Col sm={6}>
					<TextInput label="Street Line 2" {...form.getInputProps('Street2')} />
				</Grid.Col>
				{/* <Grid.Col sm={6}>
					<TextInput label="Street Line 3" {...form.getInputProps('Street3')} />
				</Grid.Col>	 */}
			</Grid>
			<Grid>
				<Grid.Col sm={6}>
					<TextInput label="City" {...form.getInputProps('City')} withAsterisk />
				</Grid.Col>
				<Grid.Col sm={6}>
					<Select label="Province" data={[{ value: "Ontario", label: "Ontario" }]} {...form.getInputProps('Province')} />
				</Grid.Col>
				<Grid.Col sm={6}>
					<TextInput label="Postal Code" {...form.getInputProps('PostalCode')} withAsterisk />
				</Grid.Col>
				<Grid.Col sm={6}>
					<Select label="Country" data={[{ value: "Canada", label: "Canada" }, { value: "United States", label: "United States" }]} {...form.getInputProps('Country')} />
				</Grid.Col>
				{/* <Grid.Col sm={6}>
					<TextInput label="Region" {...form.getInputProps('Region')} />
				</Grid.Col>	 */}
			</Grid>
			<Divider mt="lg" mb="sm" />
		
			
			<Group position="right" mt="md">
				<Button type="button" variant="subtle" onClick={() => setEditOpened(false)}>
					{form.isDirty() ? "Discard Changes" : "Close"}
				</Button>
				<Button type="button" onClick={() => handleSubmit(form.values)} hidden={!form.isDirty()}>
					Save Changes
				</Button>
			</Group>
			</form>
			
		</Drawer>
	
	</>
}

export default AddressEdit