import { useEffect, useRef, useState } from "react";
import { ActionIcon, Box, Button, Divider, Drawer, Grid, Group, Select, Space, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { modals } from '@mantine/modals';
import { IconCheck, IconClock, IconPencil, IconPlus, IconX } from "@tabler/icons-react";
import moment from "moment-timezone";
import { useProgramRegistrationState } from "../../state/programregistration.state"
import { useProgramGroupsQuery, useProgramsQuery } from "../../api/program.api";
import { DatePickerInput, TimeInput } from "@mantine/dates";
import { useProgramSessionsByIdQuery } from "../../api/program.api";

interface FormFields {
	ProgramGroupId: string;
	ProgramId: string;
	DateFrom: Date;
	TimeFrom: string;
	DateTo: Date;
	TimeTo: string;
}

interface DetailsEditorProps {
	add: boolean
}

const DetailsEditor = ({ add = false }: DetailsEditorProps) => {
	const { programRegistration, updateProgramRegistration, isDeleted, status } = useProgramRegistrationState();
	const [opened, { open, close }] = useDisclosure(false);
	const startTimeRef = useRef<HTMLInputElement>();
	const endTimeRef = useRef<HTMLInputElement>();

	const form = useForm<FormFields>({
		// validate: participantType === "adult" ? yupResolver(AdultParticipantSchema) : yupResolver(ChildParticipantSchema)
		// validate: {
		// 	FirstName: (value) => (value.length === 0 ? 'Required' : null)
		// }
	});

	const { data: programGroups } = useProgramGroupsQuery();
	const programGroupListData = programGroups?.map(g => { return { label: g.Title, value: g.Id.toString() } }) ?? [];
	// const [selectedProgramGroup, setSelectedProgramGroup] = useState<string|null>(programRegistration?.ProgramGroupId?.toString() ?? null);

	const { data: programSessions } = useProgramSessionsByIdQuery(form.values.ProgramId ? Number(form.values.ProgramId) : 0);
	const minDate = programSessions != undefined ? moment(programSessions[0].StartDate).toDate() : undefined;
	const maxDate = programSessions != undefined ? moment(programSessions[programSessions.length -1].EndDate).toDate() : undefined;

	const { data: programs } = useProgramsQuery();
	const programListData = programs?.filter(p => p.ProgramGroupId.toString() === form.values.ProgramGroupId).map(p => { return { label: p.Title, value: p.Id.toString() } }) ?? [];

	const canEdit = programRegistration.Data.ProgramSessions.length === 0;

	const handleEdit = () => {
		if (!canEdit) {
			modals.openConfirmModal({ 
				title: "Edit Registration Details",
				centered: true,
				children: <Stack>
					<Text size="sm">
						You can't change the registration details unless there are no session enrollments.
					</Text>
					<Text size="sm">
						Unselect all session enrollments and then you will be able to edit the registration details.
					</Text>
				</Stack>,
				cancelProps: { sx: { display: 'none' } },
				labels: { confirm: 'Okay', cancel: null },
			});
		} else {
			open();
		}
	}

	useEffect(() => {
		if (programRegistration) {
			form.setValues({
				ProgramGroupId: programRegistration.Data.ProgramGroupId.toString(),
				ProgramId: programRegistration.Data.ProgramId.toString(),
				DateFrom: programRegistration.Data.DateFrom ? moment(programRegistration.Data.DateFrom ?? new Date()).startOf("day").toDate() : undefined,
				TimeFrom: programRegistration.Data.DateFrom ? moment(programRegistration.Data.DateFrom).format("HH:mm") : "12:00pm",
				DateTo: programRegistration.Data.DateTo ? moment(programRegistration.Data.DateTo ?? new Date()).startOf("day").toDate() : undefined,
				TimeTo: programRegistration.Data.DateFrom ? moment(programRegistration.Data.DateTo).format("HH:mm") : "12:00pm",
			});
			form.resetDirty();
			form.resetTouched();
		}
	}, [opened, programRegistration]);

	const handleSubmit = (values: FormFields) => {
		if (programRegistration !== undefined) {
			
			programRegistration.ProgramGroupId = Number(values.ProgramGroupId);
			programRegistration.Data.ProgramGroupId = programRegistration.ProgramGroupId;
			programRegistration.ProgramId = Number(values.ProgramId);
			programRegistration.Data.ProgramId = programRegistration.ProgramId;
			programRegistration.DateFrom = moment(values.DateFrom).startOf("day").add(moment.duration(values.TimeFrom)).toISOString();
			programRegistration.Data.DateFrom = programRegistration.DateFrom;
			programRegistration.DateTo = moment(values.DateTo).startOf("day").add(moment.duration(values.TimeTo)).toISOString();
			programRegistration.Data.DateTo = programRegistration.DateTo;

			updateProgramRegistration(programRegistration);
			close();
		}
	}

	return <>
		<Button onClick={handleEdit} disabled={isDeleted} variant="light" size="xs" mt={0} sx={{ alignSelf: 'flex-start' }} leftIcon={add ? <IconPlus /> : <IconPencil />}>
			{add ? <>Add Program &amp; Dates</> : <>Edit</>}
		</Button>

		{/* <Button variant="light" size="xs" mt={0} sx={{ alignSelf: 'flex-start' }}></Button> */}
		<Drawer opened={opened} onClose={close} title={<div><Title order={3}>Registration Details</Title></div>} position="right" withOverlay={true} size="md" withCloseButton overlayProps={{ opacity: 0.1, blur: 0 }}>
			<form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
				<Box mih={500}>
					<Grid>
						<Grid.Col sm={12}>
							<Select data={programGroupListData} label="Type" maxDropdownHeight={150} withAsterisk {...form.getInputProps('ProgramGroupId')} placeholder="Select program group..."></Select>
						</Grid.Col>
						<Grid.Col sm={12}>
							<Select data={programListData} label="Program" maxDropdownHeight={150} withAsterisk {...form.getInputProps('ProgramId')} placeholder="Select program..."></Select>
						</Grid.Col>
						<Grid.Col sm={6}>
							<DatePickerInput label="Start Date" placeholder="Selected a date" withAsterisk {...form.getInputProps('DateFrom')} minDate={minDate} maxDate={maxDate} />
							<TimeInput label="Start Time" ref={startTimeRef as any} rightSection={<ActionIcon onClick={() => startTimeRef?.current?.showPicker()}><IconClock size="1rem" stroke={1.5} /></ActionIcon>} withAsterisk withSeconds={false} {...form.getInputProps('TimeFrom')} mt="sm" />
						</Grid.Col>
						<Grid.Col sm={6}>
							<DatePickerInput label="End Date" placeholder="Selected a date" withAsterisk {...form.getInputProps('DateTo')} minDate={minDate} maxDate={maxDate} />
							<TimeInput label="End Time" ref={endTimeRef as any} rightSection={<ActionIcon onClick={() => endTimeRef?.current?.showPicker()}><IconClock size="1rem" stroke={1.5} /></ActionIcon>} withAsterisk withSeconds={false} {...form.getInputProps('TimeTo')} mt="sm" />
						</Grid.Col>
					</Grid>

					<Divider mt="lg" mb="xs" />

					<Group mt="md">
						<Button type="submit" leftIcon={<IconCheck />} hidden={!form.isDirty() && !form.isTouched()}>
							Save Changes
						</Button>
						<Button type="button" variant="subtle" onClick={() => close()} leftIcon={<IconX />}>
							{form.isDirty() ? "Discard Changes" : "Close"}
						</Button>
						{/* <Button type="submit" variant="light" hidden={!form.isDirty() && !form.isTouched()}>
							Save Changes &amp; Add Contact Details
						</Button> */}
					</Group>
				</Box>
			</form>
		</Drawer>
	</>
}

export default DetailsEditor