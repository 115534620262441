import { useEffect, useState } from 'react';
import {
  createStyles,
  Container,
  Avatar,
  UnstyledButton,
  Group,
  Text,
  Menu,
  Tabs,
  Burger,
  rem,
  Title,
  TabsValue,
  Header,
  TextInput,
  Button,
  Box,
  Kbd,
  Flex,
  MediaQuery,
  useMantineColorScheme,
  Drawer,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconLogout,
  IconHeart,
  IconStar,
  IconMessage,
  IconSettings,
  IconPlayerPause,
  IconTrash,
  IconSwitchHorizontal,
  IconChevronDown,
  IconSearch,
  IconMoonFilled,
  IconSunFilled,
} from '@tabler/icons-react';
import { useMsal } from '@azure/msal-react';
import { Link, useHistory } from 'react-router-dom';
import { RoleTemplate } from '../Components/RoleTemplate';
import NavigationMenu from './NavBar';
// import { MantineLogo } from '@mantine/ds';

const useStyles = createStyles((theme) => ({
  header: {
    // paddingTop: theme.spacing.xs,
	// paddingLeft: theme.spacing.md,
    // backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
    // borderBottom: `${rem(1)} solid ${
    //   theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background
    // }`,
    // marginBottom: rem(120),
	[theme.fn.smallerThan('sm')]: {
		height: '140px!important'
	}
},

mainSection: {
	// paddingBottom: theme.spacing.xs,
	padding: 0,
	boxShadow: theme.shadows.md
  },

  topBar: {
	backgroundColor: theme.colorScheme === "light" ? theme.colors.blue[9] : theme.colors.dark[8],
	color: theme.white,//theme.colorScheme === "light" ? theme.colors.gray[0] : theme.colors.gray[3],
	// paddingLeft: theme.spacing.lg,
	// paddingRight: theme.spacing.lg
	padding: `5px ${theme.spacing.lg}`,
	height: '50px', 

	a: {
		color: theme.white,
		textDecoration: 'none'
	}
  },

  user: {
    color: theme.white,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
	// marginTop: theme.spacing.xs,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
		// backgroundColor: theme.colorScheme === "light" ? theme.colors.blue[9] : theme.colors.dark[8]
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
        0.1
      ),
    },

    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('xs')]: {
      display: 'none',
    },
  },

  userActive: {
	// backgroundColor: theme.colorScheme === "light" ? theme.colors.gray[3] : theme.colors.dark[5]
    backgroundColor: theme.fn.lighten(
      theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
      0.1
    ),
  },

  tabs: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  tabsList: {
    borderBottom: '0 !important',
	marginLeft: theme.spacing.sm,
	marginRight: theme.spacing.sm
  },

  tab: {
    fontWeight: 500,
    height: rem(30),
	marginTop: '5px',
	marginBottom: '5px',
    // color: theme.white,
    backgroundColor: 'transparent',
    // borderColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
    borderRadius: '2rem',//'0.25rem 0.25rem 0.25rem 0.25rem',
	marginRight: '5px',
    '&:hover': {
		backgroundColor: theme.colorScheme === "light" ? theme.colors.blue[1] : theme.colors.dark[5]
    //   backgroundColor: theme.fn.lighten(
    //     theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
    //     0.8
    //   )
    },

    '&[data-active]': {
		// backgroundColor: theme.colorScheme === "light" ? theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background! : theme.colors.dark[4],
    //   backgroundColor: theme.fn.lighten(
    //     theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
    //     0.1
    //   ),
	color: theme.white
    //   borderColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
	//   '&::before': {
	// 	backgroundColor: 'unset'
	//   }
    },
  },
}));

interface HeaderTabsProps {
//   user: { name: string; image: string };
//   tabs: { label: string, url: string }[];
}

export function HeaderTabsColored({ }: HeaderTabsProps) {
	const history = useHistory();
	const { instance } = useMsal();
	const { classes, theme, cx } = useStyles();
	const [menuDrawerOpened, { toggle: toggleMenuDrawer }] = useDisclosure(false);
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const [userMenuOpened, setUserMenuOpened] = useState(false);
	const [selectedTab, setSelectedTab] = useState("");

	const title = import.meta.env.VITE_TITLE;
	const account = instance.getActiveAccount();
	const name = account?.name ?? "";

	useEffect(() => {
		setTab(history.location.pathname);
	}, []);

	useEffect(() => {
		return history.listen(() => {
			setTab(history.location.pathname);
		})
	}, [history])

	const setTab = (pathname: string) => {
		if (pathname.startsWith("/calendar"))
			setSelectedTab("/calendar")
		else if (pathname.startsWith("/registrations"))
			setSelectedTab("/registrations")
		else if (pathname.startsWith("/search"))
			setSelectedTab("/search")
		else
			setSelectedTab(pathname)
	}

	const tabSelected = (value: string) => {
		history.push({ pathname: value });
	}

//   const items = tabs.map((tab: { label: string, url: string }, index) => (
//     <Tabs.Tab value={tab.url} key={index}>
//       {tab.label}
//     </Tabs.Tab>
//   ));

	const handleSwitchAccount = () => {
		instance.loginRedirect({ scopes: ["openid", "profile", "offline_access"], prompt: "select_account" });
	}

  	const handleLogout = (logoutType: string) => {
		if (logoutType === "popup") {
			instance.logoutPopup({
				postLogoutRedirectUri: "/",
				// mainWindowRedirectUri: "/"
			});
		} else if (logoutType === "redirect") {
			instance.logout({
				onRedirectNavigate: () => false
			});
			// instance.logoutRedirect({
			//     postLogoutRedirectUri: "/",
			// });
		}
	}

  return (
	<Header className={classes.header} height={90} sx={{ borderBottom: 0 }}>
		<Drawer title={<Title order={4}>{title}</Title>} opened={menuDrawerOpened} onClose={toggleMenuDrawer} size={300}>
			<NavigationMenu />
		</Drawer>
    <div style={{ boxShadow: theme.shadows.md }}>
      <Container className={classes.mainSection} size="auto">
        <Group position="apart" className={classes.topBar}>
          {/* <MantineLogo size={28} inverted /> */}
		  <Group>
			<Title order={4}><Link to="/">{title}</Link></Title>
			
		  </Group>

		  {/* <TextInput icon={<IconSearch />} placeholder="Search" radius="xl" /> */}
		  <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>

		  <Button onClick={() => history.push({ pathname: "/search" })} variant="default" size="xs" sx={{ border: '0px' }} leftIcon={<IconSearch />} radius="xl" rightIcon={
			  <Flex align="center">
				{/* <Kbd mr={5}>⌘</Kbd>
				<span>+</span>
				<Kbd ml={5}>F</Kbd>
			<Kbd>⌘</Kbd> + <Kbd>F</Kbd> */}
		  </Flex>
		  }><Box w="200px"><Text align="left">Search</Text></Box></Button>
		  </MediaQuery>

          <Burger
            opened={menuDrawerOpened}
            onClick={toggleMenuDrawer}
            className={classes.burger}
            size="sm"
            color={theme.white}
          />

          <Menu
            width={260}
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
            withinPortal
          >
            <Menu.Target>
              <UnstyledButton
                className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
              >
                <Group spacing={7}>
                  <Avatar alt={name} radius="xl" size={20}></Avatar>
                  <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                    {name}
                  </Text>
                  <IconChevronDown size={rem(12)} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              {/* <Menu.Item
                icon={<IconHeart size="0.9rem" stroke={1.5} color={theme.colors.red[6]} />}
              >
                Liked posts
              </Menu.Item>
              <Menu.Item
                icon={<IconStar size="0.9rem" stroke={1.5} color={theme.colors.yellow[6]} />}
              >
                Saved posts
              </Menu.Item>
              <Menu.Item
                icon={<IconMessage size="0.9rem" stroke={1.5} color={theme.colors.blue[6]} />}
              >
                Your comments
              </Menu.Item>

              <Menu.Label>Settings</Menu.Label>
              <Menu.Item icon={<IconSettings size="0.9rem" stroke={1.5} />}>
                Account settings
              </Menu.Item> */}
			  <Menu.Item onClick={() => toggleColorScheme()} icon={colorScheme === "light" ? <IconMoonFilled size="0.9rem" stroke={1.5} /> : <IconSunFilled size="0.9rem" stroke={1.5} />}>
				{colorScheme === "light" && "Switch to Dark Theme"}
				{colorScheme === "dark" && "Switch to Light Theme"}
			  </Menu.Item>
			  <Menu.Divider />
              <Menu.Item icon={<IconSwitchHorizontal size="0.9rem" stroke={1.5} />} onClick={handleSwitchAccount}>
				Switch Account
              </Menu.Item> 
              <Menu.Item icon={<IconLogout size="0.9rem" stroke={1.5} />} onClick={() => handleLogout("redirect")}>
				Logout
			  </Menu.Item>

              {/* <Menu.Divider />

              <Menu.Label>Danger zone</Menu.Label>
              <Menu.Item icon={<IconPlayerPause size="0.9rem" stroke={1.5} />}>
                Pause subscription
              </Menu.Item>
              <Menu.Item color="red" icon={<IconTrash size="0.9rem" stroke={1.5} />}>
                Delete Account
              </Menu.Item> */}
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Container>
	  <Container size="xl" pl={0}>
	  <Tabs
				defaultValue="Home"
				value={selectedTab}
				onTabChange={tabSelected}
				variant="pills"
				classNames={{
					root: classes.tabs,
					tabsList: classes.tabsList,
					tab: classes.tab,
				}}
				>
				<Tabs.List>
					{/* {items} */}
					<Tabs.Tab value="/">
						Home
					</Tabs.Tab>
					<Tabs.Tab value="/calendar">
						Enrollment Calendar
					</Tabs.Tab>
					<Tabs.Tab value="/registrations">
						Registrations
					</Tabs.Tab>
					<Tabs.Tab value="/reports">
						Reports
					</Tabs.Tab>
					<Tabs.Tab value="/search">
						Search
					</Tabs.Tab>
					<RoleTemplate role="role.admin">
						<Tabs.Tab value="/admin">
							Admin
						</Tabs.Tab>
					</RoleTemplate>
				</Tabs.List>
			</Tabs>
	  </Container>
      <Container size="lg">
        
      </Container>
    </div>
	</Header>
  );
}