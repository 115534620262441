export const currency = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export const linkTitle = (title: string) => {
	return title.trim()
		.replace('@', ' at ')
		.replace('&', 'and')
		.replace('\'', '')
		.replace('(', '')
		.replace(')', '')
		.replace('[', '')
		.replace(']', '')
		.replace('{', '')
		.replace('}', '')
		.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
}