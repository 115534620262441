import { useEffect, useState } from 'react';
import { Button, Center, Divider, Group, Loader, Select, Stack, Switch, Text, Title } from '@mantine/core';
import { DatePickerInput, DateValue, DatesRangeValue } from '@mantine/dates';
import { IconExclamationCircle, IconReport } from '@tabler/icons-react';
import pdfMake from 'pdfmake/build/pdfmake';
import vfs from './pdfMakeFonts';
import _ from 'lodash';
import { ReportPDF } from './ReportPDF';
import { useReportListQuery, useReportQuery } from '../../api/report.api';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useProgramActivitiesQuery, useProgramGroupsQuery, useProgramsQuery } from '../../api/program.api';
import { getProgramGroupTitle } from '../../functions/program.functions';
import moment from 'moment';

pdfMake.vfs = vfs;

interface Params {
	urlReportKey: string|undefined;
}
const ReportPage = () => {
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);

	const { urlReportKey } = useParams<Params>();

	const [pdfFile, setPdfFile] = useState<string|null>();

	const dateFrom = urlParams.get("dateFrom") != null ? new Date(urlParams.get("dateFrom")!) : null;
	const dateTo = urlParams.get("dateTo") != null ? new Date(urlParams.get("dateTo")!) : null;
	const [selectedDateRange, setSelectedDateRange] = useState<DatesRangeValue|undefined>([dateFrom, dateTo]);
	
	const setSelectedDate = (value: DateValue) => {
		let range: DatesRangeValue = [value, value];
		setSelectedDateRange(range);
	}

	const [isRange, setIsRange] = useState(!moment(dateFrom).isSame(moment(dateTo), "date"));

	const setRangeToggle = (value: boolean) => {
		setIsRange(value);
		// setSelectedDateRange(range);
		if (!value) {
			// setSelectedDateRange(selectedDateRange[0], selectedDateRange[0]);
		}
	}
	
	const [reportKey, setReportKey] = useState<string|undefined>();
	const [reportParams, setReportParams] = useState<any>({});
	
	const { data: reports } = useReportListQuery();
	const { data: report, isLoading, isFetching, refetch, remove, isSuccess, isError, error } = useReportQuery(reportKey, reportParams);
	const reportListData = reports?.map(r => { return { label: r.Name, value: r.Key } }) ?? [];
	const [selectedReport, setSelectedReport] = useState<string|null>(urlReportKey ?? null);
	const selectedReportDefinition = reports?.find(r => r.Key === selectedReport);

	const { data: programGroups } = useProgramGroupsQuery();
	const programGroupListData = programGroups?.map(g => { return { label: g.Title, value: g.Id.toString() } }) ?? [];
	const [selectedProgramGroup, setSelectedProgramGroup] = useState<string|null>(urlParams.get("programGroupId") != null ? urlParams.get("programGroupId") : null);

	const { data: programs } = useProgramsQuery();
	// const programListData = programs?.filter(p => p.ProgramGroupId === Number(selectedProgramGroup)).map(p => { return { label: p.Title, value: p.Id.toString(), group: getProgramGroupTitle(programGroups, p.ProgramGroupId) } }) ?? [];
	const programListData = programs?.map(p => { return { label: p.Title, value: p.Id.toString(), group: getProgramGroupTitle(programGroups, p.ProgramGroupId) } }) ?? [];
	const [selectedProgram, setSelectedProgram] = useState<string|null>(urlParams.get("programId") != null ? urlParams.get("programId") : null);

	const { data: programActivities } = useProgramActivitiesQuery();
	const programActivityListData = programActivities?.map(a => { return { label: a.Title, value: a.Id.toString() }}) ?? [];
	const [selectedProgramActivity, setSelectedProgramActivity] = useState<string|null>(urlParams.get("programActivityId") != null ? urlParams.get("programActivityId") : null);


	const canGenerateReport = () => {
		if (!selectedReport)
			return false;

		if (!selectedReportDefinition)
			return false;

		if (selectedReportDefinition.Parameters.find(p => p.Name === "programId") != null && !selectedProgram)
			return false;

		if (selectedReportDefinition.Parameters.find(p => p.Name === "programGroupId") != null && !selectedProgramGroup)
			return false;

		if (selectedReportDefinition.Parameters.find(p => p.Name === "programActivityId") != null && !selectedProgramActivity)
			return false;

		if ((selectedReportDefinition.Parameters.find(p => p.Name === "dateFrom") != null || 
			(selectedReportDefinition.Parameters.find(p => p.Name === "dateTo") != null)) && 
			(selectedDateRange?.[0] === null || selectedDateRange?.[1] === null))
			return false;

		return true;
	}

	const showPrograms = () => {
		return selectedReportDefinition != null ? selectedReportDefinition.Parameters.find(p => p.Name === "programId") != null : false;
	}

	const showProgramGroups = () => {
		return selectedReportDefinition != null ? selectedReportDefinition.Parameters.find(p => p.Name === "programGroupId") != null : false;
	}

	const showProgramActivities = () => {
		return selectedReportDefinition != null ? selectedReportDefinition.Parameters.find(p => p.Name === "programActivityId") != null : false;
	}

	const createReport = () => {
		if (selectedReport && selectedDateRange) {
			remove();
			setReportParams({ programId: selectedProgram, programGroupId: selectedProgramGroup, programActivityId: selectedProgramActivity, dateFrom: selectedDateRange[0]?.toISOString(), dateTo: selectedDateRange[1]?.toISOString() });
			setReportKey(selectedReport);

			let search = new URLSearchParams();
			if (selectedReportDefinition) {
				for (let param of selectedReportDefinition?.Parameters) {
					if (param.Name === "programId")
						search.append(param.Name, selectedProgram ?? "");
					if (param.Name === "programGroupId")
						search.append(param.Name, selectedProgramGroup ?? "");
					if (param.Name === "programActivityId")
						search.append(param.Name, selectedProgramActivity ?? "");
					if (param.Name === "dateFrom")
						search.append(param.Name, selectedDateRange[0]?.toISOString() ?? "");
					if (param.Name === "dateTo")
						search.append(param.Name, selectedDateRange[1]?.toISOString() ?? "");
				}
				history.replace({ pathname: `/reports/${selectedReport}`, search: search.toString() })
			}
		}
		return;
	}
	
	useEffect(() => {	
		if (report) {
			let reportPDF = new ReportPDF(report);
			if (!reportPDF)
				return;
			var dd = reportPDF.getDocumentDefinition();
			// pdfMake.createPdf(dd).open();
			pdfMake.createPdf(dd).getBlob((blob) => {
				let url = window.URL.createObjectURL(blob);
				setPdfFile(url);
			});
		}
	}, [isLoading, isFetching])

	return <>
		<Group position="apart">
			<Stack spacing={0}>
				<Title order={2}>Reports</Title>
			</Stack>

			
			{/* <TextInput ref={focusTrapRef} value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Search" mb="sm" radius="xl" icon={<IconSearch />} rightSection={isLoading ? <Loader size="sm" /> : query !== "" ? <ActionIcon radius="xl" variant="subtle" onClick={clearQuery}><IconX /></ActionIcon> : <></>} /> */}
			{/* <Title order={2}>Enrollment {programSession?.Enrollment}/{programSession?.Limit}</Title> */}
			{/* <Button component={Link} to="/registrations" size="sm" sx={{ alignSelf: 'flex-start'}} mb="sm">See All Registrations...</Button> */}
			
			{/* <Select placeholder="Saved Reports..." data={[{ label: 'Enrollment Report', value: '1' }]} miw={260}></Select> */}
		</Group>
		<Divider mt="xs" mb="xs" />
		<Group position="apart">
			<Group>
				<Select data={reportListData} value={selectedReport} onChange={setSelectedReport} placeholder="Select report..." w={250}></Select>
				{selectedReport && <>
				{showProgramGroups() &&
				<Select data={programGroupListData} value={selectedProgramGroup} onChange={setSelectedProgramGroup} placeholder="Select program group..." maw={200}></Select>
				}
				{showPrograms() && 
				<Select data={programListData} value={selectedProgram} onChange={setSelectedProgram} placeholder="Select program..." maw={200}></Select>
				}
				{showProgramActivities() && 
				<Select data={programActivityListData} value={selectedProgramActivity} onChange={setSelectedProgramActivity} placeholder="Select skill..." maw={200}></Select>
				}
				<DatePickerInput type={isRange ? "range" : "default"} value={isRange ? selectedDateRange : (selectedDateRange ? selectedDateRange[0] : null)} onChange={(value) => isRange ? setSelectedDateRange(value as DatesRangeValue) : setSelectedDate(value as DateValue)} placeholder="Select dates..." miw={200} maw={400} />
				<Switch checked={isRange} onChange={(e) => setIsRange(e.currentTarget.checked)} label="Range" />
				</>}
			</Group>
			<Button onClick={createReport} disabled={!canGenerateReport() || isLoading || isFetching}>Generate Report</Button>
		</Group>
		<Divider mt="xs" mb="lg" />

		{!isLoading && !isFetching && !isSuccess && !isError && !pdfFile && <>
			<Center w="100%" p="xl">
				<Stack align="center">
					<IconReport size="100" color="lightgray" />
					<Text color="dimmed">Please select a report and the results will appear here.</Text>
				</Stack>
			</Center>
		</>}

		{(isLoading || isFetching) && <>
			<Center w="100%" p="xl">
				<Stack align="center">
					<Loader size="100" />
					<Text color="dimmed">Loading report...</Text>
				</Stack>
			</Center>
		</>}

		{(isError) && <>
			<Center w="100%" p="xl">
				<Stack align="center">
					<IconExclamationCircle size="100" color="red" />
					<Text color="dimmed">Unable to load report due to an error.</Text>
				</Stack>
			</Center>
		</>}

		{/* {isError && <pre>{(error as any).status}{JSON.stringify(error, null, 2)}</pre> } */}
		{(!isLoading && !isFetching && !isError) && pdfFile && <>
			<iframe src={pdfFile} style={{ width: '100%', height: "calc(100vh - 260px)", border: '0px' }} />

			{/* <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
				{Array.from(new Array(numPages), (el, index) => (
				<Page key={`page_${index + 1}`} pageNumber={index + 1} width={1000} renderTextLayer={false} renderAnnotationLayer={false}/>
				))}
			</Document>
			<p>
				Page {pageNumber} of {numPages}
			</p> */}
		</>}

		
	</>
	// return <EnrollmentReport />
	// return <PDFViewer style={{ width: '100%', height: 'calc(100vh - 150px)', border: '0px' }}>
	// 	<EnrollmentReport />
	// </PDFViewer>
}

export default ReportPage