import { Card, Group, Title, Center, Text, Loader, Grid } from "@mantine/core"
import { IconAdjustmentsHorizontal } from "@tabler/icons-react"
import { useProgramRegistrationState } from "../../state/programregistration.state";
import moment from "moment";
import { formatDateRange, formatDiscountAmount } from "../../functions/programregistration.functions";

const DiscountView = () => {
	const { programRegistration, isLoading: isLoadingRegistration, status } = useProgramRegistrationState();

	const isSetup = status.isSaved;

	if (!programRegistration.Data.PromotionCode)
		return <></>

	return <>
		<Card shadow="lg" withBorder mb="sm">
			<Group position="apart" noWrap>
				<Group spacing="xs" mb="sm" noWrap>
					<IconAdjustmentsHorizontal />
					<Title order={3} truncate>Discount</Title>
				</Group>

			</Group>


			{isLoadingRegistration && isSetup && <Center h={50} mx="auto">
				<Loader />
			</Center>}

			{!isLoadingRegistration && isSetup &&
			<Grid mb={0}>
				<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Discount Code:</Text></Grid.Col>
				<Grid.Col span={9} py={0}><Text weight="bold">{programRegistration.Data.PromotionCode?.Code}</Text></Grid.Col>
				<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Discount Amount:</Text></Grid.Col>
				<Grid.Col span={9} py={0}><Text>{programRegistration.Data.PromotionCode && formatDiscountAmount(programRegistration.Data.PromotionCode)}</Text></Grid.Col>
			</Grid>
			}
			
			{/* <Center h={100}>
				<Text>Nothing to see here</Text>
			</Center> */}
		</Card>
	</>
}

export default DiscountView