import { useQuery } from "react-query";
import { useAccess, useAccountId } from "../Auth/auth-functions";
import { api } from "./api";
import { ProgramSession, ProgramSessionEnrollment } from "../models/programs.models";
import { ProgramRegistration } from "../models/programregistration.models";

export const useProgramSessionByIdQuery = (programSessionId: number) => {
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-session-by-id', programSessionId, accountId], async () => {
		let res = await api.get<ProgramSession>(`/admin/programs/sessions/${programSessionId}`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: hasAccess && programSessionId > 0 });
}

export const useProgramSessionEnrollmentsByIdQuery = (programSessionId: number) => {
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-session-enrollments-by-id', programSessionId, accountId], async () => {
		let res = await api.get<{ ProgramSessionEnrollment: ProgramSessionEnrollment, Registration: ProgramRegistration }[]>(`/admin/programs/sessions/${programSessionId}/enrollments`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: hasAccess && programSessionId > 0 });
}

export const useProgramSessionEnrollmentsByRegistrationIdQuery = (registrationId: number) => {
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-session-enrollments-by-registration-id', registrationId, accountId], async () => {
		let res = await api.get<ProgramSessionEnrollment[]>(`/admin/programs/sessions/registration/${registrationId}/enrollments`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: hasAccess && registrationId > 0 });
}
