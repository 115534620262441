import { Navbar, createStyles, getStylesRef, rem } from "@mantine/core"
import { Link } from "react-router-dom"
import { RoleTemplate } from "../Components/RoleTemplate";

const useStyles = createStyles((theme) => ({
	navbar: {
		backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
	},

	version: {
		backgroundColor: theme.fn.lighten(
			theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
			0.1
		),
		color: theme.white,
		fontWeight: 700,
	},

	header: {
		paddingBottom: theme.spacing.md,
		marginBottom: `calc(${theme.spacing.md} * 1.5)`,
		borderBottom: `${rem(1)} solid ${theme.fn.lighten(
			theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
			0.1
		)}`,
	},

	footer: {
		paddingTop: theme.spacing.md,
		marginTop: theme.spacing.md,
		borderTop: `${rem(1)} solid ${theme.fn.lighten(
			theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
			0.1
		)}`,
	},

	link: {
		...theme.fn.focusStyles(),
		display: 'flex',
		alignItems: 'center',
		textDecoration: 'none',
		fontSize: theme.fontSizes.sm,
		color: theme.white,
		padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
		borderRadius: theme.radius.sm,
		fontWeight: 500,

		'&:hover': {
			backgroundColor: theme.fn.lighten(
				theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
				0.1
			),
		},
	},

	linkIcon: {
		ref: getStylesRef('icon'),
		color: theme.white,
		opacity: 0.75,
		marginRight: theme.spacing.sm,
	},

	linkActive: {
		'&, &:hover': {
			backgroundColor: theme.fn.lighten(
				theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
				0.15
			),
			[`& .${getStylesRef('icon')}`]: {
				opacity: 0.9,
			},
		},
	},
}));

const NavigationMenu = () => {
	const { classes, cx } = useStyles();
	
	return <>
		<Navbar height={700} width={{ sm: 300 }} p="md">
			<Navbar.Section grow>
				<Link to="/" className={cx(classes.link)}>Home</Link>
				<Link to="/calendar" className={cx(classes.link)}>Enrollment Calendar</Link>
				<Link to="/registrations" className={cx(classes.link)}>Registrations</Link>
				<Link to="/reports" className={cx(classes.link)}>Reports</Link>
				<Link to="/search" className={cx(classes.link)}>Search</Link>
				<RoleTemplate role="role.admin">
					<Link to="/admin" className={cx(classes.link)}>Admin</Link>
				</RoleTemplate>
			</Navbar.Section>
		</Navbar>
	</>
}

export default NavigationMenu