import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { Group, Title, Grid, Loader, Divider, Button, Menu, Text, Badge, Space, Checkbox, TextInput, Center } from "@mantine/core"
import { hideNotification, showNotification, updateNotification } from "@mantine/notifications";
import { modals } from '@mantine/modals';
import { IconCheck, IconChevronDown, IconX } from "@tabler/icons-react";
import _ from "lodash";
import { useProgramRegistrationDeleteMutation, useProgramRegistrationSaveMutation, useRegistrationByIdQuery } from "../../api/programregistration.api";
import { useProgramActivitiesQuery, useProgramActivitySessionsQuery, useProgramsQuery } from "../../api/program.api";
import { useUserPermissionQuery } from "../../api/user.api";
import { getParticipantsNames, getProgramRegistrationTotal } from "../../functions/programregistration.functions";
import DetailsView from "../../Components/ProgramRegistrations/DetailsView";
import ParticipantsView from "../../Components/ProgramRegistrations/ParticipantsView";
import SessionsView from "../../Components/ProgramRegistrations/SessionsView";
import ParentGuardiansView from "../../Components/ProgramRegistrations/ParentGuardiansView";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import { RoleTemplate } from "../../Components/RoleTemplate"
import { ErrorBoundary } from "../../Components/Shared/ErrorBoundary";
import ErrorBoundaryFallback from "../../Components/Shared/ErrorBoundaryFallback";
import PaymentView from "../../Components/ProgramRegistrations/PaymentView";
import CustomFieldsView from "../../Components/ProgramRegistrations/CustomFieldsView";
import NotFound from "../../Components/ProgramRegistrations/NotFoundView";
import DiscountView from "../../Components/ProgramRegistrations/DiscountView";

export interface ViewRegistationPageParams {
	registrationId: string;
}

const ViewRegistationPage = () => {
	const { registrationId } = useParams<ViewRegistationPageParams>();
	const { data: registrationData, isLoading: isLoadingRegistration, isSuccess: programRegistrationLoaded, isError: programRegistrationNotLoaded } = useRegistrationByIdQuery(Number(registrationId));
	const { mutate: saveProgramRegistration, isLoading: isSaving, isError, error } = useProgramRegistrationSaveMutation();
	const { mutate: deleteProgramRegistration } = useProgramRegistrationDeleteMutation();
	const { programRegistration, updateProgramRegistration, setProgramRegistration, isChanged, isDeleted, setIsLoading, reset } = useProgramRegistrationState();
	const { data: programs } = useProgramsQuery();
	const { data: programActivities } = useProgramActivitiesQuery();
	const { data: programActivitySessions } = useProgramActivitySessionsQuery();
	const { data: userPermissions } = useUserPermissionQuery();

	// const [programRegistrationDeleted, setProgramRegistrationDeleted] = useState(false);
	const participantNames = getParticipantsNames(programRegistration).join(', ');
	const total = getProgramRegistrationTotal(programRegistration, programs ?? [], programActivities ?? [], programActivitySessions ?? []);

	const [sendEmailOnSave, setSendEmailOnSave] = useState(false);
	const [sendEmailAddress, setSendEmailAddress] = useState("");
	
	useEffect(() => {
		if (programRegistration.Id != Number(registrationId))
			reset();
	}, []);

	useEffect(() => {
		if (registrationData !== undefined && registrationData !== null) {
			setProgramRegistration(_.cloneDeep(registrationData), false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [programRegistrationLoaded])

	useEffect(() => {
		setIsLoading(isLoadingRegistration);
	}, [isLoadingRegistration])

	const canDelete = userPermissions?.DeletePermission ?? false;

	const canSave = () => {

	}

	const onSave = () => { 
		const isNew = programRegistration.Id === 0;

		modals.openConfirmModal({
			title: 'Confirm Save',
			centered: true,
			children: <>
				<Text size="sm">
					Are you sure you want to save {!isNew && "the changes to"} this registration?
				</Text>
			</>,
			labels: { confirm: isNew ? 'Save' : 'Save Changes', cancel: 'Cancel' },
			confirmProps: { color: 'green' },
			// onCancel: () => console.log('Cancel'),
			onConfirm: () => performSave(),
		});

		// performSave();
	}

	const performSave = () => {
		showNotification({
			id: 'programregistration-save',
			loading: true,
			title: 'Saving your changes',
			message: 'Please wait while your changes are saved',
			autoClose: false,
			// disallowClose: true
		});
		
		saveProgramRegistration(programRegistration!, { 
			onSuccess: (response) => {
				setProgramRegistration(_.cloneDeep(response.data), false);
				updateNotification({
					id: 'programregistration-save',
					title: 'Success',
					message: 'Registration has been saved',
					color: 'green',
					icon: <IconCheck size={16} />,
					autoClose: 3000
				});
			}, onError: (error) => {
				let errorMessage = (error as any)?.response?.data?.message;

				if (programRegistration.Data.Metadata.transaction) {
					programRegistration.Data.Metadata.transaction = undefined;
					updateProgramRegistration(programRegistration);
				}

				updateNotification({
					id: 'programregistration-save',
					title: 'Error',
					message: <>
						<Text mb="sm">An error occurred while trying to save the registration</Text>
						<Text weight="bold">{(error as any)?.response?.data?.message}</Text>
					</>,
					color: 'red',
					icon: <IconX size={16} />,
					autoClose: 5000
				});
			}
		})
	}

	const onDelete = () => modals.openConfirmModal({
		title: 'Confirm delete',
		centered: true,
		children: (
			<Text size="sm">
				Are you sure you want to delete the registration for <strong>{participantNames}</strong>? This action cannot be undone.
			</Text>
		),
		labels: { confirm: 'Confirm Delete', cancel: 'Cancel' },
		confirmProps: { color: 'red' },
		// onCancel: () => console.log('Cancel'),
		onConfirm: () => performDelete(),
	});

	const performDelete = () => {
		showNotification({
			id: 'programregistration-delete',
			loading: true,
			title: 'Deleting registration',
			message: 'Please wait while the registration is deleted',
			autoClose: false,
			// disallowClose: true
		});
		
		deleteProgramRegistration(programRegistration!, {
			onSuccess: () => {
				programRegistration.Deleted = true;
				setProgramRegistration(programRegistration, false);
				// setProgramRegistrationDeleted(true);
				updateNotification({
					id: 'programregistration-delete',
					title: 'Success',
					message: 'Registration has been deleted',
					color: 'green',
					icon: <IconCheck size={16} />,
					autoClose: 3000
				});
			}, onError: (error) => {
				updateNotification({
					id: 'programregistration-delete',
					title: 'Error',
					message: 'An error occurred while trying to delete the registration',
					color: 'red',
					icon: <IconX size={16} />,
					autoClose: 3000
				});
			}
		})
	}

	const onCancel = () => {
		reset();
	}

	if (programRegistrationNotLoaded)
		return <NotFound />

	return <>
		<ErrorBoundary fallback={<ErrorBoundaryFallback />}>
		<RoleTemplate role="role.user.readonly" noAccess={<>You don't have access to this.</>}>
			<Group position="apart">
				<Title order={2} mb="sm">
					<Group spacing="xs">
						<span>Registration for:</span>
						<Text span variant="gradient" sx={{ lineHeight: "unset" }}>{participantNames}</Text>
						{isDeleted && <Badge color="red" variant="filled" size="lg">Deleted</Badge>}
						{isLoadingRegistration && <Loader size="sm" ml="xs" pt={3} />}
					</Group>
				</Title>

				<Group spacing="xs" mb="xs" noWrap sx={{ alignSelf: 'flex-start' }}>
					{!isLoadingRegistration && !isChanged && !isDeleted && 
					<Menu position="bottom-end">
						<Menu.Target>
							<Button variant="subtle" rightIcon={<IconChevronDown />}>Actions</Button>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item onClick={onDelete} disabled={!canDelete} color="red" icon={<IconX />}><Text weight="bold">Delete Registration</Text></Menu.Item>
						</Menu.Dropdown>
					</Menu>
					}
					{isChanged && <>
					<Button onClick={onSave} variant="filled" disabled={isSaving} hidden={!isChanged} leftIcon={<IconCheck />}>Save Changes</Button>
					<Button onClick={onCancel} variant="light" disabled={isSaving} hidden={!isChanged} leftIcon={<IconX />}>Discard Changes</Button>
					</>}
				</Group>
			</Group>
			<Divider />

			{!programRegistrationLoaded && 
				<Center h={500} mx="auto">
					<Loader />
				</Center>
			}

			{programRegistrationLoaded &&			
				<Grid mt="xs" mb="md">
					<Grid.Col xs={12} sm={6}>
						<DetailsView />
						<ParentGuardiansView />
						<ParticipantsView />
					</Grid.Col>
					<Grid.Col xs={12} sm={6}>
						<SessionsView />
						<CustomFieldsView />
						<DiscountView />
						<PaymentView />
					</Grid.Col>
					{/* <Grid.Col xs={12} sm={6} lg={4}>
						<DetailsView />
					</Grid.Col>
					<Grid.Col xs={12} sm={6} lg={4}>
						<ParticipantsView />
					</Grid.Col>
					<Grid.Col xs={12} sm={6} lg={12} orderLg={3}>
						<SessionsView />
					</Grid.Col>
					<Grid.Col xs={12} sm={6} lg={4} orderLg={2}>
						<ParentGuardiansView />
					</Grid.Col> */}
				</Grid>
			}

		</RoleTemplate>
		<RoleTemplate role="role.admin">
			<small>
			<pre>
				{JSON.stringify(programRegistration, null, 2)}
			</pre>
			</small>
		</RoleTemplate>
		</ErrorBoundary>
	</>
}

export default ViewRegistationPage