import { Card, Group, Title, Grid, Text, Center, Loader, Button } from "@mantine/core"
import { useParams } from "react-router-dom";
import { IconAdjustmentsHorizontal, IconPencil, IconPlus } from "@tabler/icons-react"
import moment from "moment"
import { useProgramsQuery, useProgramGroupsQuery } from "../../api/program.api";
import { useRegistrationByIdQuery } from "../../api/programregistration.api";
import { ViewRegistationPageParams } from "../../Pages/Registrations/View";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import DetailsEditor from "./DetailsEditor";
import { formatDateRange } from "../../functions/programregistration.functions";

const DetailsView = () => {
	// const { registrationId } = useParams<ViewRegistationPageParams>();
	// const { data: registration, isLoading: isLoadingRegistration } = useRegistrationByIdQuery(Number(registrationId));
	const { programRegistration, isLoading: isLoadingRegistration, status } = useProgramRegistrationState();
	const { data: programs, isLoading: isLoadingPrograms } = useProgramsQuery();
	const { data: programGroups, isLoading: isLoadingProgramGroups } = useProgramGroupsQuery();
	
	const program = programs?.find(p => p.Id === (programRegistration?.Data.ProgramId ?? 0));
	const programGroup = programGroups?.find(p => p.Id === (programRegistration?.Data.ProgramGroupId ?? 0));

	const isSetup = status.isDetailsSetup;// programRegistration != null && programRegistration.ProgramId > 0 && programRegistration.ProgramGroupId > 0;

	return <>
		<Card shadow="lg" withBorder mb="sm">
			<Group position="apart" noWrap>
				<Group spacing="xs" mb="lg" noWrap>
					<IconAdjustmentsHorizontal />
					<Title order={3} truncate>Registration Details</Title>
				</Group>
				{!isLoadingRegistration &&
				// <Button variant="light" size="xs" mt={0} sx={{ alignSelf: 'flex-start' }}><IconPencil /> Edit</Button>
				<DetailsEditor add={!isSetup} />
				}
				{/* <Button variant="light" mt={-15}><IconPencil /> Edit</Button> */}
			</Group>
			{(isLoadingRegistration || isLoadingPrograms || isLoadingProgramGroups) && isSetup && <Center h={126} mx="auto">
				<Loader />
			</Center>}
			{!isSetup && programRegistration && <>
				{/* <Button variant="light" size="sm" mt={0} sx={{ alignSelf: 'flex-start' }}><IconPlus /> Select Program &amp; Dates</Button> */}
				{/* <DetailsEditor add={true} /> */}
				<Center h={100}>
					<Text color="dimmed">Start by adding a program and selecting dates</Text>
				</Center>
			</>}
			{!isLoadingRegistration && isSetup &&
			<Grid mb="xs">
				<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Registration #:</Text></Grid.Col>
				<Grid.Col span={9} py={0}><Text weight="bold">{programRegistration.Data.Id}</Text></Grid.Col>
				<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Type:</Text></Grid.Col>
				<Grid.Col span={9} py={0}><Text>{programGroup?.Title}</Text></Grid.Col>
				<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Program:</Text></Grid.Col>
				<Grid.Col span={9} py={0}><Text>{program?.Title} ({program?.ShortDescription})</Text></Grid.Col>
				<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Dates:</Text></Grid.Col>
				<Grid.Col span={9} py={0}><Text>{formatDateRange(programRegistration?.Data?.DateFrom, programRegistration?.Data?.DateTo)}</Text></Grid.Col>
				{programRegistration.DateSubmitted && <>
				<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Submitted:</Text></Grid.Col>
				<Grid.Col span={9} py={0}><Text>{moment(programRegistration?.DateSubmitted).format("LLL")}</Text></Grid.Col>
				</>}
				{programRegistration?.Data?.Requests !== "" && <>
				<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Special Requests:</Text></Grid.Col>
				<Grid.Col span={9} py={0}><Text>{programRegistration?.Data?.Requests}</Text></Grid.Col>
				</>}
			</Grid>
			}
		</Card>
	</>
}

export default DetailsView