import { Card, Group, Title, Grid, Text, Center, Loader, Button, Paper, Divider } from "@mantine/core"
import { IconAdjustmentsHorizontal, IconPencil } from "@tabler/icons-react"
import { useProgramRegistrationCustomFieldsQuery } from "../../api/programregistration.api";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import { getCustomFieldValue } from "../../functions/programregistration.functions";
import CustomFieldsEditor from "./CustomFieldsEditor";

const CustomFieldsView = () => {
	// const { registrationId } = useParams<ViewRegistationPageParams>();
	// const { data: registration, isLoading: isLoadingRegistration } = useRegistrationByIdQuery(Number(registrationId));
	const { programRegistration, isLoading: isLoadingRegistration, status } = useProgramRegistrationState();
	// const { data: programs, isLoading: isLoadingPrograms } = useProgramsQuery();
	// const { data: programGroups, isLoading: isLoadingProgramGroups } = useProgramGroupsQuery();
	const { data: customFieldGroups, isLoading: isLoadingCustomFieldGroups } = useProgramRegistrationCustomFieldsQuery(programRegistration?.Data?.ProgramId ?? 0);
	
	const getFieldValue = (customFieldId: number) => {
		return { __html: getCustomFieldValue(programRegistration, customFieldId).toString().replaceAll("\n", "<br />") }
	}
	// const program = programs?.find(p => p.Id === (programRegistration?.Data.ProgramId ?? 0));
	// const programGroup = programGroups?.find(p => p.Id === (programRegistration?.Data.ProgramGroupId ?? 0));

	const isSetup = status.isDetailsSetup;// programRegistration != null && programRegistration.ProgramId > 0 && programRegistration.ProgramGroupId > 0;

	if (isLoadingRegistration || isLoadingCustomFieldGroups || customFieldGroups?.length === 0)
		return <></>

	return <>
		<Card shadow="lg" withBorder mb="sm">
			<Group position="apart" noWrap>
				<Group spacing="xs" mb="sm" noWrap>
					<IconAdjustmentsHorizontal />
					<Title order={3} truncate>Custom Information</Title>
				</Group>
				{/* {!isLoadingRegistration &&
				<DetailsEditor add={!isSetup} />
				} */}
				<CustomFieldsEditor />
				{/* <Button variant="light" size="xs" mt={-15} mb="xs" leftIcon={<IconPencil />}> Edit</Button> */}
				{/* <Button variant="light" size="xs" mt={-15} mb="md"><IconPlus /> Add</Button> */}
				
			</Group>
			{(isLoadingRegistration || isLoadingCustomFieldGroups) && isSetup && <Center h={150} mx="auto">
				<Loader />
			</Center>}
			{!isSetup && programRegistration && <>
				{/* <Button variant="light" size="sm" mt={0} sx={{ alignSelf: 'flex-start' }}><IconPlus /> Select Program &amp; Dates</Button> */}
				{/* <DetailsEditor add={true} /> */}
				<Center h={100}>
					<Text>Nothing to see here</Text>
				</Center>
			</>}
			{!isLoadingRegistration && isSetup && <>
				{customFieldGroups?.map((customFieldGroup, index) => {

					return <div key={index}>
						<Divider label={customFieldGroup.Title} labelProps={{ "color": "dimmed" }} labelPosition="center" mb="md" />
						{/* <Text weight="bold" mb="sm" ml="sm">{customFieldGroup.Title}</Text> */}
						{customFieldGroup.CustomFields.map((customField, index2) => {

							return <Grid mb="xs" key={index2}>
								<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Question:</Text></Grid.Col>
								<Grid.Col span={9} py={0}><Text>{customField.Title}</Text></Grid.Col>
								<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Response:</Text></Grid.Col>
								<Grid.Col span={9} py={0}><Text><div dangerouslySetInnerHTML={getFieldValue(customField.Id)}></div></Text></Grid.Col>
							</Grid>
						})}
					</div>
				})}
				
			</>}
		</Card>
	</>
}

export default CustomFieldsView