import { useQuery } from "react-query";
import { useAccess, useAccountId } from "../Auth/auth-functions";
import { api } from "./api";
import { Report, ReportDefinition } from "../Pages/Reports/report.models";

export type ReportParams = { [key: string]: undefined | string | string[] };

export const useReportListQuery = () => { 
	const hasAccess = useAccess("role.user.readonly");

	return useQuery(['report-list'], async () => {
		let res = await api.get<ReportDefinition[]>(`/admin/reports/list`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: hasAccess });
}

export const useReportQuery = (reportKey: string|undefined, reportParams: ReportParams) => { 
	const hasAccess = useAccess("role.user.readonly");

	return useQuery(['report', reportKey, reportParams], async () => {
		let res = await api.get<Report>(`/admin/reports/report/${reportKey}`, { params: reportParams });
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: hasAccess && reportKey !== undefined });
}