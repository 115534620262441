import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MRT_ColumnDef, MRT_PaginationState, MRT_Row, MRT_TableInstance, MRT_Updater, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import moment from 'moment';

import { ChildParticipant, ProgramRegistration } from '../../models/programregistration.models';
import { ProgramSessionEnrollment } from '../../models/programs.models';
import { getAge, getGender, getParticipant, getParticipantName } from '../../functions/programregistration.functions';

interface ProgramSessionEnrollmentRegistration {
	ProgramSessionEnrollment: ProgramSessionEnrollment;
	Registration: ProgramRegistration;
}

interface ProgramSessionEnrollmentsListProps {
	enrollments: ProgramSessionEnrollmentRegistration[],
	total?: number,
	isLoading: boolean,
	showPagination?: boolean
	showFilter?: boolean
}

const ProgramSessionEnrollmentsList = ({ enrollments, total, isLoading, showPagination = true, showFilter = true }: ProgramSessionEnrollmentsListProps) => {
	const history = useHistory();
	const isFirstRender = useRef(true);
	// const [pagination, setPagination] = useLocalStorage({ key: 'session-list-pagination-state', defaultValue: { pageIndex: -1, pageSize: 10 }, getInitialValueInEffect: false });
	const [paginationState, setPaginationState] = useState<MRT_PaginationState>(JSON.parse(localStorage.getItem("session-list-pagination-state") ?? "{ \"pageIndex\": 0, \"pageSize\": 10 }") as MRT_PaginationState);
	// console.log(paginationState)
	// const { data: forms, isLoading: isFormsLoading } = useFormsQuery();
	// const { data: submissions, isLoading } = useSubmissionsQuery(formId);
	const handlePaginationChange = (updater: MRT_Updater<MRT_PaginationState>) => {
		//call the setState as normal, but need to check if using an updater callback with a previous state
		// if (isFirstRender.current) return;
		setPaginationState((prevPagination) => {
				let state = updater instanceof Function ? updater(prevPagination) : updater
				console.log(state);
				localStorage.setItem("session-list-pagination-state", JSON.stringify(state));
				return state;
			}
		);
		//put more code for your side effects here, guaranteed to only run once, even in React Strict Mode
	};

	useEffect(() => {
		// if (paginationState)
			// setPaginationState(JSON.parse(localStorage.getItem("session-list-pagination-state") ?? "{ }") as MRT_PaginationState);
		isFirstRender.current = false;
	}, [])
	
	// const getForm = (formId: number) => forms?.find(f => f.Id === formId);
	const data = useMemo(() => enrollments.map(enrollment => {
		return {
			Registration: enrollment.Registration,
			Participants: getParticipantName(enrollment.Registration, enrollment.ProgramSessionEnrollment.ParticipantId),
			Gender: getGender(getParticipant(enrollment.Registration, enrollment.ProgramSessionEnrollment.ParticipantId)!),
			Age: getAge(getParticipant(enrollment.Registration, enrollment.ProgramSessionEnrollment.ParticipantId)! as ChildParticipant, enrollment.Registration.DateFrom) ?? "Adult",
			EnrollmentDate: moment(enrollment.ProgramSessionEnrollment.EnrollmentDate).toISOString(),//`${moment(enrollment.ProgramSessionEnrollment.EnrollmentDate).format("LLL")}`,
			WaitList: `${enrollment.ProgramSessionEnrollment.WaitList ? "Yes": "No"}`
		}
	}), [enrollments]);

	const columns = useMemo<MRT_ColumnDef[]>(() => [
		// {
		// 	accessorKey: 'Id',
		// 	header: 'ID',
		// 	size: 60,
		// 	filterFn: numberFilterFn,
		// },
		{
			accessorKey: 'Participants',
			header: 'Participant',
			// accessorFn: (row: ProgramSessionEnrollmentRegistration) => getParticipantName(row.Registration, row.ProgramSessionEnrollment.ParticipantId),
			// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
			// filterFn: stringFilterFn,
		},
		{
			accessorKey: 'Gender',
			header: 'Gender',
			// accessorFn: (row: ProgramSessionEnrollmentRegistration) => getGender(getParticipant(row.Registration, row.ProgramSessionEnrollment.ParticipantId)!),
			// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
			// filterFn: stringFilterFn,
		},
		{
			accessorKey: 'Age',
			header: 'Age',
			// accessorFn: (row: ProgramSessionEnrollmentRegistration) => getAge(getParticipant(row.Registration, row.ProgramSessionEnrollment.ParticipantId)! as ChildParticipant),
			// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
			// filterFn: stringFilterFn,
		},
		{
			accessorKey: 'EnrollmentDate',
			header: 'Enrollment Date',
			Cell: ({ cell }) => {
				return moment(cell.getValue() as string).format("LLL")
			}
			// accessorFn: (row: ProgramSessionEnrollmentRegistration) => `${moment(row.ProgramSessionEnrollment.EnrollmentDate).format("LLL")}`,
			// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
			// filterFn: dateFilterFn,
		},
		{
			accessorKey: 'WaitList',
			header: 'Wait List',
			// accessorFn: (row: ProgramSessionEnrollmentRegistration) => `${row.ProgramSessionEnrollment.WaitList ? "Yes": "No"}`,
			// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
			// filterFn: stringFilterFn,
		}
		// {
		// 	accessorKey: 'Registration.DateSubmitted',
		// 	// accessorFn: (row: any) => moment(row.SubmissionDate).toDate(),
		// 	cell: (cell: any) => moment(cell.getValue()).format("LLL"),
		// 	header: 'Submitted On',
		// 	filterFn: dateFilterFn,
		// }
	], []);

	const table = useMantineReactTable({
		columns: columns,
		data: data,
		onPaginationChange: setPaginationState,
		// (state) => {
		// 	setPaginationState(state);
		// 	localStorage.setItem("session-list-pagination-state", JSON.stringify(state))
		// },
		paginationDisplayMode: "pages",
		enableFullScreenToggle: false,
		enableDensityToggle: false,
		enableHiding: false,
		enableTopToolbar: false,
		enableColumnFilters: false,
		enableColumnResizing: true,
		initialState: {
			density: "xs",
			pagination: paginationState,
			showColumnFilters: true
		},
		state: { 
			isLoading: isLoading,
			pagination: paginationState
		},
		mantinePaperProps: {
			withBorder: false,
			shadow: ""
		},
		mantineTableBodyRowProps: ({ row }) => { return {
			onClick: () => handleRowClick(row as any),
			style: { cursor: 'pointer' }
		}},
		mantineTableHeadCellProps: {
			sx: { 
				fontVariantCaps: 'all-small-caps'
			}
		}
	});

	const handleRowClick = (row: MRT_Row<{ Registration: ProgramRegistration }>) => {
		history.push({ pathname: `/registrations/view/${row.original.Registration.Id}`});
	}

	useEffect(() => {
		//do something when the pagination state changes
		localStorage.setItem("session-list-pagination-state", JSON.stringify(paginationState));

	}, [paginationState.pageIndex, paginationState.pageSize]);

	return <>
		<MantineReactTable table={table} />
	</>
}

export default ProgramSessionEnrollmentsList