import { Link, useHistory, useParams } from "react-router-dom"
import { Group, Title, Button, Divider, Text } from "@mantine/core"
import { ProgramRegistrationListByProgramGroup, RecentProgramRegistrationList } from "../../Components/ProgramRegistrations/ProgramRegistrationList"
import { useProgramGroupsQuery } from "../../api/program.api";
import { linkTitle } from "../../shared/helpers";
import { IconPlus } from "@tabler/icons-react";
import { RoleTemplate } from "../../Components/RoleTemplate";
import { useFamilyRegistrationState } from "../../state/familyregistration.state";

export interface RegistationPageParams {
	urlProgramGroup: string;
}

const RegistationsHomePage = () => {
	const history = useHistory();
	const { urlProgramGroup } = useParams<RegistationPageParams>();
	const { data: programGroups } = useProgramGroupsQuery();
	const { reset } = useFamilyRegistrationState();

	const programGroup = programGroups?.find(g => linkTitle(g.Title) === urlProgramGroup);

	const handleNewRegistration = () => {
		// if (programSession === undefined)
		// 	return;

		// let program = programs?.find(p => p.Id === programSession.ProgramId);
		// if (program === undefined)
		// 	return;
			
		// let newRegistration = createEmptyProgramRegistration();
		// newRegistration.ProgramId = program.Id;
		// newRegistration.Data.ProgramId = program.Id;
		// newRegistration.ProgramGroupId = program.ProgramGroupId;
		// newRegistration.Data.ProgramGroupId = program.ProgramGroupId;
		// newRegistration.DateFrom = programSession.StartDate;
		// newRegistration.DateTo = programSession.EndDate;
		// newRegistration.Data.DateFrom = programSession.StartDate;
		// newRegistration.Data.DateTo = programSession.EndDate;

		// setProgramRegistration(newRegistration);
		reset();
		history.push({ pathname: `/registrations/family/new` });
	}

	return <>
		<Group position="apart">
			{programGroup &&
			<Title order={2} mb="sm">Registrations for {programGroup?.Title}</Title>
			}
			{!programGroup &&
			<Title order={2} mb="sm">All Registrations</Title>
			}
			{/* <RoleTemplate role="role.admin">
				<Button component={Link} to="/registrations/new" size="sm" variant="subtle" sx={{ alignSelf: 'flex-start'}} mb="sm" leftIcon={<IconPlus />}>New Registration</Button>
			</RoleTemplate> */}

			<Group spacing="xs" mb="xs" noWrap sx={{ alignSelf: 'flex-start' }}>
				<RoleTemplate role="role.user.readwrite">
					{/* <Divider orientation="vertical" ml="md" mr={1} /> */}
					<Button onClick={handleNewRegistration} variant="subtle" size="sm" leftIcon={<IconPlus />}>New Family Registration</Button>
				</RoleTemplate>
			</Group>
		</Group>
		<Divider />

		{programGroup &&
		<ProgramRegistrationListByProgramGroup programGroupId={programGroup.Id} />
		}
		{!programGroup &&
		<RecentProgramRegistrationList />
		}
	</>
}

export default RegistationsHomePage