import { useQuery } from "react-query";
import { useAccess, useAccountId } from "../Auth/auth-functions";
import { api } from "./api";
import { Program, ProgramActivity, ProgramActivitySession, ProgramGroup, ProgramSession } from "../models/programs.models";


export const useProgramsQuery = () => { 
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['programs', accountId], async () => {
		let res = await api.get<Program[]>('/admin/programs');
		return res.data;
	}, { staleTime: Infinity, cacheTime: 600000, enabled: hasAccess });
}

export const useProgramGroupsQuery = () => { 
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-groups', accountId], async () => {
		let res = await api.get<ProgramGroup[]>('/admin/programs/groups');
		return res.data;
	}, { staleTime: Infinity, cacheTime: 600000, enabled: hasAccess });
}

export const useProgramSessionsByIdQuery = (programId: number) => {
	const accountId = useAccountId();

	return useQuery(['program-sessions-by-program-id', programId, accountId], async () => {
		let res = await api.get<ProgramSession[]>(`/admin/programs/${programId}/sessions`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: programId > 0 });
}

export const useProgramSessionsByProgramIdsQuery = (programIds: number[]) => {
	return useQuery(['program-sessions-by-program-ids', programIds], async () => {
		let res = await api.get<ProgramSession[]>(`/admin/programs/sessions`, { params: { programIds: programIds } });
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: programIds.length > 0 });
}

export const useProgramActivitiesQuery = () => { 
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-activities', accountId], async () => {
		let res = await api.get<ProgramActivity[]>('/admin/programs/activities');
		return res.data;
	}, { staleTime: Infinity, cacheTime: 600000, enabled: hasAccess });
}

export const useProgramActivitySessionsQuery = () => { 
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-activity-sessions', accountId], async () => {
		let res = await api.get<ProgramActivitySession[]>('/admin/programs/activities/sessions');
		return res.data;
	}, { staleTime: Infinity, cacheTime: 600000, enabled: hasAccess });
}


// export const useFormByIdQuery = (formId: number) => { 
// 	const hasAccess = useAccess("role.user.readonly");
// 	const accountId = useAccountId();

// 	return useQuery(['form-by-id', formId, accountId], async () => {
// 		let res = await api.get<Form>(`/admin/form/${formId}`);
// 		return res.data;
// 	}, { staleTime: Infinity, cacheTime: 60000, keepPreviousData: true, enabled: formId > 0 && hasAccess });
// }

// export const useFormByKeyQuery = (formKey: string) => { 
// 	return useQuery(['form-by-key', formKey], async () => {
// 		let res = await api.get<Form>(`/form/${formKey}`);
// 		return res.data;
// 	}, { staleTime: Infinity, cacheTime: 60000 });
// }

// export const useFormMutation = () => {
// 	const queryClient = useQueryClient();

// 	return useMutation((form: Form) => {
// 		return api.post<JSON>(`/admin/forms`, form) 
// 	}, {
// 		onSuccess: (response, form) => {
// 			queryClient.invalidateQueries(['form-by-id', form.Id]);
// 			queryClient.invalidateQueries('forms');
// 		}
// 	})
// }

// export const useFormDefinitionMutation = () => {
// 	const queryClient = useQueryClient();

// 	return useMutation((form: Form) => {
// 		return api.post<JSON>(`/admin/forms/definition`, form) 
// 	}, {
// 		onSuccess: (response, form) => {
// 			queryClient.invalidateQueries(['form-by-id', form.Id]);
// 		}
// 	})
// }