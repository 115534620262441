import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge } from '@mantine/core';
import { MRT_ColumnDef, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import moment from 'moment';

import { useRecentRegistrationQuery, useRegistrationsForProgramGroupQuery } from '../../api/programregistration.api';
import { ProgramRegistration } from '../../models/programregistration.models';
import { formatDateRange } from '../../functions/programregistration.functions';

export const RecentProgramRegistrationList = () => {
	const { data: registrations, isLoading } = useRecentRegistrationQuery();

	return <ProgramRegistrationList registrations={registrations ?? []} isLoading={isLoading} showPagination={false} />
}

interface ProgramRegistrationListByProgramGroupProps {
	programGroupId: number;
}

export const ProgramRegistrationListByProgramGroup = ({ programGroupId }: ProgramRegistrationListByProgramGroupProps) => {
	const { data: registrations, isLoading } = useRegistrationsForProgramGroupQuery(programGroupId);

	return <ProgramRegistrationList registrations={registrations ?? []} isLoading={isLoading} showPagination={false} />
}

interface ProgramRegistrationListProps {
	registrations: ProgramRegistration[],
	total?: number,
	isLoading: boolean,
	showForm?: boolean,
	showPayment?: boolean,
	showPagination?: boolean
	showFilter?: boolean
}

export const ProgramRegistrationList = ({ registrations, total, isLoading, showForm = false, showPayment = false, showPagination = true, showFilter = true }: ProgramRegistrationListProps) => {
	const history = useHistory();

	const data = useMemo(() => registrations, [registrations]);

	const formatParticipants = (participants: string[], limit: number = 0) => {
		if (limit < 1)
			return participants.join(', ');
		if (limit > participants.length)
			limit = participants.length;

		let formatted = participants.slice(0, limit).join(', ');

		if (limit < participants.length) {
			formatted += ` and ${participants.length - limit} more`;
		}
		return formatted;
	}

	const columns = useMemo<MRT_ColumnDef[]>(() => [
		{
			// accessorKey: 'Participants',
			header: 'Participants',
			accessorFn: (row: any) => row.Metadata?.Participants ? formatParticipants(row.Metadata?.Participants, 1) : null,//"(unavailable)",
			// maxSize: 250
			// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
			// filterFn: stringFilterFn,
		},
		{
			header: 'Type',
			accessorFn: (row: any) => row.Metadata?.ProgramGroup,// ?? "(unavailable)",
			// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
			// filterFn: stringFilterFn,
		},
		{
			// accessorKey: 'Email',
			header: 'Program',
			accessorFn: (row: any) => row.Metadata?.Program,// ?? "(unavailable)",
			// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
			// filterFn: stringFilterFn,
		},
		{
			accessorKey: 'DateFrom',
			header: 'Dates',
			// accessorFn: (row: any) => `${moment(row.DateFrom).format("LL")} - ${moment(row.DateTo).format("LL")}`,
			// cell: (cell: any) => cell.getValue() != "" ? cell.getValue() : "(unavailable)",
			Cell: ({ row }) => <>{formatDateRange((row.original as any).DateFrom, (row.original as any).DateTo)}</>
			// filterFn: stringFilterFn,
		},
		{
			header: 'Submitted On',
			accessorKey: 'DateSubmitted',
			// accessorFn: (row: any) => moment(row.SubmissionDate).toDate(),
			Cell: ({ cell }) => moment(cell.getValue() as string).format("LLL"),
			// filterFn: dateFilterFn,
		},
		{
			accessorKey: 'Deleted',
			header: 'Status',
			Cell: ({ cell }) => {
				return cell.getValue() as boolean ? <Badge color="red" variant="filled">Deleted</Badge> : <Badge color="green" variant="light">Registered</Badge>
			}
		}
	], []);

	const handleRowClick = (row: any) => {
		history.push({ pathname: `/registrations/view/${row.original.Id}`});
	}

	const table = useMantineReactTable({
		columns: columns,
		data: data,
		// onPaginationChange: setPaginationState,
		// (state) => {
		// 	setPaginationState(state);
		// 	localStorage.setItem("session-list-pagination-state", JSON.stringify(state))
		// },
		paginationDisplayMode: "pages",
		enableFullScreenToggle: false,
		enableDensityToggle: false,
		enableHiding: false,
		enableTopToolbar: false,
		enableColumnFilters: true,
		enableColumnActions: false,
		enableColumnResizing: true,
		initialState: {
			density: "xs",
			// pagination: paginationState,
			showColumnFilters: showFilter
		},
		state: { 
			isLoading: isLoading,
			// pagination: paginationState
		},
		mantinePaperProps: {
			withBorder: false,
			shadow: ""
		},
		mantineTableBodyRowProps: ({ row }) => { return {
			onClick: () => handleRowClick(row as any),
			style: { cursor: 'pointer' }
		}},
		mantineTableHeadCellProps: {
			sx: { 
				fontVariantCaps: 'all-small-caps'
			}
		}
	});

	return <>
		<MantineReactTable table={table} />
	</>
}