import { Paper, Title, Alert, Accordion, Badge, Text, createStyles, Group, Stack, ActionIcon, Grid, Tooltip } from "@mantine/core"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { IconExclamationCircle, IconCircleArrowRightFilled, IconCircle, IconCircleX } from "@tabler/icons-react"
import { TransactionPaymentMethod } from "../../models/transaction.models"
import { useState } from "react";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";

const useStyles = createStyles((theme) => ({
	stripeElement: {
		margin: '1rem 0 1rem',
		backgroundColor: 'white',
		padding: '8px 12px',
		borderRadius: '4px',
		border: '1px solid silver',
		boxShadow: '0 1px 3px 0 #e6ebf1',
		WebkitTransition: 'box-shadow 150ms ease',
		transition: 'box-shadow 150ms ease'
	}
}));

interface PaymentMethodEditorProps {
	disabled: boolean;
	// paymentMethod: TransactionPaymentMethod|null;
	onChanged: (value: TransactionPaymentMethod) => void;
	onStripeCardCompleted: (value: boolean) => void;
	onStripeCardCleared: () => void;
}

const PaymentMethodEditor = ({ onChanged, onStripeCardCompleted, onStripeCardCleared, disabled }: PaymentMethodEditorProps) => {
	const { classes } = useStyles();
	const stripe = useStripe();
	const elements = useElements();
	// const [selectedPaymentType, setSelectedPaymentType] = useState<TransactionPaymentMethod | null>(paymentMethod);
	const [stripeCardComplete, setStripeCardComplete] = useState(false);
	const [stripeCardError, setStripeCardError] = useState("");

	const { programRegistration, updateProgramRegistration, isLoading: isLoadingRegistration, isDeleted, status } = useProgramRegistrationState();
	const [stripePaymentMethod, setStripePaymentMethod] = useState(programRegistration.Data.Metadata.transaction?.stripePaymentMethod);
	
	const handlePaymentTypeChanged = (value: string|null) => {
		if (value === null)
			return;
		
		let paymentMethod = Number(value);
		// setSelectedPaymentType(paymentMethod);
		if (onChanged)
			onChanged(paymentMethod);
	}

	const handleStripeCardChange = (event: StripeCardElementChangeEvent) => {
		setStripeCardError(event.error?.message ?? "");
		setStripeCardComplete(event.complete);
		if (onStripeCardCompleted)
			onStripeCardCompleted(event.complete);
	}

	const resetStripePayment = () => {
		setStripePaymentMethod(undefined);
		if (onStripeCardCleared)
			onStripeCardCleared();
	}

	return <>
		<Paper p="sm" mb="md" withBorder>
			<Title order={4} mb="sm">Stripe Payment</Title>
			{/* <Text mb="md">Select how you want to process this payment:</Text> */}
			{disabled &&
			<Alert variant="light" color="indigo" mb="md" icon={<IconExclamationCircle />}>
				You must select a billing contact before you can select the payment method.
			</Alert>
			}
			{/* <Accordion value={selectedPaymentType?.toString()} onChange={handlePaymentTypeChanged} variant="filled" chevron={false} radius="md"> */}
				{/* <Accordion.Item value={TransactionPaymentMethod.ManualStripe.toString()}> */}
					{/* <Accordion.Control disabled={disabled} icon={selectedPaymentType === TransactionPaymentMethod.ManualStripe ? <IconCircleArrowRightFilled /> : <IconCircle />}><Text weight={selectedPaymentType === TransactionPaymentMethod.ManualStripe ? "bold" : "normal"}>Stripe Payment</Text></Accordion.Control> */}
					{/* <Accordion.Panel> */}
						{stripePaymentMethod && <>
							<Grid>
								<Grid.Col sm={10}>
									<Text size="sm">Card: {stripePaymentMethod.card?.brand.toUpperCase()} **** **** **** {stripePaymentMethod.card?.last4}</Text>
									<Text size="sm">Expiry: {stripePaymentMethod.card?.exp_month} / {stripePaymentMethod.card?.exp_year}</Text>
									<Badge color="orange" mt={5} sx={{ display: 'inline-block' }}>Not charged yet</Badge>
								</Grid.Col>
								<Grid.Col sm={2}>
									<Tooltip label="Reset Card">
										<ActionIcon onClick={resetStripePayment}>
											<IconCircleX />
										</ActionIcon>
									</Tooltip>
								</Grid.Col>
							</Grid>
							
						</>}
						{!stripePaymentMethod && <>
							<Text>Enter the credit card details to be processed online through Stripe.</Text>
							{/* <Button variant="light" size="sm" mt="md" leftIcon={<IconPlus />} disabled>
								Add Stripe Payment
							</Button>
								*/}

							<CardElement className={classes.stripeElement} onChange={handleStripeCardChange} options={{ disableLink: true }} />

							{stripeCardError && <>
								<Alert variant="light" color="red" icon={<IconExclamationCircle />}>
									{stripeCardError}
								</Alert>
							</>}
							{!stripeCardError && <>
							<Alert variant="light" color="indigo" icon={<IconExclamationCircle />}>
								This card will only be charged once the registration is saved.
							</Alert>
							</>}
						</>}
					{/* </Accordion.Panel> */}
				{/* </Accordion.Item> */}

			{/* </Accordion> */}

			{/* <Title order={4}>Stripe Payment</Title> */}
			
			
			{/* <Divider label="Or" labelPosition="center" my="lg" />
			<Title order={4}>Offline Payment</Title>
			<Text>Choose this option to enter the details for a payment that was processed offline (i.e. cash, debit or credit through a terminal).</Text>
			<Button variant="light" size="sm" mt="md" leftIcon={<IconPlus />} disabled> Add Offline Payment
			</Button> */}
		</Paper>
	</>
}

export default PaymentMethodEditor