import { Card, Group, Title, Grid, Text, Divider, Center, Loader, ScrollArea } from "@mantine/core"
import { IconAdjustmentsHorizontal } from "@tabler/icons-react"
import { formatAddress } from "../../functions/programregistration.functions";
import { AdultParticipant, ChildParticipant } from "../../models/programregistration.models";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import ParentGuardianListEditor from "./ParentGuardianListEditor";
import { useProgramGroupsQuery } from "../../api/program.api";
import { SessionSelectionType } from "../../models/programs.models";
import { useFamilyRegistrationState } from "../../state/familyregistration.state";

const ParentGuardiansView = () => {
	const { familyRegistration, isLoading } = useFamilyRegistrationState();
	const { data: programGroups } = useProgramGroupsQuery();

	// const programGroup = programGroups?.find(g => g.Id === (programRegistration?.Data.ProgramGroupId ?? 0));

	// if (!isLoading && (programRegistration?.Data?.ParentGuardians.length ?? 0) === 0)
	// {
	// 	if (programGroup === undefined || programGroup.ParticipantType === ProgramParticipantType.Adults || 
	// 		(programGroup.ParticipantType === ProgramParticipantType.AdultsAndChildren))
	// 	return <></>
	// }

	const isSetup = familyRegistration != null && familyRegistration.ParentGuardians.length > 0;

	// if (!isSetup && programGroup?.SessionSelectionType == SessionSelectionType.Fixed)
	// 	return <></>;

	return <>
		<Card shadow="lg" withBorder mb="sm">
			<Group position="apart" noWrap>
				<Group spacing="xs" mb="lg">
					<IconAdjustmentsHorizontal />
					<Title order={3}>Parents / Guardians</Title>
				</Group>
				{/* {!isLoading && */}
				{/* <Button variant="light" size="xs" sx={{ alignSelf: 'flex-start' }} ><IconPencil /> Edit</Button> */}
				{/* } */}
				{familyRegistration &&	<ParentGuardianListEditor />}
				
			</Group>
			{(isLoading) && <Center h={150} mx="auto">
				<Loader />
			</Center>}
			{!isSetup && familyRegistration && <>
				<Center h={100}>
					<Text color="dimmed">Add one or more parents/guardians</Text>
				</Center>
			</>}

			{/* <ScrollArea.Autosize type="auto" mah={350}> */}
				
			{familyRegistration?.ParentGuardians.map((parentGuardian, index) => {
				let adultParticipant = parentGuardian.Type === "adult" ? parentGuardian as AdultParticipant : null;
				let childParticipant = parentGuardian.Type === "child" ? parentGuardian as ChildParticipant : null;
				return <div key={index} style={{ margin: "calc(1rem / 2)" }}>
					<Grid mb="xs">
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Name:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text weight="bold">{parentGuardian.FirstName} {parentGuardian.LastName}</Text></Grid.Col>
						{/* <Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Gender:</Text></Grid.Col>
						<Grid.Col span={9} sm={3} py={0}><Text>{getGender(parentGuardian)}</Text></Grid.Col> */}
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Email:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text>{parentGuardian.Email}</Text></Grid.Col>

						
					</Grid>
					<Divider mb="md" label="Emergency Contact" labelPosition="center" labelProps={{ color: "dimmed" }} />
					<Grid mb="xs">
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Location:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text>{parentGuardian.Location}</Text></Grid.Col>
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Emergency Contact:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text>{parentGuardian.EmergencyContact}</Text></Grid.Col>
					</Grid>
					<Divider mb="md" label="Contact" labelPosition="center" labelProps={{ color: "dimmed" }} />
					<Grid mb="xs">
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Home Phone:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text>{parentGuardian.HomePhone}</Text></Grid.Col>

						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Cell Phone:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text>{parentGuardian.MobilePhone}</Text></Grid.Col>

						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Address:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text>{formatAddress(parentGuardian.Address)}</Text></Grid.Col>
					</Grid>
					
					{index < familyRegistration.ParentGuardians.length - 1 && <Divider mb="md" size={3} />}
				</div>
			})}
			
			{/* </ScrollArea.Autosize> */}
		</Card>
	</>
}

export default ParentGuardiansView