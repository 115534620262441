import { useEffect, useState } from "react";
import { ActionIcon, Button, Checkbox, Divider, Drawer, Grid, Group, Menu, Select, TextInput, Textarea, Title, Tooltip } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconCheck, IconChevronDown, IconPencil, IconPlus, IconX } from "@tabler/icons-react";
import moment from "moment";
import { createEmptyAdult, createEmptyChild, formatAddress, getGenderDescription } from "../../functions/programregistration.functions";
import { Address, AdultParticipant, ChildParticipant, Genders, Salutations } from "../../models/programregistration.models";
import _ from "lodash";
import * as Yup from "yup";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import { DatePickerInput } from "@mantine/dates";
import { AddressSchema } from "./AddressEditor";
import { IconDotsCircleHorizontal } from "@tabler/icons-react";
import PersonSearchModal from "./PersonSearchModal";
import { ProgramParticipantDocument } from "../../api/search.api";
import { getCountries, getPostalCodeLabel, getProvinceLabel, getProvincesStates } from "../../shared/address";

interface FormFields {
	FirstName: string;
	LastName: string,
	// MiddleName: string,
	Salutation: string|null,
	Gender: string,
	Birthdate: Date|null,
	HealthConcerns?: string;
	Allergies?: string;
	SpecialNeeds?: string;
	AgeOverrideReason?: string;
	ChangeDiaper?: boolean;
	Email?: string;
	HomePhone?: string;
	MobilePhone?: string;
	// Deceased: Date|null
	Address: {
		Street1: string;
		Street2: string;
		// Street3: string;
		City: string;
		// Region: string;
		Province: string;
		PostalCode: string;
		Country: string;
	}
}

export const AdultParticipantSchemaOld = Yup.object().shape({
	FirstName: Yup.string()
		.max(100, 'Maximum length is 100 characters')
		.required('Required'),
	// MiddleName: Yup.string()
	// 	.max(100, 'Maximum length is 100 characters'),
	LastName: Yup.string()
		.max(100, 'Maximum length is 100 characters')
		.required('Required'),
	Salutation: Yup.string()
		.max(30, 'Maximum length is 30 characters'),
	Gender: Yup.number()
		.oneOf([1,2,10], 'Required')
		.required('Required'),
	Birthdate: Yup.string().nullable(),
	// Deceased: Yup.string().nullable()
	Address: AddressSchema
});

export const AdultParticipantSchema = Yup.object().shape({
	FirstName: Yup.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required'),
	LastName: Yup.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required'),
	Salutation: Yup.string()
		.max(30, 'Maximum length is 30 characters'),
	Gender: Yup.number()
		.oneOf([0,1,2], 'Required')
		.required('Required'),
	Email: Yup.string().email('Invalid email'),//.required('Required'),
	HomePhone: Yup.string(),//.when('MobilePhone', { is: "", then: (schema) => schema.min(10, 'Not a valid phone number').required('Required') }),
	MobilePhone: Yup.string(),//.when('HomePhone', { is: "", then: (schema) => schema.min(10, 'Not a valid phone number').required('Required') }),
	// SameAddress: Yup.boolean(),
	Address: AddressSchema
	// Location: Yup.string().required('Required'),
	// EmergencyContact: Yup.string().required('Required')
}, [['HomePhone', 'MobilePhone']]);

export const ChildParticipantSchema = Yup.object().shape({
	FirstName: Yup.string()
		.max(100, 'Maximum length is 100 characters')
		.required('Required'),
	LastName: Yup.string()
		.max(100, 'Maximum length is 100 characters')
		.required('Required'),
	Gender: Yup.number()
		.oneOf([1,2,10], 'Required')
		.required('Required'),
	Birthdate: Yup.string()
		.required('Required'),
	Address: AddressSchema
})

interface Props {
	registrationId: number,
	participantId: string,
	participantType: "adult"|"child",
	add?: boolean
}

const ParticipantEditor = ({ registrationId, participantId, participantType, add = false }: Props) => {
	const [opened, { open, close }] = useDisclosure(false);
	const [showBirthdate, setShowBirthdate] = useState(false);
	// const { data: registration, isLoading: isLoadingRegistration } = useRegistrationByIdQuery(registrationId);
	const { programRegistration, updateProgramRegistration, getAddresses } = useProgramRegistrationState();

	const genders = Genders.map((g) => { return { value: g.toString(), label: getGenderDescription(g) } });
	const salutations = Salutations.map((s) => { return { value: s, label: s} });

	let participant = programRegistration?.Data.Participants.find(p => p.Id === participantId);

	if (add === true) {
		participant = participantType === "adult" ? createEmptyAdult() : createEmptyChild();
	}
	const form = useForm<FormFields>({
		validate: participantType === "adult" ? yupResolver(AdultParticipantSchema) : yupResolver(ChildParticipantSchema)
		// validate: {
		// 	FirstName: (value) => (value.length === 0 ? 'Required' : null)
		// }
	});

	const showDiaperChange = participant && participantType === "child" && moment.duration(moment().diff(moment(form.values?.Birthdate))).asMonths() < 36;

	useEffect(() => {
		form.setValues({
			FirstName: participant?.FirstName,
			LastName: participant?.LastName,
			// MiddleName: person?.MiddleName,
			Salutation: (participant as AdultParticipant)?.Salutation,
			Gender: participant?.Gender?.toString(),
			
			Email: (participant as AdultParticipant)?.Email,
			HomePhone: (participant as AdultParticipant)?.HomePhone,
			MobilePhone: (participant as AdultParticipant)?.MobilePhone,

			Birthdate: (participant as ChildParticipant)?.Birthdate ? moment((participant as ChildParticipant).Birthdate).toDate() : null,
			HealthConcerns: (participant as ChildParticipant)?.HealthConcerns,
			Allergies: (participant as ChildParticipant)?.Allergies,
			SpecialNeeds: (participant as ChildParticipant)?.SpecialNeeds,
			AgeOverrideReason: (participant as ChildParticipant).AgeOverrideReason,
			ChangeDiaper: (participant as ChildParticipant).ChangeDiaper,

			Address: {
				Street1: participant?.Address?.Street1 ?? "",
				Street2: participant?.Address?.Street2 ?? "",
				// Street3: address?.Street3,
				City: participant?.Address?.City ?? "",
				Province: participant?.Address?.Province ?? "",
				PostalCode: participant?.Address?.PostalCode ?? "",
				// Region: address?.Region,
				Country: participant?.Address?.Country ?? ""
			}
			// Deceased: person?.Deceased ? moment(person.Deceased).toDate() : null
		});
		form.resetDirty();
		form.resetTouched();
		setShowBirthdate(participant?.Type === "child");
		// setShowDeceased(person?.Deceased !== null);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [programRegistration, opened]);

	const handleSubmit = (values: FormFields) => {
		if (programRegistration !== undefined && participant !== undefined) {
			_.assign(participant, values);
			participant.Gender = parseInt(values.Gender);
			if (participantType === "child")
				(participant as ChildParticipant).Birthdate = showBirthdate ? (values.Birthdate?.toISOString() ?? null) : null;
			if (add) {
				programRegistration.Data.Participants.push(participant)
			}
			updateProgramRegistration(programRegistration);
			close();
		}
	}

	const handleAddParticipant = () => {
		open();
	}

	const handleSearchResult = (person: ProgramParticipantDocument) => {
		form.setValues({ 
			FirstName: person.FirstName,
			LastName: person.LastName,
			Gender: person.Gender?.toString() ?? "0",
			
			Address: {
				Street1: person.Address?.Street1 ?? "",
				Street2: person.Address?.Street2 ?? "",
				City: person.Address?.City ?? "",
				Province: person.Address?.Province ?? "",
				PostalCode: person.Address?.PostalCode ?? "",
				Country: person.Address?.Country ?? ""
			}
		})

		if (person.Type == "adult") {
			form.setValues({ 
				Salutation: person.Salutation,
				Email: person.Email,
				HomePhone: person.HomePhone,
				MobilePhone: person.MobilePhone
			});
		}

		if (person.Type == "child") {
			form.setValues({ 
				Birthdate: moment(person.Birthdate).toDate(),
				HealthConcerns: person.HealthConcerns,
				Allergies: person.Allergies,
				SpecialNeeds: person.SpecialNeeds
			})
		}
	}

	const autofillAddress = (address: Address) => {
		let values = form.values;
		values.Address = {
			Street1: address?.Street1 ?? "",
			Street2: address?.Street2 ?? "",
			City: address?.City ?? "",
			Province: address?.Province ?? "",
			PostalCode: address?.PostalCode ?? "",
			Country: address?.Country ?? ""
		};
		form.setValues(values);
	}

	return <>
		{!add &&
		<Button onClick={open} variant="light" size="xs" fullWidth leftIcon={<IconPencil />} sx={{ alignSelf: "flex-start" }}>Edit</Button>
		}
		{add && participantType === "adult" &&
		<Button onClick={handleAddParticipant} variant="subtle" size="sm" mt="md" fullWidth leftIcon={<IconPlus />}>Add Adult Participant</Button>
		}
		{add && participantType === "child" &&
		<Button onClick={handleAddParticipant} variant="subtle" size="sm" mt="md" fullWidth leftIcon={<IconPlus />}>Add Child Participant</Button>
		}
		
		<Drawer opened={opened} onClose={close} title={<div><Title order={3}>{add ? "Add" : "Edit"} {participantType === "adult" ? "Adult" : "Child"} Participant</Title></div>} position="right" withOverlay={true} size="md" withCloseButton overlayProps={{ opacity: 0.1, blur: 0 }}>

			{/* {isLoadingRegistration && <Loader />} */}
			{/* <Button variant="subtle" fullWidth leftIcon={<IconSearch />} mt={-10} mb="xs">Search for a person...</Button> */}
			<PersonSearchModal type={participantType} onSelected={handleSearchResult} />
						
			<form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
			<Grid>
				<Grid.Col sm={6}>
					<TextInput label="First Name" withAsterisk {...form.getInputProps('FirstName')} />
				</Grid.Col>
				<Grid.Col sm={6}>
					<TextInput label="Last Name" withAsterisk {...form.getInputProps('LastName')} />
				</Grid.Col>
				
				{/* <Grid.Col sm={6}>
					<TextInput placeholder="Optional" label="Middle Name" {...form.getInputProps('MiddleName')} />
				</Grid.Col> */}
				<Grid.Col sm={6}>
					<Select label="Gender" data={genders} withAsterisk {...form.getInputProps('Gender')} />
				</Grid.Col>
				{participantType === "adult" && <>
				<Grid.Col sm={6}>
					<Select label="Salutation" data={salutations} {...form.getInputProps('Salutation')} />
				</Grid.Col>
				</>}
				<Grid.Col sm={6}>
					{/* <Stack spacing={0}> */}
						{/* <Checkbox label="This is a child" mt="md" checked={showBirthdate} onChange={(e) => setShowBirthdate(e.target.checked) } /> */}
						{showBirthdate &&
						<DatePickerInput label="Date of Birth" withAsterisk {...form.getInputProps('Birthdate')} />
						}
					{/* </Stack> */}
				</Grid.Col>
				{/* <Grid.Col sm={3}>
					<Select label="Salutation" data={salutations} {...form.getInputProps('Salutation')} />
				</Grid.Col> */}
			</Grid>
			<Divider mt="lg" mb="xs" />

			{participantType === "adult" && <>
				<Title order={4}>Contact</Title>
				<Grid>
					<Grid.Col sm={12}>
						<TextInput label="Email" {...form.getInputProps('Email')} />
					</Grid.Col>
					<Grid.Col sm={12} md={6}>
						<TextInput label="Home Phone" {...form.getInputProps('HomePhone')} />
					</Grid.Col>
					<Grid.Col sm={12} md={6}>
						<TextInput label="Cell Phone" {...form.getInputProps('MobilePhone')} />
					</Grid.Col>
					
				</Grid>
				<Divider mt="lg" mb="xs" />
			</>}

			{participantType === "child" && <>
				<Grid>
					<Grid.Col sm={12} md={6}>
						<Textarea label="Health Concerns" autosize minRows={2} maxRows={4} {...form.getInputProps('HealthConcerns')} />
					</Grid.Col>
					<Grid.Col sm={12} md={6}>
						<Textarea label="Allergies" autosize minRows={2} maxRows={4} {...form.getInputProps('Allergies')} />
					</Grid.Col>
					<Grid.Col sm={12} md={6}>
						<Textarea label="Special Needs" autosize minRows={2} maxRows={4} {...form.getInputProps('SpecialNeeds')} />
					</Grid.Col>
					<Grid.Col sm={12} md={6}>
						<Textarea label="Age Override Reason" autosize minRows={2} maxRows={4} {...form.getInputProps('AgeOverrideReason')} />
					</Grid.Col>
					{showDiaperChange &&
					<Grid.Col sm={12} md={12}>
						<Checkbox label="Permission given to change diaper" checked={form.values.ChangeDiaper} {...form.getInputProps('ChangeDiaper')} />
					</Grid.Col>
					}
				</Grid>
				<Divider mt="lg" mb="xs" />
			</>}

			{/* <AddressEdit participant={participant} /> */}
			<Group position="apart" mb="xs">
				<Title order={4}>Address</Title>
				<Menu shadow="md">
					<Menu.Target>
						{/* <Tooltip label="Autofill Address" position="top"> */}
							<Button size="xs" variant="light" compact rightIcon={<IconChevronDown />}>Autofill</Button>
							{/* <ActionIcon radius="xl">
								<IconDotsCircleHorizontal />
							</ActionIcon> */}
						{/* </Tooltip> */}
					</Menu.Target>
					<Menu.Dropdown>
						{getAddresses().map((address, index) => {
							return <Menu.Item key={index} onClick={() =>autofillAddress(address)}>{formatAddress(address, true)}</Menu.Item>
						})}
					</Menu.Dropdown>
				</Menu>
			</Group>
			<Grid>
				{/* <Grid.Col sm={12}>
					<TextInput label="Description" {...form.getInputProps('Description')} withAsterisk />
				</Grid.Col> */}
				<Grid.Col sm={6}>
					<TextInput label="Street Line 1" {...form.getInputProps('Address.Street1')} withAsterisk />
				</Grid.Col>
				<Grid.Col sm={6}>
					<TextInput label="Street Line 2" {...form.getInputProps('Address.Street2')} />
				</Grid.Col>
				{/* <Grid.Col sm={6}>
					<TextInput label="Street Line 3" {...form.getInputProps('Street3')} />
				</Grid.Col>	 */}
			</Grid>
			<Grid>
				<Grid.Col sm={6}>
					<TextInput label="City" {...form.getInputProps('Address.City')} withAsterisk />
				</Grid.Col>
				<Grid.Col sm={6}>
					{(form.values?.Address?.Country === "Canada" || form.values?.Address?.Country === "United States") &&
					<Select label={getProvinceLabel(form.values?.Address?.Country)} data={getProvincesStates(form.values?.Address?.Country).map(p => { return { value: p, label: p } })} {...form.getInputProps('Address.Province')} />
					}
					{(form.values?.Address?.Country !== "Canada" && form.values?.Address?.Country !== "United States") &&
					<TextInput label="Region" {...form.getInputProps('Address.Region')} />
					}
				</Grid.Col>
				<Grid.Col sm={6}>
					<TextInput label={getPostalCodeLabel(form.values?.Address?.Country)} {...form.getInputProps('Address.PostalCode')} withAsterisk />
				</Grid.Col>
				<Grid.Col sm={6}>
					<Select label="Country" data={getCountries().map(c => { return { value: c, label: c } })} {...form.getInputProps('Address.Country')} />
				</Grid.Col>
			</Grid>

			<Divider mt="lg" mb="xs" />

			<Group mt="md">
				<Button type="submit" leftIcon={<IconCheck />} hidden={!form.isDirty() && !form.isTouched()}>
					Save Changes
				</Button>
				<Button type="button" variant="subtle" onClick={() => close()} leftIcon={<IconX />}>
					{form.isDirty() || form.isTouched() ? "Discard Changes" : "Close"}
				</Button>
				{/* <Button type="submit" variant="light" hidden={!form.isDirty() && !form.isTouched()}>
					Save Changes &amp; Add Contact Details
				</Button> */}
			</Group>

			</form>
			{/* <Button variant="subtle" disabled={isLoadingRegistration} size="sm" mt="md" mb="md" leftIcon={<IconPlus />}>Add Participant</Button> */}

		</Drawer>
	</>
}

export default ParticipantEditor