import { Card, Group, Title, Grid, Text, Center, Loader, Divider, Button, ScrollArea } from "@mantine/core"
import { useParams } from "react-router-dom";
import { IconAdjustmentsHorizontal, IconPencil } from "@tabler/icons-react"
import moment from "moment"
import { useRegistrationByIdQuery } from "../../api/programregistration.api";
import { ViewRegistationPageParams } from "../../Pages/Registrations/View";
import { getGender, getAge, formatAddress } from "../../functions/programregistration.functions";
import { AdultParticipant, ChildParticipant } from "../../models/programregistration.models";
import ParticipantsListEditor from "./ParticipantListEditor";
import { useProgramRegistrationState } from "../../state/programregistration.state";

const ParticipantsView = () => {
	// const { registrationId } = useParams<ViewRegistationPageParams>();
	// const { data: registration, isLoading: isLoadingRegistration } = useRegistrationByIdQuery(Number(registrationId));
	const { programRegistration, isLoading } = useProgramRegistrationState();

	const isSetup = programRegistration != null && programRegistration.Data.Participants.length > 0;

	return <>
		<Card shadow="lg" withBorder mb="sm">
			<Group position="apart">
				<Group spacing="xs" mb="lg">
					<IconAdjustmentsHorizontal />
					<Title order={3}>Participants</Title>
				</Group>
				{/* <Button variant="light" size="xs" mt={-15} mb="md"><IconPencil /> Edit</Button> */}
				{programRegistration &&	<ParticipantsListEditor />}
				
			</Group>
			{(isLoading) && <Center h={150} mx="auto">
				<Loader />
			</Center>}
			{!isSetup && programRegistration && <>
				<Center h={100}>
					<Text color="dimmed">Add one or more participants</Text>
				</Center>
			</>}

			<ScrollArea.Autosize type="auto" mah={350}>
			
			{!isLoading && programRegistration?.Data?.Participants.map((participant, index) => {
				let adultParticipant = participant.Type === "adult" ? participant as AdultParticipant : null;
				let childParticipant = participant.Type === "child" ? participant as ChildParticipant : null;
				return <div key={index} style={{ margin: "calc(1rem / 2)" }}>
					<Grid>
						<Grid.Col span={3} sm={3} lg={3} py={0}><Text align="right" color="dimmed" size="sm">Name:</Text></Grid.Col>
						<Grid.Col span={9} sm={4} lg={9} py={0}><Text weight="bold">{adultParticipant && adultParticipant.Salutation} {participant.FirstName} {participant.LastName}</Text></Grid.Col>
						<Grid.Col span={3} sm={2} lg={3} py={0}><Text align="right" color="dimmed" size="sm">Gender:</Text></Grid.Col>
						<Grid.Col span={9} sm={3} lg={9} py={0}><Text>{getGender(participant)}</Text></Grid.Col>
					</Grid>
					{participant.Type == "child" && 
					<Grid mb="xs" mt="xs">
						{childParticipant?.Birthdate !== null && <>
						<Grid.Col span={3} sm={3} lg={3} py={0}><Text align="right" color="dimmed" size="sm">DoB:</Text></Grid.Col>
						<Grid.Col span={9} sm={4} lg={9} py={0}><Text>{moment(childParticipant?.Birthdate).format("LL")}</Text></Grid.Col>
						<Grid.Col span={3} sm={2} lg={3} py={0}><Text align="right" color="dimmed" size="sm">Age:</Text></Grid.Col>
						<Grid.Col span={9} sm={3} lg={9} py={0}><Text>{getAge(childParticipant!, programRegistration.DateFrom)}</Text></Grid.Col>
						</>}
						{childParticipant?.AgeOverrideReason !== undefined && childParticipant.AgeOverrideReason !== "" && <>
						<Grid.Col span={3} sm={3} lg={3} py={0}><Text align="right" color="dimmed" size="sm">Age Override Reason:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} lg={9} py={0} sx={{ alignSelf: 'flex-end' }}><Text>{childParticipant.AgeOverrideReason}</Text></Grid.Col>
						</>}
						{childParticipant?.HealthConcerns !== undefined && childParticipant.HealthConcerns !== "" && <>
						<Grid.Col span={3} sm={3} lg={3} py={0}><Text align="right" color="dimmed" size="sm">Health Concerns:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} lg={9} py={0}><Text>{childParticipant.HealthConcerns}</Text></Grid.Col>
						</>}
						{childParticipant?.Allergies !== undefined && childParticipant.Allergies !== "" && <>
						<Grid.Col span={3} sm={3} lg={3} py={0}><Text align="right" color="dimmed" size="sm">Allergies:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} lg={9} py={0}><Text>{childParticipant.Allergies}</Text></Grid.Col>
						</>}
						{childParticipant?.SpecialNeeds !== undefined && childParticipant.SpecialNeeds !== "" && <>
						<Grid.Col span={3} sm={3} lg={3} py={0}><Text align="right" color="dimmed" size="sm">Special Needs:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} lg={9} py={0}><Text>{childParticipant.SpecialNeeds}</Text></Grid.Col>
						</>}
						{childParticipant?.ChangeDiaper !== undefined && <>
						<Grid.Col span={3} sm={3} lg={3} py={0}><Text align="right" color="dimmed" size="sm">Can change diaper:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} lg={9} py={0}><Text>{childParticipant.ChangeDiaper === true ? "Yes": "No"}</Text></Grid.Col>
						</>}
					</Grid>
					}
					
					<Divider mb="sm" label="Contact" labelPosition="center" labelProps={{ color: 'dimmed' }} />
					{participant.Type == "adult" &&
					<Grid mb="xs">
						{adultParticipant?.Email && <>
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Email:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text>{adultParticipant?.Email}</Text></Grid.Col>
						</>}
						{adultParticipant?.HomePhone && <>
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Home Phone:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text>{adultParticipant?.HomePhone}</Text></Grid.Col>
						</>}
						{adultParticipant?.MobilePhone && <>
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Cell Phone:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text>{adultParticipant?.MobilePhone}</Text></Grid.Col>
						</>}
					</Grid>}
					<Grid>
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Address:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text>{formatAddress(participant.Address)}</Text></Grid.Col>
					</Grid>
					{index < programRegistration.Data.Participants.length - 1 && <Divider mt="md" mb="xl" size={3} />}
				</div>
			})}

			</ScrollArea.Autosize>
		</Card>
	</>
}

export default ParticipantsView