import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
// import './index.css'
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter as Router } from 'react-router-dom';

import { msalInstance } from './auth';


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
	 <MsalProvider instance={msalInstance}>
		<Router>
			<App />
		</Router>
	 </MsalProvider>
  </React.StrictMode>,
)
