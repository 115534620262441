import { ReactNode } from "react";
import { useMsal } from "@azure/msal-react";
import { hasRole } from "../Auth/auth-functions";

interface RoleTemplateProps {
	role: string,
	// not?: boolean,
	// empty?: boolean,
	children: ReactNode,
	noAccess?: JSX.Element
}
export const RoleTemplate = ({ role, children, noAccess }: RoleTemplateProps) => {
	const { instance } = useMsal();
	const activeAccount = instance.getActiveAccount();

	const roles = activeAccount?.idTokenClaims!['roles'] ?? [];

	if (roles.length === 0)
		return <>{noAccess}</>

	return hasRole(roles, role) ? <>
		{children}
	</> : <>
		{noAccess}
	</>
}
