import { useHistory } from "react-router-dom"
import { Group, Title, Grid, Divider, Button, Text, Alert } from "@mantine/core"
import { modals } from '@mantine/modals';
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCheck, IconInfoCircleFilled, IconPlus, IconX } from "@tabler/icons-react";
import _ from "lodash";
import { useProgramRegistrationSaveMutation } from "../../../api/programregistration.api";
import { useFamilyRegistrationState } from "../../../state/familyregistration.state";
import ParentGuardiansView from "../../../Components/FamilyRegistration/ParentGuardiansView";
import ErrorBoundaryFallback from "../../../Components/Shared/ErrorBoundaryFallback";
import RegistrationsView from "../../../Components/FamilyRegistration/RegistrationsView";
import { RoleTemplate } from "../../../Components/RoleTemplate"
import { ErrorBoundary } from "../../../Components/Shared/ErrorBoundary";

const NewFamilyRegistationPage = () => {
	const history = useHistory();
	const { familyRegistration, isChanged, isValid, isSaving, setIsSaving, isCompleted, successfulSaves, failedSaves, addSuccessfulSave, addFailedSave, reset } = useFamilyRegistrationState();
	const { mutate: saveProgramRegistration } = useProgramRegistrationSaveMutation();

	const onSave = () => { 
		modals.openConfirmModal({
			title: 'Confirm Create',
			centered: true,
			children: <>
				<Text size="sm">
					Are you sure you want to create {familyRegistration.ProgramRegistrations.length == 1 ? 'this registration?' : 'these registrations?'}
				</Text>
			</>,
			labels: { confirm: 'Yes, Create Registrations', cancel: 'Cancel' },
			confirmProps: { color: 'green' },
			// onCancel: () => console.log('Cancel'),
			onConfirm: () => performSaveTest(),
		});
	}

	const performSaveTest = () => {
		setIsSaving(true);
		setTimeout(() => {
			setIsSaving(false);
			addSuccessfulSave(familyRegistration.ProgramRegistrations[0]);
		}, 3000);
	}

	const performSave = () => {
		showNotification({
			id: 'familyregistration-create',
			loading: true,
			title: 'Creating registrations',
			message: 'Please wait while the registrations are created',
			autoClose: false,
			// disallowClose: true
		});

		for (var i = 0; i < familyRegistration.ProgramRegistrations.length; i++) {
			let programRegistration = familyRegistration.ProgramRegistrations[i];

			setIsSaving(true);

			saveProgramRegistration(programRegistration!, { 
				onSuccess: (result) => {
					updateNotification({
						id: 'familyregistration-create',
						title: 'Success',
						message: 'Registration has been saved',
						color: 'green',
						icon: <IconCheck size={16} />,
						autoClose: 3000
					});

					setIsSaving(false);
					addSuccessfulSave(result.data);
				}, onError: () => {
					updateNotification({
						id: 'familyregistration-create',
						title: 'Error',
						message: 'An error occurred while trying to save the registration',
						color: 'red',
						icon: <IconX size={16} />,
						autoClose: 3000
					});

					setIsSaving(false);
					addFailedSave(programRegistration);
				}
			})
		}
	}

	const onCancel = () => {
		modals.openConfirmModal({
			title: 'Confirm Discard',
			centered: true,
			children: <>
				<Text size="sm">
					Are you sure you want to discard this family registration?
				</Text>
			</>,
			labels: { confirm: 'Yes, Discard', cancel: 'Cancel' },
			confirmProps: { color: 'red' },
			// onCancel: () => console.log('Cancel'),
			onConfirm: () => {
				history.push({ pathname: '/registrations' });
			}
		});
	}

	const onNew = () => {
		reset();
	}

	const onDone = () => {
		history.push({ pathname: '/registrations' });
	}

	return <>
		<ErrorBoundary fallback={<ErrorBoundaryFallback />}>
			<RoleTemplate role="role.user.readonly" noAccess={<>You don't have access to this.</>}>
				<Group position="apart">
					<Title order={2} mb="sm">New Family Registration</Title>

					<Group spacing="xs" mb="xs" noWrap sx={{ alignSelf: 'flex-start' }}>
						{isChanged && <>
							{!isCompleted && <>
								<Button onClick={onSave} variant="filled" disabled={!isSaving && !isValid()} hidden={!isChanged} leftIcon={<IconCheck />} loading={isSaving}>Create Registrations</Button>
								<Button onClick={onCancel} variant="light" disabled={isSaving} hidden={!isChanged} leftIcon={<IconX />}>Discard</Button>
							</>}
							{isCompleted && <>
								<Button onClick={onNew} variant="light" disabled={!isSaving && !isValid()} hidden={!isChanged} leftIcon={<IconPlus />} loading={isSaving}>New Family Registration</Button>
								<Button onClick={onDone} variant="light" disabled={isSaving} hidden={!isChanged}>Done</Button>
							</>}
						</>}
					</Group>
				</Group>
				<Divider />

				<Alert icon={<IconInfoCircleFilled />} mt="sm">
					A family registration saves entry time by adding the same parent/guardians and address to each new registration. Registration dates will also be automatically applied to subsequent registrations.
				</Alert>

				<Grid mt="xs" mb="md">
					<Grid.Col xs={12} sm={6}>
						<ParentGuardiansView />
					</Grid.Col>
					<Grid.Col xs={12} sm={6}>
						<RegistrationsView />
					</Grid.Col>
				</Grid>

			</RoleTemplate>
			<RoleTemplate role="role.admin">
				<small>
				<pre>
					{JSON.stringify(familyRegistration, null, 2)}
				</pre>
				</small>
			</RoleTemplate>
		</ErrorBoundary>
	</>
}

export default NewFamilyRegistationPage