import { useEffect, useState } from "react";
import { Button, Divider, Drawer, Group, Paper, Title } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { IconCheck, IconPencil, IconPlus, IconX } from "@tabler/icons-react"
import { useProgramRegistrationState } from "../../state/programregistration.state";
import { getProgramRegistrationTotal } from "../../functions/programregistration.functions";
import { useProgramsQuery, useProgramActivitiesQuery, useProgramActivitySessionsQuery } from "../../api/program.api";
import { BillingContact, TransactionPaymentMethod } from "../../models/transaction.models";
import { getCountryCode } from "../../shared/address";
import PaymentBillingContactView from "./PaymentBillingContactView";
import PaymentMethodEditor from "./PaymentMethodEditor";
import { createStripePaymentMethod } from "../../functions/transaction.functions";
import { PaymentMethod } from "@stripe/stripe-js";

const PaymentEditor = () => {
	const stripe = useStripe();
	const elements = useElements();

	const [opened, { open, close }] = useDisclosure(false);
	const { programRegistration, updateProgramRegistration, isLoading: isLoadingRegistration, isDeleted, status } = useProgramRegistrationState();

	// const [selectedPaymentType, setSelectedPaymentType] = useState<TransactionPaymentMethod | null>(programRegistration.Data.Metadata.transaction?.paymentMethod ?? null);
	const [stripeCardComplete, setStripeCardComplete] = useState(false);

	const [isChanged, setChanged] = useState(false);
	const [creatingPaymentMethod, setCreatingPaymentMethod] = useState(false);
	
	// const total = getProgramRegistrationTotal(programRegistration, programs ?? [], programActivities ?? [], programActivitySessions ?? []);
	const billingContact = programRegistration.Data.Metadata.transaction?.billingContact;//transaction?.Data?.BillingContact;
	// const transactionPaymentMethod = programRegistration.Data.Metadata.transaction?.paymentMethod;
	const [stripePaymentMethod, setStripePaymentMethod] = useState<PaymentMethod|undefined>(programRegistration.Data.Metadata.transaction?.stripePaymentMethod);

	const [selectedBillingContact, setSelectedBillingContact] = useState<BillingContact|null>(billingContact ?? null);
	
	useEffect(() => {
		setChanged(false);
		setSelectedBillingContact(billingContact ?? null);
		setStripePaymentMethod(programRegistration.Data.Metadata.transaction?.stripePaymentMethod);
		setStripeCardComplete(false);
		if (!opened) {
			// setSelectedPaymentType(null);
		}
	}, [opened]);

	const handleBillingContactChanged = (billingContact: BillingContact) => {
		setSelectedBillingContact(billingContact);
		setChanged(true);
	}
	
	const handlePaymentTypeChanged = (value: TransactionPaymentMethod) => {
		if (value === null)
			return;
		setChanged(true);
		// setSelectedPaymentType(value);
	}

	const handleStripeCardCompleted = (completed: boolean) => {
		setChanged(true);
		setStripeCardComplete(completed);
	}

	const handleStripeCardCleared = () => {
		setChanged(true);
		setStripePaymentMethod(undefined);
	}

	const canSave = () => {
		if (selectedBillingContact === null || selectedBillingContact === undefined)
			return false;
		// if (selectedPaymentType === null)
		// 	return false;
		// if (selectedPaymentType === TransactionPaymentMethod.ManualStripe && !stripeCardComplete && !stripePaymentMethod)
		// 	return false;
		if (!stripeCardComplete && !stripePaymentMethod)
			return false;
		if (creatingPaymentMethod)
			return false;

		return true;
	}

	const handleSave = async () => {
		if (selectedBillingContact === null || selectedBillingContact === undefined)
			return;

		if (programRegistration.Data.Metadata.transaction === undefined)
			programRegistration.Data.Metadata.transaction = { };

		programRegistration.Data.Metadata.transaction.billingContact = selectedBillingContact ?? undefined;

		// if (selectedPaymentType === TransactionPaymentMethod.ManualStripe && !stripePaymentMethod) {		
		if (!stripePaymentMethod) {		
			setCreatingPaymentMethod(true);

			let paymentMethod = await createStripePaymentMethod(stripe, elements, selectedBillingContact);

			setCreatingPaymentMethod(false);
			
			if (paymentMethod === null)
				return;
				
			setStripePaymentMethod(paymentMethod);

			programRegistration.Data.Metadata.transaction.stripePaymentMethod = paymentMethod;
			programRegistration.Data.Metadata.transaction.paymentCharged = false;
			programRegistration.Data.Metadata.transaction.offlinePaymentMethod = undefined;
		}

		// if (selectedPaymentType !== TransactionPaymentMethod.ManualStripe) {
		// 	programRegistration.Data.Metadata.transaction.stripePaymentMethod = undefined;
		// 	programRegistration.Data.Metadata.transaction.paymentCharged = undefined;

		// 	if (selectedPaymentType === TransactionPaymentMethod.OfflineCash)
		// 		programRegistration.Data.Metadata.transaction.offlinePaymentMethod = "Cash";
		// 	if (selectedPaymentType === TransactionPaymentMethod.OfflineCredit)
		// 		programRegistration.Data.Metadata.transaction.offlinePaymentMethod = "Debit/Credit";
		// }

		// if (selectedPaymentType !== null)
		// 	programRegistration.Data.Metadata.transaction.paymentMethod = selectedPaymentType;

		updateProgramRegistration(programRegistration);
		close();
	}

	// const handleCreateTransaction = () => {

	// 	saveTransaction({ 
	// 		ProgramRegistrationId: programRegistration.Id,
	// 		PaymentId: 'pi_3NDU00KSBBuwS4H20dQB7xjM',
	// 		BillingContact: { 
	// 			"Id": "", "Type": "adult", "Email": "jgroenew@gmail.com", "Gender": 1, "Address": {"Id": 17, "City": "Bowmanville", "Country": "Canada", "Street1": "38 David Baker Crt", "Street2": "", "Province": "Ontario", "PostalCode": "L1C0S1"}, "LastName": "Bar", "FirstName": "sadfsdf", "HomePhone": "9054191411", "Salutation": "Mr", "MobilePhone": "9059031280"
	// 		}
	// 		// CustomerId: 'cus_Ncnd1DZ06agSj0'
	// 	}, {
	// 		onSuccess: (result) => {
	// 			programRegistration.TransactionId = result.data.Id;
	// 			updateProgramRegistration(programRegistration, false);
	// 		}
	// 	})
	// }
	return <>
		{/* <Button variant="light" size="xs" mt={-15} mb="md"><IconPencil /> Edit</Button> */}
		{/* <RoleTemplate role="role.admin"> */}
			<Button onClick={open} disabled={isDeleted} variant="light" size="xs" mt={-15} mb="md" leftIcon={stripePaymentMethod === undefined ? <IconPlus /> : <IconPencil />}>
				{stripePaymentMethod === undefined ? "Add" : "Edit"}
			</Button>
		{/* </RoleTemplate> */}
	
		<Drawer opened={opened} onClose={close} title={<div><Title order={3}>Payment Details</Title></div>} position="right" withOverlay={true} size="md" withCloseButton overlayProps={{ opacity: 0.1, blur: 0 }}>
			<Paper p="sm" mb="md" withBorder>
				<Title order={4}>Billing Contact</Title>
				{/* {!status.isBillingContactSetup &&
				<Text>Please add a Billing Contact for this payment.</Text>
				} */}
				{/* {status.isBillingContactSetup && <>
					<Grid my="sm">
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Name:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text size="sm" weight="bold">{billingContact?.FirstName} {billingContact?.LastName}</Text></Grid.Col>

						{billingContact?.Email && <>
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Email:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text size="sm">{billingContact?.Email}</Text></Grid.Col>
						</>}
						{billingContact?.HomePhone && <>
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Home Phone:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text size="sm">{billingContact?.HomePhone}</Text></Grid.Col>
						</>}
						{billingContact?.MobilePhone && <>
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Cell Phone:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text size="sm">{billingContact?.MobilePhone}</Text></Grid.Col>
						</>}
						<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Address:</Text></Grid.Col>
						<Grid.Col span={9} sm={9} py={0}><Text size="sm">{formatAddress(billingContact?.Address)}</Text></Grid.Col>
					</Grid>
				</>} */}
				{/* <Button onClick={handleAddBillingContact} variant="light" size="sm" mt="md" leftIcon={<IconPlus />}>
					Add Billing Contact
				</Button> */}
				<PaymentBillingContactView onBillingContactChanged={handleBillingContactChanged} />
			</Paper>
			
			<PaymentMethodEditor disabled={selectedBillingContact === null} onChanged={handlePaymentTypeChanged} onStripeCardCompleted={handleStripeCardCompleted} onStripeCardCleared={handleStripeCardCleared} />
			{/* <Paper p="sm" mb="md" withBorder>
				<Title order={4} mb="sm">Payment Method</Title>
				<Text mb="md">Select how you want to process this payment:</Text>
				{selectedBillingContact === null &&
				<Alert variant="light" color="indigo" mb="md" icon={<IconExclamationCircle />}>
					You must select a billing contact before you can select the payment method.
				</Alert>
				}
				<Accordion value={selectedPaymentType?.toString()} onChange={handlePaymentTypeChanged} variant="filled" chevron={false} radius="md">
					<Accordion.Item value={TransactionPaymentMethod.ManualStripe.toString()}>
						<Accordion.Control disabled={selectedBillingContact === null} icon={selectedPaymentType === TransactionPaymentMethod.ManualStripe ? <IconCircleArrowRightFilled /> : <IconCircle />}><Text weight={selectedPaymentType === TransactionPaymentMethod.ManualStripe ? "bold" : "normal"}>Stripe Payment</Text></Accordion.Control>
						<Accordion.Panel>
							{stripePaymentMethod && <>
								<Text>Card: {stripePaymentMethod.card?.brand.toUpperCase()} **** **** **** {stripePaymentMethod.card?.last4}, Exp: {stripePaymentMethod.card?.exp_month} / {stripePaymentMethod.card?.exp_year}</Text>
								<Badge color="orange" mt={2} mb={-2} sx={{ display: 'inline-block' }}>Not charged yet</Badge>
							</>}
							{!stripePaymentMethod && <>
							<Text>Choose this option to enter credit card details to be processed online through Stripe.</Text>

							<CardElement className={classes.stripeElement} onChange={handleStripeCardChange} options={{ disableLink: true }} />

							<Alert variant="light" color="indigo" icon={<IconExclamationCircle />}>
								This card will only be charged once the registration is saved.
							</Alert>
							</>}
						</Accordion.Panel>
					</Accordion.Item>
					<Accordion.Item value={TransactionPaymentMethod.OfflineCash.toString()}>
						<Accordion.Control disabled={selectedBillingContact === null} icon={selectedPaymentType === TransactionPaymentMethod.OfflineCash ? <IconCircleArrowRightFilled /> : <IconCircle />}><Text weight={selectedPaymentType === TransactionPaymentMethod.OfflineCash ? "bold" : "normal"}>Cash</Text></Accordion.Control>
					</Accordion.Item>
					<Accordion.Item value={TransactionPaymentMethod.OfflineCredit.toString()}>
						<Accordion.Control disabled={selectedBillingContact === null} icon={selectedPaymentType === TransactionPaymentMethod.OfflineCredit ? <IconCircleArrowRightFilled /> : <IconCircle />}><Text weight={selectedPaymentType === TransactionPaymentMethod.OfflineCredit ? "bold" : "normal"}>Debit/Credit Terminal</Text></Accordion.Control>
					</Accordion.Item>
				</Accordion>

			</Paper> */}
			
			<Divider mt="lg" mb="xs" />

			<Group mt="md" position="right">
				{isChanged &&
				<Button type="button" onClick={handleSave} disabled={!canSave()} loading={creatingPaymentMethod} leftIcon={<IconCheck />}>
					Save Changes
				</Button>
				}
				<Button type="button" variant="subtle" onClick={() => close()} disabled={creatingPaymentMethod} leftIcon={<IconX />}>
					{isChanged ? "Discard Changes" : "Close"}
				</Button>
			</Group>
		</Drawer>
	</>
}

export default PaymentEditor