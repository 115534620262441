import { Stripe, StripeElements } from "@stripe/stripe-js";
import { BillingContact } from "../models/transaction.models";
import { getCountryCode } from "../shared/address";

export const createStripePaymentMethod = async (stripe: Stripe|null, elements: StripeElements|null, billingContact: BillingContact) => {
	if (stripe === undefined || stripe === null || elements === undefined || elements === null)
		return null;

	let cardElement = elements?.getElement("card");
			
	if (cardElement === null || cardElement === undefined)
		return null;
	
	let paymentMethodResult = await stripe?.createPaymentMethod({
		card: cardElement,
		type: "card",
		billing_details: {
			name: billingContact.FirstName + ' ' + billingContact.LastName,
			email: billingContact.Email,
			phone: billingContact.HomePhone || billingContact.MobilePhone,
			address: {
				line1: billingContact.Address?.Street1,
				line2: billingContact.Address?.Street2,
				city: billingContact.Address?.City,
				state: billingContact.Address?.Province,// ?? selectedBillingContact.Address?.Region,
				postal_code: billingContact.Address?.PostalCode,

				country: getCountryCode(billingContact.Address?.Country ?? "")
			}
		}
	});

	if (paymentMethodResult.error)
		return null;

	return paymentMethodResult.paymentMethod;
}