import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAccess, useAccountId } from "../Auth/auth-functions";
import { api } from "./api";
import { ProgramRegistration, ProgramRegistrationCustomFieldGroup } from "../models/programregistration.models";

export const useProgramRegistrationCustomFieldsQuery = (programId: number) => { 
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['registration-custom-fields', accountId, programId], async () => {
		let res = await api.get<ProgramRegistrationCustomFieldGroup[]>(`/admin/registrations/customfields/${programId}`);
		return res.data;
	}, { staleTime: 5*(60*1000), cacheTime: 10*(60*1000), enabled: hasAccess });
}

export const useRecentRegistrationQuery = () => { 
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-registrations-recent', accountId], async () => {
		let res = await api.get<ProgramRegistration[]>('/admin/registrations/recent');
		return res.data;
	}, { staleTime: 5*(60*1000), cacheTime: 10*(60*1000), enabled: hasAccess });
}

export const useNewRegistrationsTodayQuery = () => { 
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-registrations-new-today', accountId], async () => {
		let res = await api.get<ProgramRegistration[]>('/admin/registrations/new/today');
		return res.data;
	}, { staleTime: 5*(60*1000), cacheTime: 10*(60*1000), enabled: hasAccess });
}

export const useNewRegistrationsYesterdayQuery = () => { 
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-registrations-new-yesterday', accountId], async () => {
		let res = await api.get<ProgramRegistration[]>('/admin/registrations/new/yesterday');
		return res.data;
	}, { staleTime: 5*(60*1000), cacheTime: 10*(60*1000), enabled: hasAccess });
}

export const useRegistrationsForProgramGroupQuery = (programGroupId: number) => { 
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-registrations-by-program-group', programGroupId, accountId], async () => {
		let res = await api.get<ProgramRegistration[]>(`/admin/registrations/programgroup/${programGroupId}`);
		return res.data;
	}, { staleTime: 5*(60*1000), cacheTime: 10*(60*1000), enabled: hasAccess && programGroupId > 0 });
}

export const useRegistrationByIdQuery = (registrationId: number) => { 
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-registrations-by-id', registrationId, accountId], async () => {
		let res = await api.get<ProgramRegistration>(`/admin/registrations/id/${registrationId}`);
		return res.data;
	}, { retry: false, staleTime: Infinity, cacheTime: 600000, enabled: registrationId > 0 && hasAccess });
}

export const useProgramRegistrationSaveMutation = () => {
	const queryClient = useQueryClient();
	const accountId = useAccountId();

	return useMutation((programRegistration: ProgramRegistration) => {
			if (programRegistration.Id === 0)
				return api.post<ProgramRegistration>(`/admin/registrations`, programRegistration) 
			else
				return api.put<ProgramRegistration>(`/admin/registrations/${programRegistration.Id}`, programRegistration) 
		}, {
		onSuccess: (response, programRegistration) => {
			queryClient.invalidateQueries('search');
			queryClient.invalidateQueries('search-participant');
			queryClient.invalidateQueries(['program-registrations-by-id', programRegistration.Id, accountId]);
			queryClient.invalidateQueries(['program-session-by-id']);
			queryClient.invalidateQueries(['program-sessions-by-program-id', programRegistration.ProgramId, accountId]);
			queryClient.invalidateQueries(['program-session-enrollments-by-id']);
			queryClient.invalidateQueries(['program-session-enrollments-by-registration-id', programRegistration.Id, accountId]);
		}
	})
}

export const useProgramRegistrationDeleteMutation = () => {
	const queryClient = useQueryClient();
	const accountId = useAccountId();

	return useMutation((programRegistration: ProgramRegistration) => {		
			return api.delete<{ Id: number, Name: string }>(`/admin/registrations/${programRegistration.Id}`) 
		}, {
		onSuccess: (response, programRegistration) => {
			queryClient.invalidateQueries(['search']);
			queryClient.removeQueries(['program-registrations-by-id', programRegistration.Id, accountId]);
			queryClient.invalidateQueries(['program-session-by-id']);
			queryClient.invalidateQueries(['program-sessions-by-program-id', programRegistration.ProgramId]);
			queryClient.invalidateQueries(['program-session-enrollments-by-id']);
			queryClient.invalidateQueries(['program-session-enrollments-by-registration-id', programRegistration.Id, accountId]);
		}
	})
}

// export const useFormByIdQuery = (formId: number) => { 
// 	const hasAccess = useAccess("role.user.readonly");
// 	const accountId = useAccountId();

// 	return useQuery(['form-by-id', formId, accountId], async () => {
// 		let res = await api.get<Form>(`/admin/form/${formId}`);
// 		return res.data;
// 	}, { staleTime: Infinity, cacheTime: 60000, keepPreviousData: true, enabled: formId > 0 && hasAccess });
// }

// export const useFormByKeyQuery = (formKey: string) => { 
// 	return useQuery(['form-by-key', formKey], async () => {
// 		let res = await api.get<Form>(`/form/${formKey}`);
// 		return res.data;
// 	}, { staleTime: Infinity, cacheTime: 60000 });
// }

// export const useFormMutation = () => {
// 	const queryClient = useQueryClient();

// 	return useMutation((form: Form) => {
// 		return api.post<JSON>(`/admin/forms`, form) 
// 	}, {
// 		onSuccess: (response, form) => {
// 			queryClient.invalidateQueries(['form-by-id', form.Id]);
// 			queryClient.invalidateQueries('forms');
// 		}
// 	})
// }

// export const useFormDefinitionMutation = () => {
// 	const queryClient = useQueryClient();

// 	return useMutation((form: Form) => {
// 		return api.post<JSON>(`/admin/forms/definition`, form) 
// 	}, {
// 		onSuccess: (response, form) => {
// 			queryClient.invalidateQueries(['form-by-id', form.Id]);
// 		}
// 	})
// }