import { Button, Drawer, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from '@mantine/modals';
import { IconPencil, IconPlus, IconX } from "@tabler/icons-react";
import { formatAddress, formatDateRange, getParticipantsNames } from "../../functions/programregistration.functions";
import { ParentGuardian, ProgramRegistration, ProgramRegistrationDto } from "../../models/programregistration.models";
import { useFamilyRegistrationState } from "../../state/familyregistration.state";
import RegistrationEditor from "./RegistrationEditor";
import { getProgramTitle } from "../../functions/program.functions";
import { useProgramsQuery } from "../../api/program.api";

interface Props {
	
}

const RegistrationListEditor = ({ }: Props) => {
	const [opened, { open, close }] = useDisclosure(false);
	const { familyRegistration, isSaving, isCompleted } = useFamilyRegistrationState();

	return <>
		<Button onClick={open} disabled={isSaving || isCompleted} variant="light" size="xs" mt={-15} mb="md" leftIcon={familyRegistration.ProgramRegistrations.length === 0 ? <IconPlus /> : <IconPencil />}>
			{familyRegistration.ProgramRegistrations.length === 0 ? "Add" : "Edit"}
		</Button>

		<Drawer opened={opened} onClose={close} title={<div><Title order={3}>Registrations</Title></div>} position="right" size="md" withCloseButton>
			{familyRegistration.ProgramRegistrations.map((programRegistration, index) => {
				return <RegistrationListEditorItem key={index} programRegistration={programRegistration} />
			})}

			{familyRegistration.ProgramRegistrations.length === 0 && <>
				<Text color="dimmed">There are no registrations</Text>
			</>}

			<RegistrationEditor parentGuardianId="" add />
		</Drawer>
	</>
}

export default RegistrationListEditor

interface RegistrationListEditorItemProps {
	programRegistration: ProgramRegistration;
}

const RegistrationListEditorItem = ({ programRegistration }: RegistrationListEditorItemProps) => {
	const { data: programs } = useProgramsQuery();
	const { familyRegistration, updateFamilyRegistration } = useFamilyRegistrationState();

	const handleDeleteRegistration = () => {
		modals.openConfirmModal({
			title: 'Confirm Removal',
			centered: true,
			children: <>
				<Text size="sm">
					Are you sure you want to remove this registration?
				</Text>
			</>,
			labels: { confirm: 'Yes, Remove Registration', cancel: 'Cancel' },
			confirmProps: { color: 'red' },
			// onCancel: () => console.log('Cancel'),
			onConfirm: () => {
				familyRegistration.ProgramRegistrations = familyRegistration.ProgramRegistrations.filter(p => p.Data.Id != programRegistration.Data.Id);
				updateFamilyRegistration(familyRegistration);
			}
		});
	}

	const canDelete = true;

	return <Paper p="sm" mb="md" withBorder shadow="sm">
		<Group position="apart" align="flex-start" noWrap>
			<Stack spacing={0}>
				<Text size="lg" weight="bold">
					{getParticipantsNames(programRegistration)}
				</Text>
				<Text size="xs">{getProgramTitle(programs, programRegistration.ProgramId)}</Text>
				<Text size="xs">{formatDateRange(programRegistration.DateFrom, programRegistration.DateTo)}</Text>
				{/* <Text size="xs" color="dimmed">{formatAddress(parentGuardian.Address, false)}</Text> */}
			</Stack>

			<Stack spacing="xs">
				<RegistrationEditor registrationId={programRegistration.Data.Id} parentGuardianId="" />
				<Button onClick={handleDeleteRegistration} disabled={!canDelete} variant="subtle" size="xs" leftIcon={<IconX />} sx={{ alignSelf: "flex-start" }}>Remove</Button>
			</Stack>
		</Group>
			
	</Paper>
}