import { useEffect } from "react";
import { ActionIcon, Button, Divider, Drawer, Grid, Group, Menu, Select, TextInput, Textarea, Title, Tooltip } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconDotsCircleHorizontal, IconCheck, IconPencil, IconPlus, IconX } from "@tabler/icons-react";
import _ from "lodash";
import * as Yup from "yup";
import { createEmptyParentGuardian, createEmptyProgramRegistration, formatAddress, getGenderDescription } from "../../functions/programregistration.functions";
import { Address, Genders, ProgramRegistration, Salutations } from "../../models/programregistration.models";
import { useFamilyRegistrationState } from "../../state/familyregistration.state";
import { AddressSchema } from "../ProgramRegistrations/AddressEditor";
import { ProgramParticipantDocument } from "../../api/search.api";
import PersonSearchModal from "../ProgramRegistrations/PersonSearchModal";
import { getCountries, getPostalCodeLabel, getProvinceLabel, getProvincesStates } from "../../shared/address";
import NewRegistationPage from "../../Pages/Registrations/New";
import { useProgramRegistrationState } from "../../state/programregistration.state";



interface Props {
	registrationId?: number,
	parentGuardianId: string,
	// participantType: "adult"|"child",
	add?: boolean
}

const RegistrationEditor = ({ registrationId, parentGuardianId, add = false }: Props) => {
	const [opened, { open, close }] = useDisclosure(false);
	const { familyRegistration, updateFamilyRegistration, getAddresses } = useFamilyRegistrationState();
	const { programRegistration, updateProgramRegistration, reset } = useProgramRegistrationState();

	let parentGuardian = familyRegistration?.ParentGuardians.find(p => p.Id === parentGuardianId);

	if (add === true) {
		// parentGuardian = createEmptyParentGuardian();
	}

	const handleEditRegistration = () => {
		if (registrationId) {
			let editProgramRegistration = familyRegistration?.ProgramRegistrations.find(p => p.Data.Id == registrationId);
			if (editProgramRegistration)
				updateProgramRegistration(_.cloneDeep(editProgramRegistration));
		}
		open();
	}

	const handleAddRegistration = () => {
		let registration = createEmptyProgramRegistration();
		registration.Data.ParentGuardians = _.cloneDeep(familyRegistration.ParentGuardians);
		if (familyRegistration.ProgramRegistrations.length > 0) {
			let latestRegistration = familyRegistration.ProgramRegistrations[familyRegistration.ProgramRegistrations.length - 1];
			registration.DateFrom = latestRegistration.DateFrom;
			registration.DateTo = latestRegistration.DateTo;
			registration.Data.DateFrom = latestRegistration.Data.DateFrom;
			registration.Data.DateTo = latestRegistration.Data.DateTo;
		}
		updateProgramRegistration(registration);
		open();
	}

	const handleSaveRegistration = (registration: ProgramRegistration) => {
		let existingIndex = familyRegistration.ProgramRegistrations.findIndex(p => p.Data.Id == registration.Data.Id);
		if (existingIndex >= 0)
			familyRegistration.ProgramRegistrations[existingIndex] = registration;
		else
			familyRegistration.ProgramRegistrations.push(registration);

		updateFamilyRegistration(familyRegistration);
		close();
	}

	return <>
		{!add &&
		<Button onClick={handleEditRegistration} variant="light" size="xs" fullWidth leftIcon={<IconPencil />} sx={{ alignSelf: "flex-start" }}>Edit</Button>
		}
		{add &&
		<Button onClick={handleAddRegistration} variant="subtle" size="sm" mt="md" fullWidth leftIcon={<IconPlus />}>Add Registration</Button>	
		}
		
		<Drawer opened={opened} onClose={close}  position="right" withOverlay={true} size="95%" withCloseButton={false} overlayProps={{ opacity: 0.1, blur: 0 }}>

			<NewRegistationPage onSave={handleSaveRegistration} onDiscard={close} />

		</Drawer>
	</>
}

export default RegistrationEditor