import { Card, Group, Title, Grid, Text, Divider, Center, Loader, ScrollArea, Stack, Button, Alert } from "@mantine/core"
import { IconAdjustmentsHorizontal, IconCircleCheck } from "@tabler/icons-react"
import { formatAddress, formatDateRange, getParticipantsNames } from "../../functions/programregistration.functions";
import { AdultParticipant, ChildParticipant, ProgramRegistration } from "../../models/programregistration.models";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import ParentGuardianListEditor from "./ParentGuardianListEditor";
import { useProgramGroupsQuery, useProgramsQuery } from "../../api/program.api";
import { SessionSelectionType } from "../../models/programs.models";
import { useFamilyRegistrationState } from "../../state/familyregistration.state";
import RegistrationListEditor from "./RegistrationListEditor";
import { getProgram, getProgramTitle } from "../../functions/program.functions";

const RegistrationsView = () => {
	const { familyRegistration, isLoading, isSuccessfullySaved, successfulSaves } = useFamilyRegistrationState();
	const { data: programGroups } = useProgramGroupsQuery();
	const { data: programs } = useProgramsQuery();
	// const programGroup = programGroups?.find(g => g.Id === (programRegistration?.Data.ProgramGroupId ?? 0));

	// if (!isLoading && (programRegistration?.Data?.ParentGuardians.length ?? 0) === 0)
	// {
	// 	if (programGroup === undefined || programGroup.ParticipantType === ProgramParticipantType.Adults || 
	// 		(programGroup.ParticipantType === ProgramParticipantType.AdultsAndChildren))
	// 	return <></>
	// }

	const isSetup = familyRegistration != null && familyRegistration.ProgramRegistrations.length > 0;

	const showSuccessfullySaved = (registration: ProgramRegistration) => isSuccessfullySaved(registration);
	const getSavedRegistration = (registrationId: number) => successfulSaves.find(r => r.Data.Id == registrationId);

	// if (!isSetup && programGroup?.SessionSelectionType == SessionSelectionType.Fixed)
	// 	return <></>;

	return <>
		<Card shadow="lg" withBorder mb="sm">
			<Group position="apart" noWrap>
				<Group spacing="xs" mb="lg">
					<IconAdjustmentsHorizontal />
					<Title order={3}>Registrations</Title>
				</Group>
				{/* {!isLoading && */}
				{/* <Button variant="light" size="xs" sx={{ alignSelf: 'flex-start' }} ><IconPencil /> Edit</Button> */}
				{/* } */}
				{familyRegistration &&	<RegistrationListEditor />}
				
			</Group>
			{(isLoading) && <Center h={150} mx="auto">
				<Loader />
			</Center>}
			{!isSetup && familyRegistration && <>
				<Center h={100}>
					<Text color="dimmed">Add one or more registrations</Text>
				</Center>
			</>}

			{/* <ScrollArea.Autosize type="auto" mah={350}> */}
				
			{familyRegistration?.ProgramRegistrations.map((programRegistration, index) => {
				const savedRegistration = getSavedRegistration(programRegistration.Data.Id);

				return <div key={index} style={{ margin: "calc(1rem / 2)" }}>
					
					<Group spacing="xs" noWrap>
						<Grid mb="xs">
							<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Participants:</Text></Grid.Col>
							<Grid.Col span={9} sm={9} py={0}><Text weight="bold">{getParticipantsNames(programRegistration)}</Text></Grid.Col>
							<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Program:</Text></Grid.Col>
							<Grid.Col span={9} sm={9} py={0}><Text>{getProgramTitle(programs, programRegistration.ProgramId)} ({getProgram(programs, programRegistration.ProgramId)?.ShortDescription})</Text></Grid.Col>
							<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Dates:</Text></Grid.Col>
							<Grid.Col span={9} sm={9} py={0}><Text>{formatDateRange(programRegistration.DateFrom, programRegistration.DateTo)}</Text></Grid.Col>					
						</Grid>
						{/* <Stack align="center" spacing={0} w={50} miw={50}>
							<IconCircleCheck size={30} color="green" />
							<Button variant="white" compact radius="xl" color="green">Open</Button>
						</Stack> */}
					</Group>

					{showSuccessfullySaved(programRegistration) && savedRegistration &&
						<Alert variant="light" color="green" p={8} >
							<Group spacing="xs" noWrap position="apart">
								<Group spacing={5} noWrap>
									<IconCircleCheck color="green" size="20" />
									<Text>Registration Created Successfully!</Text>
								</Group>
								<Button component="a" href={`/registrations/view/${savedRegistration.Id ?? 0}`} target="_blank" variant="filled" compact color="green">Open</Button>
							</Group>
						</Alert>
					}
					
					{index < familyRegistration.ProgramRegistrations.length - 1 && <Divider mb="md" size={1} />}
				</div>
			})}
			
			{/* </ScrollArea.Autosize> */}
		</Card>
	</>
}

export default RegistrationsView