import { Button, Drawer, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPencil, IconPlus, IconX } from "@tabler/icons-react";
import { formatAddress } from "../../functions/programregistration.functions";
import { ParentGuardian } from "../../models/programregistration.models";
import { useFamilyRegistrationState } from "../../state/familyregistration.state";
import ParentGuardianEditor from "./ParentGuardianEditor";

interface Props {
	
}

const ParentGuardianListEditor = ({ }: Props) => {
	const [opened, { open, close }] = useDisclosure(false);
	const { familyRegistration, getParentGuardians, isSaving, isCompleted } = useFamilyRegistrationState();

	return <>
		<Button onClick={open} disabled={isSaving || isCompleted} variant="light" size="xs" mt={-15} mb="md" leftIcon={getParentGuardians().length === 0 ? <IconPlus /> : <IconPencil />}>
			{getParentGuardians().length === 0 ? "Add" : "Edit"}
		</Button>

		<Drawer opened={opened} onClose={close} title={<div><Title order={3}>Parents/Guardians</Title></div>} position="right" size="md" withCloseButton>
			{getParentGuardians().map((parentGuardian, index) => {
				return <ParentGuardianListEditorItem key={index} parentGuardian={parentGuardian} />
			})}

			{getParentGuardians().length === 0 && <>
				<Text color="dimmed">There are no parents/guardians</Text>
			</>}

			<ParentGuardianEditor parentGuardianId="" add />
		</Drawer>
	</>
}

export default ParentGuardianListEditor

interface ParentGuardianListEditorItemProps {
	parentGuardian: ParentGuardian;
}

const ParentGuardianListEditorItem = ({ parentGuardian }: ParentGuardianListEditorItemProps) => {
	const { familyRegistration, deleteParentGuardian } = useFamilyRegistrationState();
	const handleDeleteParentGuardian = (parentGuardianId: string) => {
		deleteParentGuardian(parentGuardianId); 
	}

	const canDelete = true;//programRegistration.Data.ParentGuardians.length > 1;

	return <Paper p="sm" mb="md" withBorder shadow="sm">
		<Group position="apart" align="flex-start" noWrap>
			<Stack spacing={0}>
				<Text size="lg" weight="bold">
					<Text component="span">{parentGuardian?.Salutation} {parentGuardian.FirstName} {parentGuardian.LastName} </Text>
					{/* <Text size="sm" component="span" color="dimmed">({getGender(parentGuardian)}, Adult)</Text> */}
				</Text>
				<Text size="xs" color="dimmed">{formatAddress(parentGuardian.Address, false)}</Text>
			</Stack>
			<Stack spacing="xs">
				<ParentGuardianEditor parentGuardianId={parentGuardian.Id} />
				<Button onClick={() => handleDeleteParentGuardian(parentGuardian.Id)} disabled={!canDelete} variant="light" size="xs" leftIcon={<IconX />} sx={{ alignSelf: "flex-start" }}>Delete</Button>
			</Stack>
		</Group>
			
	</Paper>
}