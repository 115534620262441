import { useMsal } from "@azure/msal-react";

export const useAccess = (role: string) => {
	const { instance } = useMsal();
	const activeAccount = instance.getActiveAccount();
	const roles = activeAccount?.idTokenClaims!['roles'] ?? [];

	return hasRole(roles, role);
}

export const useAccountId = () => {
	const { instance } = useMsal();
	const activeAccount = instance.getActiveAccount();
	return activeAccount?.localAccountId ?? "";
}

export const hasRole = (roles: string[], role: string) => {
	switch (role) {
		case "role.user.readonly":
			return roles.includes("role.admin") || roles.includes("role.user.readwrite") || roles.includes("role.user.readonly");
		case "role.user.readwrite":
			return roles.includes("role.admin") || roles.includes("role.user.readwrite");
		case "role.admin":
			return roles.includes("role.admin");
	}
	return false;
}

export const isInRole = (roles: string[], role: string) => { 
	return roles.find(r => r === role) !== undefined;
}