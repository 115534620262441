import { useQuery } from "react-query";
import { useAccess, useAccountId } from "../Auth/auth-functions";
import { api } from "./api";

interface SearchResults {
	results: SearchResult[];
}

interface SearchResult {
	title: string;
}

export interface ProgramRegistrationDocument {
	id: string;
	Program: string;
	ProgramId: number;
	ProgramGroup: string;
	ProgramGroupId: number;
	Participants: string[];
	DateFrom: string;
	DateTo: string;
	DateSubmitted: string;
	Deleted: boolean;
}

export interface ProgramParticipantDocument {
	id?: string;
	Type?: "adult"|"child";
	FirstName?: string;
	LastName?: string;
	Gender?: number;

	Address?: {
		// Id?: number;
		Street1?: string;
		Street2?: string;
		City?: string;
		Province?: string;
		Region?: string;
		PostalCode?: string;
		Country?: string;
	};

	Salutation?: string;
	Email?: string;
	HomePhone?: string;
	MobilePhone?: string;
	
	Location?: string;
	EmergencyContact?: string;
	
	Birthdate?: string;
	HealthConcerns?: string;
	Allergies?: string;
	SpecialNeeds?: string;
}

export const searchIndexProgramRegistrationsQuery = async () => {
	return await api.get('/admin/programs/typesense-index-registrations');
}

export const searchIndexProgramParticipantsQuery = async () => {
	return await api.get('/admin/programs/typesense-index-participants');
}

export const useSearchQuery = (query: string) => { 
	const hasAccess = useAccess("role.user.readonly");

	return useQuery(['search', query], async () => {
		let res = await api.get<ProgramRegistrationDocument[]>(`/admin/search`, { params: { query: query } });
		return res.data;
	}, { enabled: hasAccess && query !== undefined && query.length > 0 });
}

export const useParticipantSearchQuery = (query: string) => { 
	const hasAccess = useAccess("role.user.readonly");

	return useQuery(['search-participant', query], async () => {
		let res = await api.get<ProgramParticipantDocument[]>(`/admin/search-participants`, { params: { query: query } });
		return res.data;
	}, { enabled: hasAccess && query !== undefined && query.length > 0 });
}