import { Card, Group, Title, Grid, Text, Center, Loader, Button, Divider, Badge } from "@mantine/core"
import { Link, useParams } from "react-router-dom";
import { IconAdjustmentsHorizontal, IconPencil, IconPlus } from "@tabler/icons-react"
import moment from "moment"
import { useProgramsQuery, useProgramGroupsQuery, useProgramActivitiesQuery, useProgramActivitySessionsQuery } from "../../api/program.api";
import { useRegistrationByIdQuery } from "../../api/programregistration.api";
import { ViewRegistationPageParams } from "../../Pages/Registrations/View";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import DetailsEditor from "./DetailsEditor";
import { formatAddress, formatDateRange, getGender, getProgramRegistrationTotal } from "../../functions/programregistration.functions";
import { currency } from "../../shared/helpers";
import { useTransactionByIdQuery } from "../../api/transaction.api";
import PaymentEditor from "./PaymentEditor";
import { TransactionPaymentMethod } from "../../models/transaction.models";
import PaymentRefundEditor from "./PaymentRefundEditor";

const PaymentView = () => {
	// const { registrationId } = useParams<ViewRegistationPageParams>();
	// const { data: registration, isLoading: isLoadingRegistration } = useRegistrationByIdQuery(Number(registrationId));
	const { programRegistration, isLoading: isLoadingRegistration, status } = useProgramRegistrationState();
	const { data: programs, isLoading: isLoadingPrograms } = useProgramsQuery();
	const { data: programGroups, isLoading: isLoadingProgramGroups } = useProgramGroupsQuery();
	const { data: programActivities } = useProgramActivitiesQuery();
	const { data: programActivitySessions } = useProgramActivitySessionsQuery();
	const { data: transaction } = useTransactionByIdQuery(programRegistration.TransactionId ?? 0);
	
	const program = programs?.find(p => p.Id === (programRegistration?.Data.ProgramId ?? 0));
	const programGroup = programGroups?.find(p => p.Id === (programRegistration?.Data.ProgramGroupId ?? 0));

	const isSaved = status.isSaved;
	const isTransactionCreated = transaction !== undefined;//status.isPaymentSetup;// programRegistration != null && programRegistration.ProgramId > 0 && programRegistration.ProgramGroupId > 0;
	const isBillingContactSetup = programRegistration.Data.Metadata.transaction?.billingContact !== undefined || transaction?.Data?.BillingContact !== undefined;
	
	const total = getProgramRegistrationTotal(programRegistration, programs ?? [], programActivities ?? [], programActivitySessions ?? [], true);
	const totalCostExcludingDiscount = getProgramRegistrationTotal(programRegistration, programs ?? [], programActivities ?? [], programActivitySessions ?? []);
	const billingContact = transaction?.Data?.BillingContact ?? programRegistration.Data.Metadata.transaction?.billingContact;
	const transactionPaymentMethod = programRegistration.Data.Metadata.transaction?.paymentMethod;
	const stripePaymentMethod = programRegistration.Data.Metadata.transaction?.stripePaymentMethod;
	const offlinePaymentMethod = programRegistration.Data.Metadata.transaction?.offlinePaymentMethod;

	const statusPaid = transactionPaymentMethod === TransactionPaymentMethod.ManualStripe && programRegistration.Data.Metadata.transaction?.paymentCharged !== false;

	const statusNotPaid = transactionPaymentMethod === undefined || 
		(transactionPaymentMethod === TransactionPaymentMethod.ManualStripe && 
			(programRegistration.Data.Metadata.transaction?.paymentCharged === false && stripePaymentMethod === undefined));

	const statusNotCharged = transactionPaymentMethod === TransactionPaymentMethod.ManualStripe && 
		(programRegistration.Data.Metadata.transaction?.paymentCharged === false && stripePaymentMethod !== undefined);

	// Should calculate entire registration total
	if (total <= 0)
		return <></>

	return <>
		<Card shadow="lg" withBorder mb="sm" pb="xl">
			<Group position="apart" noWrap>
				<Group spacing="xs" mb="lg" noWrap>
					<IconAdjustmentsHorizontal />
					<Title order={3} truncate>Payment Details</Title>
				</Group>
				{/* {!isLoadingRegistration &&
				<DetailsEditor add={!isSetup} />
				} */}
				{/* <Button variant="light" size="xs" mt={-15} mb="md"><IconPencil /> Edit</Button> */}
				{/* <Button variant="light" size="xs" mt={-15} mb="md"><IconPlus /> Add</Button> */}
				{isSaved && !isTransactionCreated && <PaymentEditor />}
				{isSaved && isTransactionCreated && <PaymentRefundEditor />}
			</Group>
			{(isLoadingRegistration || isLoadingPrograms || isLoadingProgramGroups) && isTransactionCreated && <Center h={150} mx="auto">
				<Loader />
			</Center>}
			{!isTransactionCreated && programRegistration && <>
				{/* <Button variant="light" size="sm" mt={0} sx={{ alignSelf: 'flex-start' }}><IconPlus /> Select Program &amp; Dates</Button> */}
				{/* <DetailsEditor add={true} /> */}
				<Grid mb="xs">
					<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Total:</Text></Grid.Col>
					<Grid.Col span={9} py={0}><Text weight="bold">{currency.format(totalCostExcludingDiscount)}</Text></Grid.Col>
					{totalCostExcludingDiscount - total > 0 && <>
					<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Discount:</Text></Grid.Col>
					<Grid.Col span={9} py={0}><Text weight="bold">{currency.format(totalCostExcludingDiscount - total)}</Text></Grid.Col>
					</>}
					<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Status:</Text></Grid.Col>
					<Grid.Col span={9} py={0}>
						{statusNotPaid &&
							<Badge color="red" mt={2} mb={-2} sx={{ display: 'inline-block' }}>Not paid yet</Badge>
						}
						{statusNotCharged &&
							<Badge color="orange" mt={2} mb={-2} sx={{ display: 'inline-block' }}>Not charged yet</Badge>
						}
						{statusPaid &&
							<Badge color="green" mt={2} mb={-2} sx={{ display: 'inline-block' }}>Paid</Badge>
						}
					</Grid.Col>
					{stripePaymentMethod !== undefined && <>
						<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Method:</Text></Grid.Col>
						<Grid.Col span={9} py={0}><Text weight="bold">Stripe</Text></Grid.Col>
						<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Card:</Text></Grid.Col>
						<Grid.Col span={9} py={0}><Text weight="bold">{stripePaymentMethod.card?.brand.toUpperCase()} **** **** **** {stripePaymentMethod.card?.last4}, Exp: {stripePaymentMethod.card?.exp_month} / {stripePaymentMethod.card?.exp_year}</Text></Grid.Col>
					</>}
					{offlinePaymentMethod !== undefined && <>
						<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Method:</Text></Grid.Col>
						<Grid.Col span={9} py={0}><Text weight="bold">{offlinePaymentMethod}</Text></Grid.Col>
					</>}
				</Grid>
					{!isSaved &&
					<Center h={80}>
						<Text color="dimmed">Save the registration before adding a payment</Text>
					</Center>
					}
					{isSaved && !stripePaymentMethod && !offlinePaymentMethod &&
					<Center h={80}>
						<Text color="dimmed">Add a payment for this registration</Text>
					</Center>
					}
			</>}
			{!isLoadingRegistration && isTransactionCreated && <>
				<Grid mb="xs">
					<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Total:</Text></Grid.Col>
					<Grid.Col span={9} py={0}><Text weight="bold">{currency.format(totalCostExcludingDiscount)}</Text></Grid.Col>
					{totalCostExcludingDiscount - total > 0 && <>
					<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Discount:</Text></Grid.Col>
					<Grid.Col span={9} py={0}><Text weight="bold">{currency.format(totalCostExcludingDiscount - total)}</Text></Grid.Col>
					</>}
					<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Paid:</Text></Grid.Col>
					<Grid.Col span={9} py={0}>{transaction ? <Text weight="bold">{currency.format(transaction.Paid)}</Text> : <Badge color="red" mt={2} mb={-2} sx={{ display: 'inline-block' }}>Not paid yet</Badge>}</Grid.Col>
					{/* <Grid.Col span={9} py={0}>{transaction ? <Text weight="bold">{currency.format(transaction.Paid)}</Text> : <Badge color="red" mt={2} mb={-2} sx={{ display: 'inline-block' }}>Not paid yet</Badge>}</Grid.Col> */}
					{transaction && <>
					<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Payment Method:</Text></Grid.Col>
					<Grid.Col span={9} py={0}><Text>Stripe</Text></Grid.Col>
					</>}
					{transaction && <>
					<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Payment ID:</Text></Grid.Col>
					<Grid.Col span={9} py={0}>
						<Group spacing={0}>
							<Text>{transaction?.Data?.PaymentData?.id}</Text>
							{transaction?.Data?.PaymentData?.id &&
							<Button component="a" href={`https://dashboard.stripe.com/payments/${transaction?.Data?.PaymentData?.id}`} target="_blank" variant="subtle" compact>Open in Stripe</Button>
							}
						</Group>
					</Grid.Col>
					<Grid.Col span={3} py={0}><Text align="right" color="dimmed" size="sm">Customer ID:</Text></Grid.Col>
					<Grid.Col span={9} py={0}>
						<Group spacing={0}>
							<Text>{transaction?.Data?.CustomerData?.id}</Text>
							{transaction?.Data?.CustomerData?.id &&
							<Button component="a" href={`https://dashboard.stripe.com/customers/${transaction?.Data?.CustomerData?.id}`} target="_blank" variant="subtle" compact>Open in Stripe</Button>
							}
						</Group>
					</Grid.Col>
					</>}
				</Grid>
			</>}
			{!isLoadingRegistration && isBillingContactSetup && <>
				{/* {transaction && <> */}
				<Divider mb="md" label="Billing Contact" labelPosition="center" labelProps={{ color: 'dimmed' }} />
				<Grid mb="xs">
					<Grid.Col span={3} sm={3} lg={3} py={0}><Text align="right" color="dimmed" size="sm">Name:</Text></Grid.Col>
					<Grid.Col span={9} sm={4} lg={9} py={0}><Text weight="bold">{billingContact?.Salutation} {billingContact?.FirstName} {billingContact?.LastName}</Text></Grid.Col>
					{/* <Grid.Col span={3} sm={2} lg={3} py={0}><Text align="right" color="dimmed" size="sm">Gender:</Text></Grid.Col>
					<Grid.Col span={9} sm={3} lg={9} py={0}><Text>{getGender(transaction?.Data.BillingContact.Gender)}</Text></Grid.Col> */}
				</Grid>
				<Grid mb="xs">
					{billingContact?.Email && <>
					<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Email:</Text></Grid.Col>
					<Grid.Col span={9} sm={9} py={0}><Text>{billingContact?.Email}</Text></Grid.Col>
					</>}
					{billingContact?.HomePhone && <>
					<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Home Phone:</Text></Grid.Col>
					<Grid.Col span={9} sm={9} py={0}><Text>{billingContact?.HomePhone}</Text></Grid.Col>
					</>}
					{billingContact?.MobilePhone && <>
					<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Cell Phone:</Text></Grid.Col>
					<Grid.Col span={9} sm={9} py={0}><Text>{billingContact?.MobilePhone}</Text></Grid.Col>
					</>}
				</Grid>
				{billingContact?.Address && <>
				<Divider mb="md" />
				<Grid>
					<Grid.Col span={3} sm={3} py={0}><Text align="right" color="dimmed" size="sm">Address:</Text></Grid.Col>
					<Grid.Col span={9} sm={9} py={0}><Text>{formatAddress(billingContact.Address)}</Text></Grid.Col>
				</Grid>
				</>}
				{/* </>} */}
			</>}
		</Card>
	</>
}

export default PaymentView