import { useHistory } from "react-router-dom"
import { Button, Center, Stack, Text, Title } from "@mantine/core"
import { IconAlertTriangle } from "@tabler/icons-react"

const NotFound = () => {
	const history = useHistory();

	return <Center h={400}>
		<Stack align="center">
			<IconAlertTriangle size={150} strokeWidth={1} />
			<Title order={2}>Not Found</Title>
			<Text>No registration exists for the specified id.</Text>

			<Button variant="light" onClick={() => history.goBack()}>Go Back</Button>
		</Stack>
	</Center>
}

export default NotFound