import { Title, Button, Drawer, Alert, Text, Accordion, Group } from "@mantine/core"
import { IconArrowBackUp, IconCircle, IconCircleArrowRightFilled, IconCreditCard, IconExclamationCircle, IconPencil } from "@tabler/icons-react"
import { useDisclosure } from "@mantine/hooks";
import { useProgramRegistrationState } from "../../state/programregistration.state";
import { useUserPermissionQuery } from "../../api/user.api";
import { getProgramRegistrationTotal } from "../../functions/programregistration.functions";
import { useProgramActivitiesQuery, useProgramActivitySessionsQuery, useProgramsQuery } from "../../api/program.api";
import { currency } from "../../shared/helpers";
import { useTransactionByIdQuery } from "../../api/transaction.api";


interface PaymentRefundEditorProps {
	// paymentMethod: TransactionPaymentMethod|null;
	onRefunded?: () => void;
}

const PaymentRefundEditor = ({ onRefunded }: PaymentRefundEditorProps) => {
	const [opened, { open, close }] = useDisclosure(false);
	const { data: userPermissions } = useUserPermissionQuery();

	const { programRegistration, updateProgramRegistration, isLoading: isLoadingRegistration, isDeleted, status } = useProgramRegistrationState();
	const { data: programs, isLoading: isLoadingPrograms } = useProgramsQuery();
	// const { data: programGroups, isLoading: isLoadingProgramGroups } = useProgramGroupsQuery();
	const { data: programActivities } = useProgramActivitiesQuery();
	const { data: programActivitySessions } = useProgramActivitySessionsQuery();
	const { data: transaction } = useTransactionByIdQuery(programRegistration.TransactionId ?? 0);

	const total = getProgramRegistrationTotal(programRegistration, programs ?? [], programActivities ?? [], programActivitySessions ?? []);

	return <>
		{userPermissions?.RefundPermission === true &&
		<Button onClick={open} hidden={isDeleted} variant="light" size="xs" mt={-15} mb="md" leftIcon={<IconArrowBackUp />}>
			Refund
		</Button>
		}

		<Drawer opened={opened} onClose={close} title={<div><Title order={3}>Refund Payment</Title></div>} position="right" withOverlay={true} size="md" withCloseButton overlayProps={{ opacity: 0.1, blur: 0 }}>
			<Alert variant="light" color="indigo" icon={<IconExclamationCircle />}>
				This action will initiate a payment refund through Stripe. Once completed, it cannot be reversed.
			</Alert>

			<Text mt="md" mb="md">Select the amount of the refund:</Text>

			<Accordion value="100" variant="filled" chevron={false} radius="md">
				<Accordion.Item value="100">
					<Accordion.Control icon={<IconCircleArrowRightFilled />}><Text weight={"bold"}>Full Refund: {currency.format(total)}</Text></Accordion.Control>
					{/* <Accordion.Panel> */}
				</Accordion.Item>
				<Accordion.Item value="90">
					{/* <Accordion.Panel> */}
					<Accordion.Control icon={<IconCircle />}><Text weight={"normal"}>Refund less 5% fee: {currency.format(total - total * 0.05)}</Text></Accordion.Control>
				</Accordion.Item>
				
			</Accordion>

			<Text mt="md" mb="md">Refund will go to:</Text>

			<Group spacing="xs">
				<IconCreditCard />
				<span>{transaction?.Data.PaymentData.payment_method_details.card.brand.toUpperCase()}, ...{transaction?.Data.PaymentData.payment_method_details.card.last4}, ({transaction?.Data.PaymentData.payment_method_details.card.exp_month} / {transaction?.Data.PaymentData.payment_method_details.card.exp_year})</span>
			</Group>
			
		</Drawer>
	</>
}

export default PaymentRefundEditor