import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
//   msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: any) => {
	// console.log(event.eventType);
	// console.log(event.payload?.account ?? "");
	if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && event.payload.account) {
		const account = event.payload.account;
		msalInstance.setActiveAccount(account);
	}
});
