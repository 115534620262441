import moment from "moment";
import _ from "lodash";
import { currency } from "../../shared/helpers";
import { Report, ReportColumn, ReportGroup, ReportCellTable } from "./report.models";

export class ReportPDF {

	// private dateFilter: ng.IFilterDate;
	// private currencyFilter: ng.IFilterCurrency;

	constructor(private report: Report) {
		
	}

	getDocumentDefinition() : any {
		var dd = {
			pageSize: this.report.Layout.Size,
			pageOrientation: this.report.Layout.Orientation,
			pageMargins: [40, 70, 40, 40],

			header: this.getReportHeader(),
			footer: (currentPage: number, pageCount: number) => { return this.getReportFooter(currentPage, pageCount) },
			content: this.getContent(),
			styles: this.getStyles(),
			defaultStyle: this.getDefaultStyle()
		};

		return dd;
	}

	getColumnWidths() : (string|number)[] {
		var widths: (string|number)[] = [];

		_.forEach(this.report.Body.Columns, (column) => { 
			widths.push(column.Width);
		});

		return widths;
		// return ['*', '*', 100];
	}

	getContent() {
		var content: any[] = [];

		content.push(this.getReportTable());

		return content;
	}

	getStyles() : any {
		return {
			header : {
				fontSize: 16,
				bold: true,
				margin: [0, 20, 0, 5],
				alignment: "center"
			},
			subheader : {
				fontSize: 11,
				bold: true,
				margin: [0, 0, 0, 10],
				alignment: "center"
			},
			dateHeader: {
				fontSize: 14,
				bold: true,
				margin: [0, 10, 0, 5]
			},
			total: {
				bold: true,
				fontSize: 12,
				margin: [5, 5, 5, 0]
			},
			table: {
				margin: [0, 0, 0, 5] // margin: [0, 10, 0, 5]
			},
			tableHeader: {
				bold: true,
				fontSize: 10,
				color: 'black',
				margin: [5, 0, 5, 0]
			},
			groupHeader: {
				bold: true,
				fontSize: 12,
				color: 'black',
				margin: [5, 0, 5, 0]
			},
			groupHeader2: {
				bold: true,
				fontSize: 10,
				color: 'black',
				margin: [5, 0, 5, 0]
			},
			groupFooter: {
				bold: true,
				fontSize: 11,
				margin: [5, 5, 5, 0]
			},
			groupFooter2: {
				bold: true,
				fontSize: 10,
				margin: [5, 5, 5, 0]
			},
			reportFooter: {
				bold: true,
				fontSize: 12,
				margin: [5, 5, 5, 0]
			},

			footerLeft: {
				margin: [40, 0]
			},
			footerMiddle: {

			},
			footerRight: {
				margin: [40, 0]
			},
			middle: {
				alignment: 'center'
			},
			right: {
				alignment: 'right'
			},
			cell: {
				margin: [5, 1, 5, 1]
			},
			noResults: {
				margin: [5, 20, 5, 1],
				fontSize: 10
			}
		}
	}

	getDefaultStyle() : any {
		return {
			fontSize: 8
		}
	}

	getReportHeader() : any {
		return [
			{ text: this.report.Header.Title, style: "header" },
			{ text: this.report.Header.Subtitle, style: "subheader" },
		];
	}

	getReportFooter(currentPage: number, pageCount: number) : any {
		var leftTitle = this.report.Footer.LeftTitle;
		if (leftTitle == "%page-count%")
			leftTitle = `Page ${currentPage} of ${pageCount}`;
		else if (leftTitle == null)
			leftTitle = "";

		var middleTitle = this.report.Footer.MiddleTitle;
		if (middleTitle == "%page-count%")
			middleTitle = `Page ${currentPage} of ${pageCount}`;
		else if (middleTitle == null)
			middleTitle = "";

		var rightTitle = this.report.Footer.RightTitle;
		if (rightTitle == "%page-count%")
			rightTitle = `Page ${currentPage} of ${pageCount}`;
		else if (rightTitle == null)
			rightTitle = "";

		var left = { text: leftTitle, style: "footerLeft" };
		var middle = { text: middleTitle, style: "footerMiddle", alignment: "center" };
		var right = { text: rightTitle, style: "footerRight", alignment: "right" };

		return {
			columns: [ left, middle, right ]
		};
	}

	getReportTable() {
		var tableRows: any[] = [];

		var headerRow: any[] = [];

		_.forEach(this.report.Body.Columns, (column) => {
			var style: string[] = [ "tableHeader" ];
			if (column.Alignment == "middle")
				style.push("middle");
			if (column.Alignment == "right")
				style.push("right");

			headerRow.push({ text: column.Name, style: style });
		});
		

		tableRows.push(headerRow);


		_.forEach(this.report.Body.Groups, (group: ReportGroup) => {
			var row = [
				this.getGroupTable(group, this.report.Body.Columns, "groupHeader", "groupFooter", 0, group == this.report.Body.Groups[this.report.Body.Groups.length - 1])
			];
			tableRows.push(row);
		});

		if (this.report.Body.Groups == null || this.report.Body.Groups.length == 0) {
			var noResultsRow: any[] = [];
			
			noResultsRow.push({ text: this.report.Body.NoResults, style: [ "noResults", "middle" ], colSpan: this.report.Body.Columns.length });

			for (var i = 0; i < this.report.Body.Columns.length - 1; i++) {
				noResultsRow.push({ text: '' });
			}
			
			tableRows.push(noResultsRow);
		}

		if (this.report.Body.Footer != null) {
			_.forEach(this.report.Body.Footer.Rows, (row) => {
				var footerRow: any[] = [];
				
				_.forEach(this.report.Body.Columns, (column, colIndex) => {
					var cell = row.Cells[colIndex];
					var style: string[] = [ "reportFooter" ];
					if (cell.Alignment == "middle")
						style.push("middle");
					if (cell.Alignment == "right")
						style.push("right");

					if (cell.Value != null) {
						if (column.Type == "currency") {
							footerRow.push({ text: currency.format(cell.Value), style: style, colSpan: cell.ColumnSpan });
						} else { //column.Type == "string" || column.Type == "string-multiline"
							footerRow.push({ text: `${cell.Value}`, style: style, colSpan: cell.ColumnSpan });
						} //else if (column.Type == "date-short") {
						// 	footerRow.push({ text: this.dateFilter(report.Footer.Cells[colIndex].Value, "shortDate"), style: style });
						// }
					} else {
						footerRow.push({ text: '' });
					}
					
				});

				tableRows.push(footerRow);
			});
		}


		var table = {

			// style: 'table',
			layout: {
				'hLineWidth': (i: any, node: any) => {
					if (this.report.Body.Footer != null && i === node.table.body.length - this.report.Body.Footer.Rows.length) return 2;
					if (i === 0 || i === node.table.body.length) return 0;
					return (i === node.table.headerRows) ? 2 : 0;
				},
				'vLineWidth': function(i: any, node: any) { return 0; },
				'paddingLeft': function (i: any, node: any) { return 0; },
				'paddingRight': function (i: any, node: any) { return 0; }
			},
			table: {
				widths: this.getColumnWidths(),
				headerRows: 1,
				body: tableRows
			}
		}

		return table;
	}


	getGroupTable(group: ReportGroup, columns: ReportColumn[], headerStyle: string, footerStyle: string, level: number, lastGroup: boolean) {
		var tableRows: any[] = [];

		// Group Title
		var headerRowCount = 0;
		if (group.Title != null) {
			headerRowCount = 1;

			var headerRow: any[] = [];
			var style: string[] = [ headerStyle ];

			headerRow.push({ text: group.Title, style: style, colSpan: columns.length });

			for (var i = 0; i < columns.length - 1; i++) {
				headerRow.push({ text: '' });
			}

			tableRows.push(headerRow);
		}

		level += 1;
		_.forEach(group.Groups, (group: ReportGroup) => {
			var row = [
				this.getGroupTable(group, this.report.Body.Columns, "groupHeader2", "groupFooter2", level, group == this.report.Body.Groups[this.report.Body.Groups.length - 1])
			];
			tableRows.push(row);
		});

		// Group Rows
		_.forEach(group.Rows, (r) => {
			var row: any[] = [];

			_.forEach(columns, (column, colIndex) => {
				var style: string[] = [ "cell" ];
				if (r.Cells[colIndex].Alignment == "middle")
					style.push("middle");
				if (r.Cells[colIndex].Alignment == "right")
					style.push("right");

				if (column.Type == "string" || column.Type == "string-multiline") {
					row.push({ text: `${r.Cells[colIndex].Value}`, style: style, colSpan: r.Cells[colIndex].ColumnSpan });
				} else if (column.Type == "currency") {
					row.push({ text: currency.format(r.Cells[colIndex].Value), style: style, colSpan: r.Cells[colIndex].ColumnSpan });
				} else if (column.Type == "datetime") {
					row.push({ text: moment(r.Cells[colIndex].Value).format("LLL"), style: style, colSpan: r.Cells[colIndex].ColumnSpan });
				} else if (column.Type == "datetime-short") {
					row.push({ text: moment(r.Cells[colIndex].Value).format("L h:mm"), style: style, colSpan: r.Cells[colIndex].ColumnSpan });
				} else if (column.Type == "datetime-medium") {
					row.push({ text: moment(r.Cells[colIndex].Value).format("LL h:mma"), style: style, colSpan: r.Cells[colIndex].ColumnSpan });
				} else if (column.Type == "date-short") {
					row.push({ text: moment(r.Cells[colIndex].Value).format("L"), style: style, colSpan: r.Cells[colIndex].ColumnSpan });
				} else if (column.Type == "time-short") {
					row.push({ text: moment(r.Cells[colIndex].Value).format("LT"), style: style, colSpan: r.Cells[colIndex].ColumnSpan });
				} else if (column.Type == "date-medium") {
					row.push({ text: moment(r.Cells[colIndex].Value).format("LL"), style: style, colSpan: r.Cells[colIndex].ColumnSpan });
				} else if (column.Type == "time-medium") {
					row.push({ text: moment(r.Cells[colIndex].Value).format("LTS"), style: style, colSpan: r.Cells[colIndex].ColumnSpan });
				} else if (column.Type == "date-long") {
					row.push({ text: moment(r.Cells[colIndex].Value).format("LLL"), style: style, colSpan: r.Cells[colIndex].ColumnSpan });
				} else if (column.Type == "date-full") {
					row.push({ text: moment(r.Cells[colIndex].Value).format("LLLL"), style: style, colSpan: r.Cells[colIndex].ColumnSpan });
				} else if (column.Type == "table") {
					row.push(this.getCellTable(r.Cells[colIndex].Value, style, r.Cells[colIndex].ColumnSpan))
				}
			});				

			tableRows.push(row);
		});

		if ((group.Rows == null || group.Rows.length == 0) && (group.Groups == null || group.Groups.length == 0)) {
			var noResultsRow: any[] = [];
			
			noResultsRow.push({ text: group.NoResults, style: [ "cell" ], colSpan: columns.length });

			for (var i = 0; i < columns.length - 1; i++) {
				noResultsRow.push({ text: '' });
			}
			
			tableRows.push(noResultsRow);
		}

		// Group Footer
		if (group.Footer != null) {
			_.forEach(group.Footer.Rows, (row) => {
				var footerRow: any[] = [];
				
				_.forEach(columns, (column, colIndex) => {
					var cell = row.Cells[colIndex];
					var style: string[] = [ footerStyle ];
					if (cell.Alignment == "middle")
						style.push("middle");
					if (cell.Alignment == "right")
						style.push("right");

					if (cell.Value != null) {
						if (column.Type == "currency") {
							footerRow.push({ text: currency.format(cell.Value), style: style, colSpan: cell.ColumnSpan });
						} else { //column.Type == "string" || column.Type == "string-multiline"
							footerRow.push({ text: `${cell.Value}`, style: style, colSpan: cell.ColumnSpan });
						} //else if (column.Type == "date-short") {
						// 	footerRow.push({ text: this.dateFilter(report.Footer.Cells[colIndex].Value, "shortDate"), style: style });
						// }
					} else {
						footerRow.push({ text: '' });
					}					
				});

				tableRows.push(footerRow);
			});
		}			

		var lineThickness = 0.5;
		if (level > 1)
			lineThickness = 0

		// Table creation
		var table = {
			pageBreak: group.PageBreak && !lastGroup ? 'after' : null,
			colSpan: columns.length, // Span the columns of the outer table
			style: 'table',
			layout: {
				'hLineWidth': (i: any, node: any) => {
					if (group.Footer != null && i === node.table.body.length - group.Footer.Rows.length) return lineThickness;
					return (i === node.table.headerRows && headerRowCount > 0) ? lineThickness : (group.RowSeparatorLines && i > 0 ? 0.5 : 0);
				},
				'vLineWidth': function(i: any, node: any) { return 0; },
				'paddingLeft': function (i: any, node: any) { return 0; },
				'paddingRight': function (i: any, node: any) { return 0; }
			},
			table: {
				widths: this.getColumnWidths(),
				headerRows: headerRowCount,
				dontBreakRows: true,
				body: tableRows
			}
		}

		return table;
	}

	getCellTableLayout() {
		return {
			hLineWidth: () => 0.5,
			vLineWidth: () => 0.5,
			paddingLeft: function (i: any, node: any) { return 1; },
			paddingRight: function (i: any, node: any) { return 1; }
		}
	}

	getCellTable(reportCellTable: ReportCellTable, style: string[], colSpan: number) {
		let table = {
			style: '',
			// dontBreakRows: true,
			// keepWithHeaderRows: 1,
			headerRows: 1,
			widths: reportCellTable.Columns.map(c => c.Width),
			heights: 10,
			body: [
				this.getCellTableHeader(reportCellTable),
				...this.getCellTableRows(reportCellTable)
			]
		};

		return {
			style: style,
			colSpan: colSpan,
			margin: [0, -1, 0, -1],
			layout: this.getCellTableLayout(),
			table: table
		}
	}

	getCellTableHeader(reportCellTable: ReportCellTable) {
		let headerRow = [];

		for (let column of reportCellTable.Columns) {
			headerRow.push({ text: column.Name, fontSize: 7, alignment: column.Alignment, color: column.FillColor }); //, style: style
		}

		return headerRow
	}

	getCellTableRows(reportCellTable: ReportCellTable) {
		let rows: any[] = [];

		rows = reportCellTable.Rows.map(row => {
			return row.Cells.map(cell => {
				return {
					text: cell.Value, alignment: cell.Alignment, colSpan: cell.ColumnSpan, fontSize: 7, fillColor: cell.FillColor
				}
			});
		});

		return rows;
	}
}
