import { extend, hookstate, useHookstate } from '@hookstate/core';
import _ from 'lodash';
// import { validatePerson } from '../functions/person-functions';
import { Address, ParentGuardian, ParticipantBase, ProgramRegistration, RegistrationProgramSession } from "../models/programregistration.models";
import { devtools } from '@hookstate/devtools';
import moment from 'moment';
import { ProgramSession, ProgramSessionEnrollment } from '../models/programs.models';
import { getProgramSession } from '../functions/program.functions';
import { createEmptyChild, createEmptyParentGuardian, createEmptyProgramRegistration } from '../functions/programregistration.functions';
import { StoreEngine, localstored } from '@hookstate/localstored';
import { TransactionPaymentMethod } from '../models/transaction.models';

interface ProgramRegistrationState {
	programRegistration?: ProgramRegistration;
	initial?: ProgramRegistration;
	isChanged: boolean;
	isLoading: boolean;
	// isValid: boolean;
}

const ProgramRegistrationState = hookstate(
	{ isChanged: false, isLoading: false } as ProgramRegistrationState, 
	extend(
		localstored({
			key: 'admin-program-registration-v1',
			engine: sessionStorage as StoreEngine,
			initializer: () => new Promise<ProgramRegistrationState>((resolve, reject) => { 
				let emptyRegistration = createEmptyProgramRegistration();
				resolve({ 
					programRegistration: emptyRegistration,
					initial: emptyRegistration,
					isChanged: false,
					isLoading: false
				})
			})
		}),			
		devtools({ key: 'admin-program-registration' })
	)
);

export const useProgramRegistrationState = () => {
	let state = useHookstate(ProgramRegistrationState);
	// if (init) {
	// 	state.set({ person: _.cloneDeep(init), initial: _.cloneDeep(init), isChanged: false });
	// }
	// state.attach(Persistence('icamp-people-person-state'));
	return {
		programRegistration: state.programRegistration.get({ noproxy: true }) as ProgramRegistration,
		initial: state.initial.get({ noproxy: true }),
		setProgramRegistration: (programRegistration: ProgramRegistration|undefined, changed = true) => state.set({ 
			programRegistration: programRegistration, 
			initial: programRegistration ? _.cloneDeep(programRegistration) : undefined, 
			isChanged: changed, 
			isLoading: false
			// isValid: validatePerson(person)
		}),
		updateProgramRegistration: (programRegistration: ProgramRegistration|undefined, changed = true) => { 
			state.programRegistration.set(programRegistration);
			state.isChanged.set(changed);
			// state.isValid.set(validatePerson(person));
		},
		isValid: () => { 
			let programRegistration = state.programRegistration.get({ noproxy: true }) as ProgramRegistration;
			if (programRegistration.Data.Participants.length === 0)
				return false;

			return true;
		},
		isChanged: state.isChanged.get({ noproxy: true }),
		isDeleted: state.programRegistration.get({ noproxy: true })?.Deleted ?? false,
		isLoading: state.isLoading.get({ noproxy: true }),
		setIsLoading: (loading: boolean) => state.isLoading.set(loading),
		// isValid: state.isValid.get({ noproxy: true }),
		reset: () => state.set({ 
			programRegistration: _.cloneDeep(state.initial.get({ noproxy: true })), 
			initial: state.initial.get({ noproxy: true }), 
			isChanged: false,
			isLoading: false
			// isValid: false
		}),

		getParticipants: () => state.programRegistration.get({ noproxy: true })?.Data.Participants as ParticipantBase[],
		addChildParticipant: () => {
			let programRegistration = state.programRegistration.get({ noproxy: true }) as ProgramRegistration;

			programRegistration.Data.Participants.push(createEmptyChild());

			state.programRegistration.set(programRegistration);
			state.isChanged.set(true);
		},
		deleteParticipant: (participantId: string) => {
			let programRegistration = state.programRegistration.get({ noproxy: true }) as ProgramRegistration;

			programRegistration.Data.Participants.splice(programRegistration.Data.Participants.findIndex(p => p.Id === participantId), 1);

			state.programRegistration.set(programRegistration);
			state.isChanged.set(true);
		},

		getParentGuardians: () => state.programRegistration.get({ noproxy: true })?.Data.ParentGuardians as ParentGuardian[],
		addParentGuardian: () => {
			let programRegistration = state.programRegistration.get({ noproxy: true }) as ProgramRegistration;

			programRegistration.Data.ParentGuardians.push(createEmptyParentGuardian());

			state.programRegistration.set(programRegistration);
			state.isChanged.set(true);
		},
		deleteParentGuardian: (parentGuardianId: string) => {
			let programRegistration = state.programRegistration.get({ noproxy: true }) as ProgramRegistration;

			programRegistration.Data.ParentGuardians.splice(programRegistration.Data.ParentGuardians.findIndex(p => p.Id === parentGuardianId), 1);

			state.programRegistration.set(programRegistration);
			state.isChanged.set(true);
		},

		enrollAllProgramSessions: (programSessions: ProgramSession[]) => {
			let programRegistration = state.programRegistration.get({ noproxy: true }) as ProgramRegistration;
			for (let programSession of programSessions) {
				programRegistration.Data.ProgramSessions.push({ 
					ProgramSessionId: programSession.Id,
					Participants: programRegistration.Data.Participants.map((participant) => {
						return {
							ParticipantId: participant.Id,
							ActivitySessionIds: []
						}
					})
					// [
					// 	{
					// 		ParticipantId: participantId,
					// 		ActivitySessionIds: []
					// 	}
					// ]
				})
			}
			state.programRegistration.set(programRegistration);
			state.isChanged.set(true);
		},
		unEnrollAllProgramSessions: (programSessions: ProgramSession[]) => {
			let programRegistration = state.programRegistration.get({ noproxy: true }) as ProgramRegistration;
			programRegistration.Data.ProgramSessions = [];
			state.programRegistration.set(programRegistration);
			state.isChanged.set(true);
		},

		getRegistrationEnrollmentForSession: (programSession: ProgramSession, programSessionEnrollments: ProgramSessionEnrollment[]) => {
			let programRegistration = state.programRegistration.get({ noproxy: true }) as ProgramRegistration;
			let rps = programRegistration.Data.ProgramSessions.find(s => s.ProgramSessionId === programSession.Id);
			let count = 0;
			if (rps === undefined) {
				for (let enrollment of programSessionEnrollments) {
					if (programRegistration.Data.ProgramSessions.find(p => p.ProgramSessionId === enrollment.ProgramSessionId) === undefined)
						count--;
				}
				return count;
			}
			for (let participant of rps.Participants) {
				if (programSessionEnrollments.find(e => e.ProgramSessionId === programSession.Id && e.ParticipantId === participant.ParticipantId) === undefined)
					count++;
			}
			for (let enrollment of programSessionEnrollments) {
				if (rps.ProgramSessionId === enrollment.ProgramSessionId && rps.Participants.find(p => p.ParticipantId === enrollment.ParticipantId) === undefined)
					count--;
			}
			return count;
		},

		toggleProgramSessionEnrollment: (programSessions: ProgramSession[], programSessionId: number, participantId: string, selected: boolean) => {
			let programRegistration = state.programRegistration.get({ noproxy: true }) as ProgramRegistration;
			let registrationProgramSession = programRegistration.Data.ProgramSessions.find(s => s.ProgramSessionId === programSessionId);
			if (selected) {
				if (!registrationProgramSession) {
					programRegistration.Data.ProgramSessions.push({ 
						ProgramSessionId: programSessionId,
						Participants: [
							{
								ParticipantId: participantId,
								ActivitySessionIds: []
							}
						]
					})
				} else {
					registrationProgramSession.Participants.push({
						ParticipantId: participantId,
						ActivitySessionIds: []
					})
				}
			} else {
				if (registrationProgramSession) {
					registrationProgramSession.Participants.splice(registrationProgramSession.Participants.findIndex(p => p.ParticipantId === participantId), 1);
					if (registrationProgramSession.Participants.length === 0) {
						programRegistration.Data.ProgramSessions.splice(programRegistration.Data.ProgramSessions.findIndex(p => p.ProgramSessionId === programSessionId), 1);
					}
				}
			}

			programRegistration.Data.ProgramSessions.sort((a, b) => moment(getProgramSession(programSessions, a.ProgramSessionId)!.StartDate).isBefore(moment(getProgramSession(programSessions, b.ProgramSessionId)!.StartDate)) ? -1 : 1);

			state.programRegistration.set(programRegistration);
			state.isChanged.set(true);
		},

		toggleProgramSessionWaitList: (programSessionId: number, participantId: string, onWaitList: boolean) => {
			let programRegistration = state.programRegistration.get({ noproxy: true }) as ProgramRegistration;
			let registrationProgramSession = programRegistration.Data.ProgramSessions.find(s => s.ProgramSessionId === programSessionId);

			let registrationProgramSessionParticipant = registrationProgramSession?.Participants.find(p => p.ParticipantId === participantId);
			if (registrationProgramSessionParticipant)
				registrationProgramSessionParticipant.WaitList = onWaitList;

			state.programRegistration.set(programRegistration);
			state.isChanged.set(true);
		},

		getAddresses: () => {
			let addresses: Address[] = [];
			let programRegistration = state.programRegistration.get({ noproxy: true }) as ProgramRegistration;
				
			for (let parentGuardian of programRegistration.Data.ParentGuardians) {
				if (parentGuardian.Address !== undefined && addresses.filter(a => a.Street1 === parentGuardian.Address?.Street1).length === 0)
				addresses.push(_.cloneDeep(parentGuardian.Address));
			}
			for (let participant of programRegistration.Data.Participants) {
				if (participant.Address !== undefined && addresses.filter(a => a.Street1 === participant.Address?.Street1).length === 0)
				addresses.push(_.cloneDeep(participant.Address));
			}
				
			return addresses;
		},

		status: {
			isSaved: state.programRegistration != null && (state.programRegistration.value?.Id ?? 0) > 0,
			isDetailsSetup: state.programRegistration != null && (state.programRegistration.value?.Data.ProgramGroupId ?? 0) > 0 && (state.programRegistration.value?.Data.ProgramId ?? 0) > 0,
			// isPaymentSetup: state.programRegistration != null && (state.programRegistration.value?.Data.Metadata.paymentMethod ?? 0) > 0,
			isBillingContactSetup: state.programRegistration != null && state.programRegistration.value?.Data.Metadata.transaction?.billingContact !== undefined,
			willChargeStripePayment: state.programRegistration != null && state.programRegistration.value?.Data.Metadata.transaction?.paymentMethod === TransactionPaymentMethod.ManualStripe && state.programRegistration.value?.Data.Metadata.transaction?.paymentCharged === false,
			// isPersonalSetup: state.person != null && state.person.value?.FirstName !== "" && state.person.value?.LastName !== "",
			// canSetupContact: state.person != null && state.person.value?.FirstName !== "" && state.person.value?.LastName !== "",
			// isContactSetup: state.person != null && (
			// 	state.person.value?.HomePhone !== "" || state.person.value?.MobilePhone !== "" || state.person.value?.WorkPhone !== "" ||
			// 	state.person.value?.PersonalEmail !== "" || state.person.value?.WorkEmail !== ""),
			// canSetupAddress: state.person != null && ((state.person.value?.FamilyId ?? 0) > 0 || (state.person.value?.FirstName !== "" && state.person.value?.LastName !== "")),
			// isAddressSetup: state.person.value?.Family.Address?.Street1 !== "",
			// canSetupFamily: state.person != null && ((state.person.value?.FamilyId ?? 0) > 0 || (state.person.value?.FirstName !== "" && state.person.value?.LastName !== "")),
			// isFamilySetup: (state.person.value?.Family.Id ?? 0) > 0
		}
	};
}