import { useQuery } from "react-query";
import { useAccess, useAccountId } from "../Auth/auth-functions";
import { api } from "./api";
import { ProgramActivitySession, ProgramActivitySessionEnrollment } from "../models/programs.models";

export const useProgramActivitySessionsByProgramIdQuery = (programId: number, dateFrom: string, dateTo: string) => {
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-activity-sessions-by-program-id', programId, dateFrom, dateTo, accountId], async () => {
		let res = await api.get<ProgramActivitySession[]>(`/admin/programs/${programId}/activitysessions`, { params: { dateFrom: dateFrom, dateTo: dateTo }});
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: hasAccess && programId > 0 });
}

export const useProgramActivitySessionEnrollmentsByRegistrationIdQuery = (registrationId: number) => {
	const hasAccess = useAccess("role.user.readonly");
	const accountId = useAccountId();

	return useQuery(['program-activity-session-enrollments-by-registration-id', registrationId, accountId], async () => {
		let res = await api.get<ProgramActivitySessionEnrollment[]>(`/admin/programs/sessions/registration/${registrationId}/enrollments`);
		return res.data;
	}, { staleTime: Infinity, cacheTime: 60000, enabled: hasAccess && registrationId > 0 });
}
