export interface CreateTransactionDto {
	ProgramRegistrationId: number;
	PaymentId?: string;
	BillingContact: BillingContact;
	// CustomerId?: string;
}

export interface CreateRefundDto {
	ProgramRegistrationId: number;
	TransactionId: number;
	Amount: number;
	ChargeId: string;
}

export interface StripeRefund {
	
}

export interface Transaction {
	Id: number,
	UserId: number,
	DateSubmitted: string,
	PaymentMethod: TransactionPaymentMethod,
	Total: number,
	Paid: number,
	Data: TransactionData
}

export enum TransactionPaymentMethod {
	None = 0,
	DepositOnline = 1,
	FullAmountOnline = 2,
	PhoneFollowUp = 3,
	ManualStripe = 5
}

export interface TransactionData {
	Agreement?: boolean,
	PaymentData?: any,
	CustomerData?: any,
	OfflinePaymentData?: any,
	BillingContact: BillingContact	
}

export interface BillingContact {
	Id: string,
	Type: string,
	FirstName: string,
	LastName: string,
	Salutation: string,
	Gender: number,
	Email: string,
	HomePhone: string,
	MobilePhone: string,
	Address: {
		Id: number,
		Street1: string,
		Street2: string,
		City: string,
		Province: string,
		PostalCode: string,
		Country: string
	}
}